<div class="content-dash">
    <h1>Cambiar Contraseña</h1>
    <form [formGroup]="form" class="change_pass">
        <div class="form-group">
            <label for="currentPassword">Contraseña Actual:</label>
            <input autocomplete="actual_password" [formControl]="form.controls['actual_password']" type="password" id="currentPassword" name="currentPassword" placeholder="Escriba su contraseña actual" required>
            <small
												*ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched"
												class="form-error-msg"> Contraseña actual es requerido </small>
        </div>

        <div class="form-group">
            <label for="newPassword">Nueva Contraseña:</label>
            <input autocomplete="new_password" [formControl]="form.controls['new_password']"  type="password" id="newPassword" name="newPassword" placeholder="Escriba su contraseña nueva"  required>
            <small
            *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched"
            class="form-error-msg"> Nueva contraseña es requerido </small>
            <small *ngIf="form.controls['new_password'].hasError('minlength') && form.controls['new_password'].touched " class="form-error-msg">Contraseña debe tener minimo 6 caracteres</small>
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirmar Nueva Contraseña:</label> 
            <input autocomplete="newPasswordConfirm" [formControl]="form.controls['newPasswordConfirm']"  type="password" id="confirmPassword" name="confirmPassword"  placeholder="Repita su contraseña nueva" required>
            <small
                                            *ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched"
                                            class="form-error-msg"> Campo requerido </small>

            <small *ngIf="form.controls['newPasswordConfirm'].hasError('minlength') && form.controls['newPasswordConfirm'].touched " class="form-error-msg">Contraseña debe tener minimo 6 caracteres</small>
                     
        </div>

        <button>Cambiar Contraseña</button>
    </form>
</div>

