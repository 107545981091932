import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { WebService } from 'src/app/services/web/web.service';

@Component({
  selector: 'app-customer-plan-update',
  templateUrl: './customer-plan-update.component.html',
  styleUrls: ['./customer-plan-update.component.scss']
})
export class CustomerPlanUpdateComponent implements OnInit {

  @ViewChild('skipBillingDialog') skipBillingDialog: TemplateRef<any>;

  // ESTO ES GENERAL
  formData = {};
  console = console;
  form: FormGroup;

  customer: any;
  tarjetas: any[] = new Array;
  lastarjetasa: any[] = new Array;

  public chp: any;
  estatus: any[] = [
    { value: 1, description: "Activo" },
    { value: 2, description: 'Inactivo' },
    { value: 3, description: 'Congelado' },
    { value: 4, description: 'En proceso de cobro' },
    { value: 5, description: 'No se pudo cobrar' },
    { value: 6, description: 'Suscripción por correo electrónico' },
    { value: 7, description: 'Suscrito, pero sin cobrar aun' }
  ];
  next: any[] = [
    { value: true, description: "SI" },
    { value: false, description: 'NO' }
  ];
  plan: any;
  servicePlans: any[] = [];
  service: any;
  initialStatus: any;
  changePlan: Boolean = false;

  constructor(
    private location: Location,
    private spinner: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private toast: ToastService,
    private webService: WebService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      id: new FormControl('', [
        Validators.required
      ]),
      contractNumber_Plan: new FormControl(''),
      startdate_customerPlan: new FormControl('', [
        Validators.required
      ]),
      endDate_customerPlan: new FormControl('', [
        Validators.required
      ]),
      idPlanEnrollmentrenewalPartner: new FormControl(''),
      status_customerPlan: new FormControl('', [
        Validators.required
      ]),
      nextBill: new FormControl('', [
        Validators.required
      ]),
      plan: new FormControl(''),
      service: new FormControl(''),
      tdc: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.spinner.open();

    this.activatedRoute.params.subscribe(params => {

      this.webService.get(this.webService.HOST + "/hasplan/" + params["plan"]).subscribe(chp => {

        this.chp = chp.result;
        this.customer = this.chp.customer;
        this.plan = this.chp.plan.id;
        this.service = this.chp.plan.service.id;

        this.webService.get(this.webService.HOST + "/ecommerce/plan/service/" + this.service).subscribe(plans => {
          this.form.controls["plan"].setValue(this.chp.plan.id);
          this.servicePlans = plans.result;
          // Se carga la información del plan asociado al cliente a través de los componentes
          if (this.chp.lastOkBillingDate != null) this.chp.lastOkBillingDate = this.chp.lastOkBillingDate;
          if (this.chp.lastNokBillingDate != null) this.chp.lastNokBillingDate = this.chp.lastNokBillingDate;
          this.form.controls["contractNumber_Plan"].setValue(this.chp.contractNumber_Plan);
          this.form.controls["id"].setValue(this.chp.id);
          this.form.controls["startdate_customerPlan"].setValue(this.chp['startdate_customerPlan']);
          this.form.controls["endDate_customerPlan"].setValue(this.chp.endDate_customerPlan);
          this.form.controls["nextBill"].setValue(this.next[1].value);
          if (this.chp.idPlanEnrollmentrenewalPartner != null) {
            this.form.controls["idPlanEnrollmentrenewalPartner"].setValue(this.chp.idPlanEnrollmentrenewalPartner);
          }

          // Se selecciona el estado actual del plan asociado al cliente
          this.form.controls["status_customerPlan"].setValue(this.chp.status_customerPlan);
          if (this.chp.status_customerPlan == 6 || this.chp.status_customerPlan == 7 || this.chp.status_customerPlan == 5) {
            this.form.controls['status_customerPlan'].disable();
          }
          this.initialStatus = this.chp.status_customerPlan;

          if (this.chp.creditCardToken != null) {
            // Se buscan las tarjetas del cliente
            this.webService.get(this.webService.HOST + "/customer/" + this.customer.id + "/creditcard").subscribe(parametros => {

              if (parametros['result'] != null) {
                let lastarjetas = parametros['result'];
                for (let i in lastarjetas) {
                  let tdc = lastarjetas[i].creditCard;
                  this.webService.get(this.webService.HOST + "/customer/" + this.customer.id + "/creditcard/token/" + lastarjetas[i].tokenCreditCard).subscribe(params => {
                    if (params['result'] != null) {
                      this.lastarjetasa.push(lastarjetas[i]);
                      this.tarjetas.push(tdc);
                      if (this.chp.creditCardToken.id == params['result'].id) { // ESTA ES LA TDC ASOCIADA AL PLAN
                        this.form.controls["tdc"].setValue(this.tarjetas[this.tarjetas.length - 1].id);
                      }
                      this.spinner.close();
                    }
                    this.spinner.close();
                  });
                }
              }
            });
          } else {
            this.spinner.close();
          }

        }, err => {
          this.toast.showError(err);
          console.log(err);
        });
      });

    });
  }

  goBack() {
    this.location.back();
  }

  update() {
    this.spinner.open();
    let obj = this.form.value;
    this.chp = obj;
    let start = new Date(obj.startdate_customerPlan);
    let end = new Date(obj.endDate_customerPlan);
    if (obj.nextBill == null) {
      obj.nextBill = false;
    }
    // VALIDAMOS LAS FECHAS
    if (end.getTime() <= start.getTime()) { // FECHA DE CULMINACION PREVIA A LA DE INSCRIPCIÓN
      this.toast.showError("Fecha de culminacion debe ser POSTERIOR a la fecha de inscripción");
      this.spinner.close();
      return;
    }

    this.chp.service = { id: this.service };
    this.chp.customer = { id: this.customer.id };

    if (this.chp.tdc != "") {
      this.webService.get(this.webService.HOST + "/customer/" + this.customer.id + "/creditcard/token/" + this.lastarjetasa[this.lastarjetasa.findIndex((t: { creditCard: { id: any; }; }) => t.creditCard.id == this.chp.tdc)].tokenCreditCard).subscribe(prm => {
        let card = prm['result'];
        // Se guarda el id de la tarjeta seleccionada
        this.chp.creditCardToken = { id: card.id };

        if (this.initialStatus != +this.chp.status_customerPlan) {
          this.webService.put({ action: +this.chp.status_customerPlan }, this.webService.HOST + "/ecommerce/hasplan/" + this.chp.id + "/status").subscribe(response => {
            this.updateSuscription();
          }, err => {
            this.toast.showError(err);
            this.spinner.close();
            console.log(err);
          });
        } else {
          this.updateSuscription();
        }
      });
    } else {
      if (this.initialStatus != +this.chp.status_customerPlan) {
        this.webService.put({ action: +this.chp.status_customerPlan }, this.webService.HOST + "/ecommerce/hasplan/" + this.chp.id + "/status").subscribe(response => {
          this.updateSuscription();
        }, err => {
          this.toast.showError(err);
          this.spinner.close();
          console.log(err);
        });
      } else {
        this.updateSuscription();
      }
    }
  }

  updateSuscription() {
    if (this.plan != +this.chp.plan) { // SE CAMBIÓ DE PLAN: CERRAR LAS FACTURAS DEL CLIENTE EN ESE PLAN
      this.webService.put({ action: 2 }, this.webService.HOST + "/ecommerce/hasplan/" + this.chp.id + "/status").subscribe(response => {
        this.changePlan = true;
        this.sendUpdate();
      }, err => {
        this.toast.showError(err);
        this.spinner.close();
      });
    } else {
      this.sendUpdate();
    }
  }

  sendUpdate() {
    this.chp.plan = { id: this.chp.plan };
    if (this.form.controls['status_customerPlan'].disabled) {
      this.chp.status_customerPlan = this.initialStatus;
    }
    this.webService.put({ 'hasplan': this.chp }, this.webService.HOST + "/hasplan/" + this.chp.id).subscribe(hasplan => {
      if (this.changePlan) { // SE CAMBIÓ EL PLAN: DAR DE ALTA LA SUSCRIPCIÓN
        this.webService.put({ action: 1 }, this.webService.HOST + "/ecommerce/hasplan/" + this.chp.id + "/status").subscribe(response => {
          this.toast.showSuccess("Plan actualizado correctamente");
          this.spinner.close();
          this.location.back();
        }, err => {
          this.toast.showError(err);
          this.spinner.close();
        });
      } else {
        this.toast.showSuccess("Plan actualizado correctamente");
        this.spinner.close();
        this.location.back();
      }
    }, err => {
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  /**
   * Abre el diálogo de confirmación para saltar la fecha de cobro.
   */
  openSkipBillingDialog(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.skipBillingDialog);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.skipBillingDate();
      }
    });
  }

  /**
   * Realiza la solicitud HTTP PUT para saltar la fecha de cobro.
   */
  skipBillingDate(): void {
    this.spinner.open();
    const subscriptionId = this.chp.id; // Se asume que el ID de la suscripción se encuentra en this.chp.id
    const url = this.webService.HOST + `/subscriptions/${subscriptionId}/next-billing-date`;
    this.webService.put({}, url).subscribe(
      response => {
        this.toast.showSuccess("Fecha de cobro saltada correctamente");
        this.spinner.close();
        window.location.reload();
      },
      err => {
        this.toast.showError(err);
        this.spinner.close();
      }
    );
  }
}
