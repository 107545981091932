import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';
import { HasplanFilter } from './hasplan.filter';
import { AppConfirmService } from '../app-confirm/app-confirm.service';

@Component({
  selector: 'app-customer-plans',
  templateUrl: './customer-plans.component.html',
  styleUrls: ['./customer-plans.component.scss'],
  providers:[AppConfirmService]
})
export class CustomerPlansComponent {

  // PARA MANEJO DE PANEL DE BUSQUEDA
  isOpenSearchPanel = false;
  filter = new HasplanFilter(this.tableService.filter);
 
   // PARA MANEJO DE TABLAS
   @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
   @ViewChild(MatSort) sort: MatSort | undefined;
   displayedColumns: string[] = ['id', 'endDate_customerPlan', 'contractNumber_Plan','status_customerPlan','actions'];
   dataSource: any;

  services : object = [];
  plans : object = [];
  items: any[] = [];
  customer:any;

  selectedOption:any;
  selected:any;
  selectedService:any;

   constructor(
    public  tableService: TableService<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public confirmService: AppConfirmService,
    private cdr: ChangeDetectorRef,private webService : WebService,private spinner : SpinnerService,private toast : ToastService) {
      this.tableService = new TableService;
    }


  ngOnInit(): void {
      this.activatedRoute.params.subscribe(customer =>{
        this.customer = customer["id"];
        // this.isOpenSearchPanel = true;
        this.list();  
      });
  }

  list(event?: PageEvent){

    this.spinner.open();
    let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
	  {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})


	  httpParams = this.filter.getHttpParams(httpParams);
		this.webService.get(this.webService.HOST+"/hasplan/customer/"+this.customer,httpParams).subscribe(response =>{

          if(response['result'].length == 0){
            this.toast.showError("No se encontraron resultados para la busqueda");
          }

          this.items = response['result']
					for(let item of this.items){
							item.contractNumber_Plan = item.plan.name_plan;
							item.endDate_customerPlan = item.plan.service.name_service;
              switch(item.status_customerPlan){
                case 1 : {
                  item.show_status = 'Activo';
                  break;
                }
                case 2:{
                  item.show_status = 'Inactivo';
                  break;
                }
                case 3:{
                  item.show_status = 'Congelado';
                  break;
                }
                case 4:{
                  item.show_status = 'En proceso de cobro';
                  break;
                }
                case 5:{
                  item.show_status = 'No cobrada';
                  break;
                }

              }
						//Se cargan los planes que el cliente tiene asociados en el componente lista
          }
          
					this.dataSource = new MatTableDataSource<any>(this.items);
					this.tableService.pager = response['pager'];
          this.tableService.selection.clear();
          this.spinner.close()
          
			}, (err) => {
        this.spinner.close();
        console.log(err);
        this.toast.showError(err)
      })

  }


   // FUNCION PARA RESETEAR LA BUSQUEDA
  reset() {
    this.filter = new HasplanFilter();
    this.tableService.filter = null;
    //this.isOpenSearchPanel = false;
    this.list();
  }
  // FUNCION PARA REALIZAR BUSQUEDAS
  search() {
    //this.isOpenSearchPanel = false;
    //this.tableService.pager.pageIndex = 0;
    this.tableService.filter = new HasplanFilter(this.filter);
    this.list();
  }
  // FUNCION PARA ABRIR EL PANEL DE BUSQUEDA
  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new HasplanFilter(this.filter);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeStatus(item: any){

  }

  openDialog(item : any) {
    this.confirmService.confirm({
      title: 'Confirmación',
      message: "Esta seguro que desea cambiar el estatus del registro seleccionado?",
    
      })
      .subscribe((result) => {
        console.log(result);
        if(result){ // SI LA RESPUESTA ES OK - Eliminamos el registro
          this.deletes(item);
        }
        
        this.cdr.markForCheck();
      });
  }

  update(item: any) { // EDITAR
    this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
  }

  deletes(item : any) { // ELIMINAR
    this.spinner.open();
    let status = item.status_customerPlan == 1 ? 2 : 1;
     this.webService.put({action : status }, this.webService.HOST + "/ecommerce/hasplan/" + item.id + "/status").subscribe( response =>{
            this.toast.showSuccess("Estatus cambiado correctamente")
            this.list();
      }, err =>{
        this.toast.showError(err);
        this.spinner.close();
        console.log(err);
      });
      // this.webService.delete(this.webService.HOST + "/hasplan/" + item.id + "/" +  status).subscribe(response => {
      //   this.toast.showSuccess("Estatus cambiado correctamente")
      //   this.list();
      // }, err =>{
      //  this.toast.showError(err);
      //  this.spinner.close();
      // });
  }

  goBack(){
    this.router.navigate(["/reports"]); 
  }
  



}
