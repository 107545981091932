import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; // Asegúrate de tener esta importación
import { CreditCardComponent } from '../credit-card/credit-card.component';
//import { ThemeService } from '../../theme.service';
//import { AuthService } from 'src/app/services/auth/auth.service';
//import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-content-dashboard',
  templateUrl: './content-dashboard.component.html',
  styleUrls: ['./content-dashboard.component.scss']
})
export class ContentDashboardComponent {
  nombreEmpresa: string = 'Billcentrix';
  nombreUsuario: string = ''; // Nombre de usuario
  correoUsuario: string = '';
  telefonoUsuario : string = '';
  imagenAleatoria: string; //Imagen Aleatoria
  constructor(){
 
 // Selecciona una imagen de perfil aleatoria de una lista de imágenes
 const perfilImagenes: string[] = [
  '../assets/images/profile/men-01.png',
  '../assets/images/profile/men-02.png',
  '../assets/images/profile/men-03.png',
  '../assets/images/profile/men-04.png',
  '../assets/images/profile/girl-02.png',
  '../assets/images/profile/girl-03.png',
  '../assets/images/profile/girl-04.png',
  '../assets/images/profile/girl-05.png',
  // Agrega más rutas de imágenes según sea necesario
];
this.imagenAleatoria = perfilImagenes[Math.floor(Math.random() * perfilImagenes.length)];
    
  }
}
const routes: Routes = [
  {
    path: 'dashboard',
    component: ContentDashboardComponent,
    children: [
      { path: 'credit-card', component: CreditCardComponent }, // Ruta de Tarjeta de Crédito
      // Otras rutas del dashboard aquí
    ],
  },
]; 