<body>
    <div class="main">
        <div class="grid-overlay">
            <div class="nav">
                <a href="/">regresar</a> 
            </div>
            <div class="logoblock"><img (click)="goHome()" src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
                <h2>Cambiar Contraseña</h2>
            </div>
            
            
                <div class="background-white">
                     <h1>Cambiar Contraseña</h1>
                        <form class="user" [formGroup]="resetForm" (ngSubmit)="reset()" > 
                            <input [formControl]="resetForm.controls['email']"  type="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Ingresa una dirección de email...">
                                <small 
                                *ngIf="resetForm.controls['email'].hasError('required') && resetForm.controls['email'].touched" 
                                class="form-error-msg"> Correo es requerido </small>
                                <small 
                                *ngIf="resetForm.controls['email'].hasError('email') && resetForm.controls['email'].touched" 
                                class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>
                        <div class="options">
                        <button class="crear" type="btn" [disabled]="resetForm.invalid" >Cambiar Contraseña</button>
                        </div>
                        </form>
                         <div class="logear">¿Ya tienes cuenta?<button (click)="onLoginClick()" >Entrar</button></div>
                        
                </div>              
        </div>
    
    </div>
            <app-footer></app-footer>





