<div class="bar-preview"><h3>Este es un sitio Demo, podras visualizar como se vera su sitio web segun el template que seleccione</h3></div>
<div class="header">
  <div class="content-header">
    <div class="logo" routerLink="/basic">
      <img src="/assets/images/brand.svg" alt="logo">
    </div>
    
    <!-- Menú normal para dispositivos de escritorio -->
    <div  class="menu">
      <div class="menu-item">
        <a routerLink="/basic">Home</a>
        <div class="submenu">
          <a routerLink="/basic">Basic</a>
          <a routerLink="/practic">Practic</a>
          <a routerLink="/contempo">Contempo</a>
        </div>
      </div>
      <a routerLink="/loginpreview">Login</a>
      <a routerLink="/dashboard">Account</a>
    </div>

    <!-- Menú hamburguesa para dispositivos móviles -->
    <!-- Menú hamburguesa para dispositivos móviles -->
<!-- Botón hamburguesa para dispositivos móviles -->
<div class="hamburger-menu" *ngIf="isMobile" (click)="toggleMenu()">
  <button>
    <span></span>
    <span></span>
    <span></span>
  </button>
</div>

<!-- Menú para dispositivos móviles (inicialmente oculto) -->
<div   class="mobile-menu" *ngIf="isMobile && isMenuOpen">
  <ul>
    <li><a routerLink="/">Home</a></li>
    <li *ngIf="!authService.isLoggedIn()" ><a (click)="goLogin()">Login</a></li>
    <li *ngIf="authService.isLoggedIn()" ><a (click)="goDashboard()">Account</a></li>
  </ul>
</div>
  </div>
</div>
