import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup ;

  constructor(private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService,
    private location : Location) {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email])
    });
  }

  ngOnInit(): void {
  }

  reset(){

    this.spinner.open();

    const signinData = this.resetForm.value;

    // SOLICITAMOS TOKEN
    this.webService.post(signinData,this.webService.HOST + "/billcentrix/V2/resetPassword").subscribe( res =>{
      
      this.spinner.close();
      this.toast.showSuccess("Se enviara un nuevo password a su correo, por favor inicie sesion con sus credenciales nuevas ...");
      this.router.navigateByUrl("/");

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    })


  }

  goBack(){
    this.location.back();
  }
  onLoginClick() {
    this.router.navigate(["/login"])
    
  }
  goHome() {
    this.router.navigate(['/']);
  }

}

