<body>
    <div class="grid-overlay" [ngClass]="{'empty-portal': isEmpty}">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img *ngIf="myportals.length === 0" src="../../../../assets/images/BRAND.png" alt="logo de Billcentrix">
                    <img   *ngIf="myportals.length > 0" src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
               
            </div>
            <div class="buttons">
                <button (click)="goSettings()" >Crear nueva suscripción</button>
                <!-- <button   class="create-plan-btn"><h1>+</h1></button> -->
                <!-- <img src="../../../../assets/icons/cubo-green.png" alt="icono portal"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="my-portals-data">
            <!-- <div class="second-headboard">
                <h1>Suscripciones</h1>
                <div class="linked-icons" >
                    <button >Registro inicial</button>
                    <button  >Crear plan</button>
                    <button class="active">Activar servicio</button>
                </div>
                     <img src="../../../assets/icons/3412.png" alt="iconos de pasos"> 
            </div> -->
            <div *ngIf="myportals.length === 0" class="create-portal">
                <h1>Gestión de suscripciones</h1>
                <div class="modal-center">
                    <img src="../../assets/icons/box.svg" alt="imagen de cubo">
    
                    <p>Bienvenido a tu portal de administración de Billcentrix, estás a pocos pasos de tener tu plataforma de suscripciones activa.</p>
                    <button (click)="goSettings()">Crea tu primera suscripción</button>
                </div>
            </div>
            <app-my-portal *ngFor="let portal of myportals" [portal]="portal"></app-my-portal>
            <div class="grid">
                
            </div></div>
        
        <!-- <div class="options-buttons">
            <button class="back-btn">Atrás</button>
            <button class="continue-btn">Continuar</button>
        </div> -->
    </div>
</body>