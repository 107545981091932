<div class="bar-preview"><h3>Este es un sitio Demo, podras visualizar como se vera su sitio web segun el template que seleccione</h3><a routerLink="/basic">regresar</a></div>
<div class="main">
    
    
    <div class="login-modal-content">
        <div class="modal-login">
            <div class="background-white">
                <form  [formGroup]="form" >

                    <h1>Sesión</h1>
                    <input   type="text" placeholder="Email">
                    <small 
                    class="form-error-msg"> Correo es requerido </small>

                    <input  type="password" placeholder="Password">
                    <small class="form-error-msg"> Contraseña es requerido </small>

                    <p>¿Olvidaste tu Password?</p>
                    <a >Haz click aquí</a>
                    <div class="options">
                        <!-- <div class="checkbox">
                            <input type="checkbox" id="checkbox-remember" class="cookies">
                            <label for="checkbox-remember">Recuerdame<br>en este sitio</label>
                        </div> -->
                        <button>Entrar</button>
                    </div>

                </form>
               
            </div>
            <div class="transparent-background">
                <p>¿Nueva cuenta?</p>
                <!--button (click)="onRegisterClick()">Regístrate</button-->
                <button>Regístrate</button>
            </div>
        </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
</div>