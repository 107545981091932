<body>
    <div class="main-content">
        <div [ngClass]="{
            'background': selectedPalette === 'Billcentrix',
            'background-modern': selectedPalette === 'Moderna',
            'background-violet': selectedPalette === 'Violeta'
          }">
          <app-header></app-header>
            
            <div class="main-image">
                <div class="text">
                    <h1>Revive Tus Mañanas con nuestro Café Premium</h1>
                <p>Calidad inigualable en cada taza. Suscribete ahora.</p>
                </div>
                <div class="image-right">
                    <img src="../../../assets/images/botellasx3-Header.png" alt="imagenes del header">
                </div>
            </div>
        </div>
        <div [ngClass]="{
            'background2': selectedPalette === 'Billcentrix',
            'background2-modern': selectedPalette === 'Moderna',
            'background2-violet': selectedPalette === 'Violeta'
          }">
            <div class="plans">
                <h1>Nuestro Planes</h1>
                <p>PRECIOS</p>
                <div>
                    <app-plan-contempo [selectedPalette]="selectedPalette"></app-plan-contempo>
                </div>
            </div>
        </div>
        <div [ngClass]="{
            'banner-content': selectedPalette === 'Billcentrix',
            'banner-content-modern': selectedPalette === 'Moderna',
            'banner-content-violet': selectedPalette === 'Violeta'
          }" >
            <div class="image">
                <img src="../../../assets/images/botellasx3-Header.png" alt="imagen del banner">
            </div>
            <div class="text">
                <h1>Aroma en cada taza</h1>
                <p>Nuestro café, vestido de colores vivos, invita a una experiencia única. Ven, siente el calor de la tradición en cada taza.</p>
            </div>
        </div>
        <div [ngClass]="{
            'footer': selectedPalette === 'Billcentrix',
            'footer-modern': selectedPalette === 'Moderna',
            'footer-violet': selectedPalette === 'Violeta'
          }" >
            <h2>Texto en el pie de página</h2>
            <hr>
            <div class="mail">
                <img *ngIf="selectedPalette === 'Billcentrix'" src="../../../assets/icons/mail.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Moderna'" src="../../../assets/icons/mail-green.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Violeta'" src="../../../assets/icons/mail-fucsia.png" alt="icono de mail">
                <h2>Forma@<strong>decontacto.com</strong></h2>
            </div>
        </div>
    </div>
    <!-- <div class="options-buttons">
        <button class="back-btn">Atrás</button>
        <button class="continue-btn">Seleccionar</button>
    </div> -->
</body>