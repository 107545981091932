<div class="plan-basic">
    <div [ngClass]="{
        'plan-basic-content': selectedPalette === 'Billcentrix',
        'plan-basic-content-modern': selectedPalette === 'Moderna',
        'plan-basic-content-violet': selectedPalette === 'Violeta'
      }">
        <div class="carousel-nav-left" (click)="previous()" [ngClass]="{ 'disabled': currentIndex === 0 }">
            <div class="arrow-left"></div>
        </div>
        <div class="carousel-container">
            <div class="preview" *ngFor="let plan of visiblePlans; let i = index">
                <div class="header" >
                    <h1>{{ plan.title }}</h1>
                </div>
                <div class="see-photo"><img [src]="plan.image" alt="Imagen del plan"></div>
              
                <h1 class="big-number">${{ plan.precio }}</h1>
                <div class="including">
                    <p class="option">{{ plan.option }}</p>
                </div>
                <div class="select">
                    <button [routerLink]="'/checkout'">Seleccionar</button>
                </div>
            </div>
            <div class="carousel-nav-right" [ngClass]="{ 'disabled': currentIndex === plans.length - 1 }"
            (click)="next()">
            <div class="arrow-right"></div>
        </div>
        </div>
    </div>
</div>