<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <button (click)="goBack()">Regresar</button>
                <button class="create-plan-btn"><h1>+</h1></button>
                <!-- <img src="../../../assets/icons/planes.png" alt="icono planes"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <form [formGroup]="form" class="needs-validation" > 
            <div class="plans-data">
                <div class="second-headboard">
                    <h1>Planes de suscripción</h1>
                    <div class="linked-icons" >
                        <button (click)="goBack()" >Registro inicial</button>
                        <button class="active">Crear plan</button>
                        <button *ngIf="!showPaymentButton" (click)="goToPortals()">Suscripción activa</button>
                    <button  *ngIf="showPaymentButton" (click)="processPayment()">Activar suscripción</button></div> 
                   
                    <!-- <img src="../../../assets/icons/4123.png" alt="logo de Billcentrix"> -->
                </div>
                <div class="plans-data-text">
                    <strong>¿Qué ofreces?</strong>
                    <p class="line-spacing">Según tu propuesta de valor, crea el plan o planes de pagos para tus productos o
                        servicios.</p>
                </div>
                <!-- <div class="preview-text">
                    <img src="../../../assets/icons/ver.png" alt="vista previa">
                    <p class="text-right">Vista previa</p>
                </div> -->
                <div class="grid">
                    <div class="create-first-plan">
                        <h1>Crea tu plan</h1>
                        <div class="second-grid">
                            <div class="upper-part">
                                <div class="left">
                                    <div class="items">
                                        <div><label for="name">Nombre del plan*</label> <i class="material-icons icon-help" matTooltip="Se refiere al plan de suscripción dentro de tu portal, ej. “plan mensual” o “plan VIP”">help_outline</i> </div>
                                        <input [formControl]="form.controls['name_plan']" tabindex="1" required type="text" placeholder="Ej. Club de suscripción">
                                        <small *ngIf="form.controls['name_plan'].hasError('required') && form.controls['name_plan'].touched" class="form-error-msg">Nombre es requerido</small>
                                    </div>
                                    <div class="items">
                                      <div>  <label for="cost">Costo*</label><i class="material-icons icon-help" matTooltip="Se refiere a lo que quieres cobrar por tu plan según la frecuencia, Ej. $20 al mes.">help_outline</i></div>
                                        <input [formControl]="form.controls['feeCost_plan']" tabindex="3" type="number" required placeholder="Ej. $99">
                                        <small *ngIf="form.controls['feeCost_plan'].hasError('required') && form.controls['feeCost_plan'].touched"
                                                class="form-error-msg"> Costo es requerido </small>
                                    </div>
                                    <!-- <div class="items">
                                        <label for="cost-per-subscription">Costo por inscripción</label>
                                        <input type="text" placeholder="Ej: $199">
                                    </div> -->
                                    <div class="items">
                                       <div> <label for="cost-per-freeze">Costo por inscripción*</label><i class="material-icons icon-help" matTooltip="Se refiere a cuánto le cuesta al cliente final “congelar su plan” qué significa detener el plan de forma indefinida sin desafiliarse.  Usualmente se aplica cuando el cliente está de viaje, para no perderlo.">help_outline</i>
                                    </div>
                                      
                                            <input  [formControl]="form.controls['freezeCostPlan']" tabindex="5" type="number" required placeholder="Ej. $25">
                                              <small  *ngIf="form.controls['freezeCostPlan'].hasError('required') && form.controls['freezeCostPlan'].touched"
                                                 class="form-error-msg"> Costo por inscripción es requerido </small>
                                    </div>
                                    <div class="items">
                                        <div> <label for="cost-per-freeze">Costo por congelamiento*</label><i class="material-icons icon-help" matTooltip="Se refiere a cuánto le cuesta al cliente final “congelar su plan” qué significa detener el plan de forma indefinida sin desafiliarse.  Usualmente se aplica cuando el cliente está de viaje, para no perderlo.">help_outline</i>
                                     </div>
                                       
                                             <input  [formControl]="form.controls['freezeCostPlan']" tabindex="5" type="number" required placeholder="Ej. $25">
                                               <small  *ngIf="form.controls['freezeCostPlan'].hasError('required') && form.controls['freezeCostPlan'].touched"
                                                  class="form-error-msg"> Costo por Congelamiento es requerido </small>
                                     </div>
                                </div>
                                <div class="right">
                                    <div class="items">
                                       <div> <label for="tax">Impuesto*</label> <i class="material-icons icon-help" matTooltip="Se refiere al impuesto según tu tipo de producto. Ej. Comida 0%, alcohol 10%, servicios 7%, etc.">help_outline</i>
                                       </div>
                                    
                                         <input [formControl]="form.controls['taxRate_plan']" tabindex="2" type="number" placeholder="Ej. 5%">
                                         
                                         <small *ngIf="form.controls['taxRate_plan'].hasError('required') && form.controls['taxRate_plan'].touched"
                                                class="form-error-msg"> Impuesto es requerido </small>
                                    </div>
                                    <div class="items">
                                       <div> <label for="frequency">Frecuencia*</label><i class="material-icons icon-help" matTooltip=" Se refiere a la recurrencia de tu plan “mensual, quincenal, bimestral, etc”.">help_outline</i>
                                       </div>
                                        
                                        <select [formControl]="form.controls['frecuency']" tabindex="4" required (change)="onChangeFrecuency($event.target.selectedIndex)">
                                            <option  disabled="" selected="" value="">Seleccione una opcion</option>
                                            <option  *ngFor="let f of frecuencies" [value]="f.id" > {{ f.name_frecuency }} </option>
                                        </select>
                                        <i class="arrow"></i>
                                        <small
                                        *ngIf="form.controls['frecuency'].hasError('required') && form.controls['frecuency'].touched"
                                        class="form-error-msg"> Frecuencia es requerido </small>
                                    </div>
                                    <div class="items">
                                        <div>
                                            <label for="qty_installments_to_collect_plan">Cantidad de Cuotas a cobrar</label>
                                            <i class="material-icons icon-help" matTooltip="Se refiere a la cantidad de cuotas que se cobrará en el plan.">help_outline</i>
                                        </div>
                                        <select [formControl]="form.controls['qty_installments_to_collect_plan']" tabindex="5">
                                            <option value="" disabled >Seleccione una opción</option>
                                            <option value="" selected >No Aplica</option>
                                            <option [ngValue]="1">1 Cuota</option> 
                                            <option *ngFor="let i of [].constructor(47); let index = index" [ngValue]="index + 2">{{ index + 2 }} Cuotas</option>
                                        </select>
                                        <i class="arrow"></i>
                                    </div>
                                    <div class="items">
                                        <div>
                                            <label for="is_visible_plan">Se debe visualizar en el portal*</label>
                                            <i class="material-icons icon-help" matTooltip="Se refiere a si el plan es visible o no.">help_outline</i>
                                        </div>
                                        <select [formControl]="form.controls['is_visible_plan']" tabindex="6" required>
                                            <option value="" disabled selected>Seleccione una opción</option>
                                            <option [value]="true">Sí</option>
                                            <option [value]="false">No</option>
                                        </select>
                                        <i class="arrow"></i>
                                        <small *ngIf="form.controls['is_visible_plan'].hasError('required') && form.controls['is_visible_plan'].touched" class="form-error-msg"> Visibilidad es requerido </small>
                                    </div>
                                </div>
                            </div>
                            <div class="shared-part">
                                <div class="items">
                                    <label for="description">Descripción del plan*</label>
                                    <textarea [formControl]="form.controls['description']" tabindex="6" rows="3" type="text" placeholder="Ej. Reciba su caja de suscripción"></textarea>
                                    <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched"
                                            class="form-error-msg"> Descripción es requerido 
                                    </small>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="left">
                                    <div class="items">
                                        <label for="image">Imagen de portada</label>
                                        <div class="upload-photo">
                                           <div class="first-part">
                                                <input tabindex="7" #inputFile class="form-control" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onLogoChange($event)" >
                                                <!-- <img class="up" src="../../../assets/icons/subir.png" alt="subir foto"> <p>Subir archivo</p> -->
                                            </div>
                                            <!-- <div>
                                                <img  src="../../../assets/icons/subir.png" alt="subir foto"> <p>Subir archivo</p>
                                            </div> -->
                                            <!-- <img src="../../../assets/icons/photo.png" alt="foto"> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="right">
                                    <div class="items">
                                        <label for="plan">Este plan incluye:</label>
                                        <input tabindex="8" type="text" placeholder="Listado de beneficios">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="preview-text2">
                        <img src="../../../assets/icons/ver.png" alt="vista previa">
                        <p>Vista previa</p>
                    </div> -->
                    <div class="preview">
                        <h1>{{ form.controls['name_plan'].value || 'Plan' }}</h1>
                        <div class="see-photo">
                            <img [src]="image64 || '../../../assets/icons/photo.png'" alt="foto">
                        </div>
                        <h3>Beneficio principal</h3>
                        <h1 class="big-number">${{ form.controls['feeCost_plan'].value || '0' }}</h1>
                        <h3>{{ frecuencyName  || 'Mensuales' }}</h3>
                        <div class="including">
                            <ul class="dotted-list">
                                <li>{{ form.controls['description'].value}}</li>
                                <!-- <li>Sunde omnis iste natus</li>
                                <li>Sed ut perspiciatis unde omnis iste natus sit</li> -->
                            </ul>
                        </div>
                        <button>Seleccionar</button>
                    </div>
                </div>
                <div class="save">
                    <button tabindex="9" (click)="send()" >Guardar</button>
                </div>
            </div>
        </form>
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <!-- <button class="continue-btn"  (click)="goToPortals()" >Ir a mis portales</button> -->
            <!-- <button class="save2">Guardar</button>
            <button class="delete">Eliminar</button>
            <button class="post" >Publicar</button> -->
        </div>
    </div>
</body>