import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  constructor(private router : Router) {}
  

  ngOnInit(): void {
  }

   
  goBack(){
    this.router.navigate(['']);
  }
}
