import { Component, OnInit  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-change-template',
  templateUrl: './change-template.component.html',
  styleUrls: ['./change-template.component.scss']
})
export class ChangeTemplateComponent implements OnInit {
  countdown: number = 5;
  onboardingConfigID : any;

  constructor(private activateRoute : ActivatedRoute,private router: Router) {}

  ngOnInit() {
    this.activateRoute.params.subscribe( params =>{
      this.onboardingConfigID = params['id'];
        const counter = interval(1000).pipe(take(this.countdown));
        counter.subscribe(
          (value) => this.countdown = this.countdown - 1,
          (err) => console.error(err),
          () => this.navigateToHome()
        );
    }, err =>{
     
    });
  }

  navigateToHome() {
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID]);
  } 
}
