<div class="preview">
    <div class="header">
        <h3>{{plan.name_plan}}</h3><img src="/assets/icons/etiqueta.svg" alt="">
        <!-- <img src="../../../assets/icons/etiqueta-celeste.png" alt=""> -->
    </div>
    <div class="see-photo">
        <img [src]="imagePath || '../../../assets/icons/photo.png'" alt="foto">
    </div>
    <h3>Beneficio principal</h3>
    <h1 class="big-number">${{plan.feeCost_plan}}</h1>
    <h4>{{plan.frecuency.name_frecuency}}</h4>
    <div class="including">
        <h5 class="dotted-list">
            <li>{{plan.description}}</li>
            <!-- <li>Sunde omnis iste natus</li>
            <li>Sed ut perspiciatis unde omnis iste natus sit</li> -->
        </h5>
    </div>
    <div class="options">
        <button class="edit-bt" (click)="goEdit()"> <img src="../../../assets/icons/editar.png" alt="editar plan">
            <p>Editar</p>
        </button>
        <button *ngIf="plan.status_plan == 2" (click)="onUpdateStatus(plan.id,1)"> <img src="../../../assets/icons/ok.png" alt="activar plan">
            <p>Activar</p>
        </button>
        <button *ngIf="plan.status_plan == 1" (click)="onUpdateStatus(plan.id,2)"> <img src="../../../assets/icons/eliminar.png" alt="desactivar plan">
            <p>Desactivar</p>
        </button>
    </div>
</div>