import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
/* Angular Material */
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
//nuevos agregador, portales
import { MatSidenavModule } from '@angular/material/sidenav'; // Importa MatSidenavModule
import { MatToolbarModule } from '@angular/material/toolbar';
//
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthGuard } from './guards/auth.guard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './common/footer/footer.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { LoginOnboardComponent } from './login-onboard/login-onboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { MenuComponent } from './common/menu/menu.component';
import { PreferencesComponent } from './account-settings/preferences/preferences.component';
import { PlansComponent } from './account-settings/plans/plans.component';
import { PlanComponent } from './partials/plan/plan.component';
import { MyPlansComponent } from './account-settings/plans/my-plans/my-plans.component';
import { PortalComponent } from './account-settings/portal/portal.component';
import { MyPortalComponent } from './partials/my-portal/my-portal.component';
import { MyPortalsComponent } from './account-settings/portal/my-portals/my-portals.component';
import { PortalCustomizationComponent } from './account-settings/portal-customization/portal-customization.component';
import { BasicComponent } from './plans-preview/basic/basic.component';
import { PlanBasicComponent } from './partials/plan-basic/plan-basic.component';
import { PracticalComponent } from './plans-preview/practical/practical.component';
import { PlanPracticalComponent } from './partials/plan-practical/plan-practical.component';
import { ContempoComponent } from './plans-preview/contempo/contempo.component';
import { PlanContempoComponent } from './partials/plan-contempo/plan-contempo.component';
import { FAQsComponent } from './faqs/faqs.component';
import { FAQsHoversComponent } from './faqs-hovers/faqs-hovers.component';
import { ReportsComponent } from './reports/reports.component';
import { SliderComponent } from './partials/slider/slider.component';
import { MenuPreviewComponent } from './common/menu-preview/menu-preview.component';
import { UpdatePortalComponent } from './account-settings/update-portal/update-portal.component';
import { PaymentPopupComponent } from './account-settings/plans/payment-popup/payment-popup.component';
import { FacPopupComponent } from './account-settings/plans/fac-popup/fac-popup.component';
import { PreviewPopupComponent } from './account-settings/portal-customization/preview-popup/preview-popup.component';
import { UpdatePortalTemplatesComponent } from './account-settings/update-portal-templates/update-portal-template.component';
import { UpdatePortalFeaturesComponent } from './account-settings/update-portal-features/update-portal-features.component';
import { UpdateSliderComponent } from './account-settings/update-portal-features/update_slider/update-slider.component';
import { UpdatePlanComponent } from './account-settings/update-portal-plan/update-plan/update-plan.component';
import { CreatePlanComponent } from './account-settings/update-portal-plan/create-plan/create-plan.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotFoundComponent } from './not found/not-found.component';
import { UnauthorizedStepComponent } from './unauthorized-step/unauthorized-step.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CongratulationComponent } from './congratulation/congratulation.component';
import { ChangeTemplateComponent } from './change-template/change-template.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import { CustomerPlansComponent } from './customer-plans/customer-plans.component';
import { CustomerCardsComponent } from './customer-cards/customer-cards.component';
import { CustomerInvoicesComponent } from './customer-invoices/customer-invoices.component';
import { CustomerUpdateComponent } from './customer-update/customer-update.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { CreateCardPopupComponent } from './customer-cards/create-card-popup/create-card-popup.component';
import { CustomerPlanUpdateComponent } from './customer-plans/customer-plan-update/customer-plan-update.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import { CheckoutComponent } from './partials/checkout/checkout.component';
import { NavbarcheckoutComponent } from './partials/navbar/navbar.component';
import { HeaderComponent } from './partials/header/header.component';
import { SidebarComponent } from './partials/sidebar/sidebar.component';
import { DashboardComponent } from './partials/dashboard/dashboard.component';
import { ContentDashboardComponent } from './partials/content-dashboard/content-dashboard.component';
import { CreditCardComponent } from './partials/credit-card/credit-card.component';
import { SuscriptionComponent } from './partials/mis-suscripciones/suscription/suscription.component';
import { DireccionesComponent } from './partials/direcciones/direcciones.component';
import { AddressComponent } from './partials/direcciones/address/address.component';
import { CreditcardInfoComponent } from './partials/credit-card/creditcard-info/creditcard-info.component';
import { ChangePassComponent } from './partials/change-pass/change-pass.component';
import { LoginpreviewComponent } from './partials/login/login.component';
import { AddClientComponent } from './add-client/add-client.component';
import { ConfirmDialogComponent } from './partials/my-portal/confirm-dialog/confirm-dialog.component';
import { CustomerAddressUpdateComponent } from './customer-address-update/customer-address-update.component';
import { ConfigurationsComponent } from './account-settings/configurations/configurations.component';
// import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ProcessingComponent } from './processing/processing.component';
import { AppDialogContainerComponent } from './account-settings/plans/app-dialog-container/app-dialog-container.component';
import {Logging} from "./interceptors/logging.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    FooterComponent,
    NavbarComponent,
    LoginComponent,
    LoginOnboardComponent,
    AccountSettingsComponent,
    MenuComponent,
    PreferencesComponent,
    PlansComponent,
    PlanComponent,
    MyPlansComponent,
    PortalComponent,
    MyPortalComponent,
    MyPortalsComponent,
    PortalCustomizationComponent,
    BasicComponent,
    PlanBasicComponent,
    PracticalComponent,
    PlanPracticalComponent,
    ContempoComponent,
    PlanContempoComponent,
    FAQsComponent,
    FAQsHoversComponent,
    ReportsComponent,
    SliderComponent,
    MenuPreviewComponent,
    UpdatePortalComponent,
    UpdatePortalTemplatesComponent,
    UpdatePortalFeaturesComponent,
    PaymentPopupComponent,
    FacPopupComponent,
    PreviewPopupComponent,
    UpdateSliderComponent,
    UpdatePlanComponent,
    CreatePlanComponent,
    CreateAccountComponent,
    UnauthorizedComponent,
    UnauthorizedStepComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    CongratulationComponent,
    ChangeTemplateComponent,
    CustomerPlansComponent,
    CustomerCardsComponent,
    CustomerInvoicesComponent,
    CustomerUpdateComponent,
    CustomerPlanUpdateComponent,
    CreateCardPopupComponent,
    CustomerInvoiceDetailComponent,
    CheckoutComponent,
    NavbarcheckoutComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    ContentDashboardComponent,
    CreditCardComponent,
    SuscriptionComponent,
    DireccionesComponent,
    AddressComponent,
    CreditcardInfoComponent,
    ChangePassComponent,
    LoginpreviewComponent,
    AddClientComponent,
    ConfirmDialogComponent,
    CustomerAddressUpdateComponent,
    ConfigurationsComponent,
    ProcessingComponent,
    AppDialogContainerComponent
  ],
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatSelectModule,
    MatTooltipModule,
    BrowserModule,
    MatSlideToggleModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true}), // ToastrModule added
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },{ provide: HTTP_INTERCEPTORS, useClass: Logging, multi: true },AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
