<body>
    <div class="main-content">
        <div class="logoblock">
            <img src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
            <div><app-menu></app-menu></div>
        </div>
        
        <div class="create-portal">
            <h1>Gestión de suscripciones</h1>
            <div class="modal-center">
                <img src="../../assets/icons/box.svg" alt="imagen de cubo">

                <p>Bienvenido a tu portal de administración de Billcentrix, estás a pocos pasos de tener tu plataforma de suscripciones activa.</p>
                <button (click)="goStep()">{{ buttonText }}</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</body>