import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { WebService } from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  form: FormGroup;
  public username: string = '';
  public partner: any;

  provincesList: any[] = [];
  provincesGeneralList: any[] = [];
  corregimientos: { [key: string]: any[] } = {};
  corregimientosSelected: any[] = [];
  emailPaymentSucceeded!: boolean;
  emailChangeMethodPayment: boolean;
  whatsappChangeMethodPayment: boolean;
  emailPaymentFailed!: boolean;
  settings : any;
  partnerSetting : any;

  corregimientosToSend : any[] = [];
  previousValuesToSend : any[] = [];

  constructor(
    private spinner: SpinnerService,
    private webService: WebService,
    private toast: ToastService,
    private router: Router
  ) {
    this.form = this.updateFormGroup();
  }

  ngOnInit(): void {

    const sesion = JSON.parse(localStorage.getItem('sesion')!);
    if(sesion.partner != null || sesion.partner != undefined){
      this.spinner.open();
      this.webService.get(this.webService.HOST + "/province").subscribe(response => {
        this.provincesList = response.result; // CARGAMOS TODAS LAS PROVINCIAS ACTIVAS DEL SISTEMA
        this.provincesGeneralList = response.result;
        this.partner = sesion.partner; // TOMAMOS EL PARTNER EN SESION
        this.loadPartnerSettings();
        this.webService.get(this.webService.HOST + "/partner/" + this.partner).subscribe(partner => {
          console.log(partner);
          // PROCEDEMOS A SETEAR EN PANTALLA LAS PROVINCIAS QUE TIENE ESE PARTNER ASOCIADAS EN LA TABLA PROVINCES PARTNERS
          this.form.controls["provinces"].setValue(this.provincesList.filter(item1 => partner.result.provinces.some(item2 => item1.id === item2.id)));
          // AQUI VAMOS A RECORRER Y ARMAR UN NUEVO ARREGLO QUE CONTENDRÁ LOS CORREGIMIENTOS QUE TIENE ESE PARTNER ASOCIADOS
          partner.result.partnerCorregimientos.forEach(element => {
            this.corregimientosSelected.push(element.corregimiento);
          });
          // AQUI LO QUE HACEMOS ES DETERMINAR SI DEBEMOS PRESELECCIONAR TODOS LOS CORREGIMIENTOS EN PANTALLA O NO
          const preSelectCorregimientos = this.corregimientosSelected.length !== 0;
          this.onProvinceChange(preSelectCorregimientos);
        });
      }, err => {
        this.spinner.close();
        console.log(err);
      });
    }else {
      this.toast.showInfo("Por favor, creación el proceso de creacion de su cuenta")
    }

  }

  onProvinceChange(selectAll: boolean) {
    this.corregimientos = {}; // LIMPIAMOS EL ARREGLO EN PANTALLA
    // RECORRER TODAS LAS PROVINCIAS ASIGNADAS AL PARTNER Y OBTENER LOS CORREGIMIENTOS ASOCIADOS
    this.form.value.provinces.forEach((p: any) => {
      const provinceCorregimientos = this.provincesGeneralList.find(t => t.id === p.id)?.corregimientos || [];
      this.corregimientos[p.id] = provinceCorregimientos.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    });

    if (selectAll) {
      const allCorregimientos: any[] = Object.values(this.corregimientos).reduce((acc: any[], val: any[]) => acc.concat(val), []);
      this.form.controls["corregimientos"].setValue(allCorregimientos);
    } else {
      const selectedCorregimientos: any[] = Object.values(this.corregimientos)
        .reduce((acc: any[], val: any[]) => acc.concat(val), [])
        .filter((item1: any) => this.corregimientosSelected.some((item2: any) => item1.id === item2.id));
      this.form.controls["corregimientos"].setValue(selectedCorregimientos);
    }
    this.spinner.close();
  }

  onCorregimientosChange(selectedValues: any, provinceId: any): void {
    // Handle the change event here
    let arraySameProvince: any[] = [];
    this.corregimientosSelected.forEach(selected => {
      arraySameProvince.push(...this.corregimientos[provinceId].filter(item => item.id == selected.id));
    });
    // Function to check if an element exists in an array
    const existsInArray = (array: any[], element: any) => {
      return array.some(item => item.id === element.id);
    };
    // Find elements in arraySameProvince that are not in selectedValues
    let differentElements = arraySameProvince.filter(item => !existsInArray(selectedValues, item));

    // Remove differentElements from this.corregimientosSelected
    this.corregimientosSelected = this.corregimientosSelected.filter(item => 
      !existsInArray(differentElements, item)
    );

    this.previousValuesToSend.push(...selectedValues)
    this.previousValuesToSend.forEach(c => {
      if(c.province == null || c.province == undefined){
        c.province = provinceId
      }
    });
    this.previousValuesToSend = Array.from(new Set(this.previousValuesToSend.map(item => JSON.stringify(item))))
                                     .map(item => JSON.parse(item));

                                      // Filter out items that do not match the provinceId or are not in selectedValues
    this.previousValuesToSend = this.previousValuesToSend.filter(item => 
      item.province === provinceId && selectedValues.some(val => val.id === item.id)
  );

    if(this.corregimientosToSend.length == 0){
      this.corregimientosToSend.push(...JSON.parse(JSON.stringify(this.previousValuesToSend)));
    }else{
      
      this.previousValuesToSend.forEach(c =>{
        this.corregimientosToSend.push(c)
      });
    
      // Remove duplicates
      this.corregimientosToSend = Array.from(new Set(this.corregimientosToSend.map(item => JSON.stringify(item))))
      .map(item => JSON.parse(item));

      let temporaryArray = JSON.parse(JSON.stringify(this.corregimientosToSend.filter(item => 
        item.province !== provinceId)));
    
      // Filter out items that do not match the provinceId or are not in selectedValues
      this.corregimientosToSend = this.previousValuesToSend.filter(item => 
        item.province === provinceId && selectedValues.some(val => val.id === item.id));

      this.corregimientosToSend.push(...temporaryArray);

    }
  }

  updateFormGroup(): FormGroup {
    return new FormGroup({
      provinces: new FormControl(''),
      corregimientos: new FormControl('')
    });
  }

  // Función personalizada de validación
  passwordsMatchValidator(passwordField: AbstractControl): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === passwordField.value ? null : { 'mismatch': true };
    };
  }

  send() {
    this.spinner.open();
    this.sendConfigurations();
    const userId = JSON.parse(localStorage.getItem('sesion')!).user_id;

    let provincesToSend: any[] = [];
    this.form.value.provinces.forEach((province: any) => {
      provincesToSend.push({ id: province.id });
    });

    this.corregimientosToSend.push(...this.corregimientosSelected);
    // Remove duplicates
    this.corregimientosToSend = Array.from(new Set(this.corregimientosToSend.map(item => JSON.stringify(item))))
    .map(item => JSON.parse(item));

    const json = {
      provinces: provincesToSend,
      corregimientos: this.corregimientosToSend.length == 0 ? this.corregimientosSelected : this.corregimientosToSend,
      partner: this.partner,
      user: userId
    };
    this.webService.put(json, this.webService.HOST + "/onboarding/partner").subscribe(response => {
      this.spinner.close();
      this.toast.showSuccess("Información actualizada con éxito");
      // Reload the page by navigating to the same route
      this.router.navigate([this.router.url]);
    }, err => {
      this.spinner.close();
      this.toast.showError("Error al actualizar la información");
      console.error(err);
    });
  }

  goBack() {
    this.router.navigate(["/my-portals"]);
  }

  removeProvince(province: any) {
    const currentProvinces = this.form.controls['provinces'].value.filter((p: any) => p.id !== province.id);
    this.form.controls['provinces'].setValue(currentProvinces);
    this.onProvinceChange(false);
  }




    // Método para cargar las configuraciones del Partner
    loadPartnerSettings(): void {
        this.webService.get(this.webService.HOST + "/partnerSetting/partner/" + this.partner).subscribe(partnerSettings =>{
            this.partnerSetting = partnerSettings.result;
            this.emailPaymentSucceeded = partnerSettings.result.emailPaymentSucceeded;
            this.emailPaymentFailed = partnerSettings.result.emailPaymentFailed;
            this.emailChangeMethodPayment = partnerSettings.result.emailChangeMethodPayment;
            this.whatsappChangeMethodPayment = partnerSettings.result.whatsappChangeMethodPayment;
        }, err =>{
          console.error(err);
          this.toast.showError(err)
        })
}

sendConfigurations(){
  this.partnerSetting.emailPaymentSucceeded = this.emailPaymentSucceeded;
  this.partnerSetting.emailPaymentFailed = this.emailPaymentFailed;
  this.partnerSetting.emailChangeMethodPayment = this.emailChangeMethodPayment;
  this.partnerSetting.whatsappChangeMethodPayment = this.whatsappChangeMethodPayment;
  this.partnerSetting.partner = {id : this.partnerSetting.partner.id}

  delete this.partnerSetting.createdAt;
  delete this.partnerSetting.updatedAt;

  this.webService.put(this.partnerSetting,this.webService.HOST + "/partnerSetting/" + this.partnerSetting.id).subscribe(response =>{

  }, err =>{
    console.error(err);
    this.toast.showError(err);
  })
}

onToggleChange(): void {
  const updatedSettings = {
    partner_settings: {
      email_payment_succeeded: this.emailPaymentSucceeded,
      email_payment_failed: this.emailPaymentFailed,
      email_change_method_payment : this.emailChangeMethodPayment,
      whatsapp_change_method_payment : this.whatsappChangeMethodPayment
    }
  };
}

}
