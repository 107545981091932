<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button (click)="goBack()">Regresar</button>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="setting-data">
            <div class="second-headboard">
                <h1>Actualizar información del Cliente</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
               
            </div>
            <div class="general-data">
                <h1>Datos generales</h1>
                <form [formGroup]="form" >
                <div class="grid">
                    <div class="left">
                        <div class="items">

                            <label for="name">Servicio*</label>
                            <input [formControl]="form.controls['service']" 
                                    type="text" readonly   value="{{ chp?.plan?.service?.name_service || '-' }}">
                                    

                        </div>

                        <div class="items">
                            <label for="code">Nombre*</label>
                            <input type="text" readonly   value="{{ customer?.name_customer || '-' }} {{customer?.lastName_customer || '-' }}">
                        </div>

                        <div class="items">
                            <label for="email">Número de contrato*</label>
                            <input [formControl]="form.controls['contractNumber_Plan']" 
                            type="text" readonly  >

                        </div>

                        <div class="items">
                            <label for="email">Fecha de inscripción*</label>
                            <input type="text" readonly  [formControl]="form.controls['startdate_customerPlan']">
                        </div>

                        <div class="items">
                            <label for="tdc">Tarjeta de crédito*</label>
                             <div class="select-wrapper">
                                <select id="tdc" [formControl]="form.controls['tdc']" >
                                        <option disabled="" selected="" value="">Seleccione una opción</option>
                                        <option  *ngFor="let tarjeta of tarjetas" [value]="tarjeta.id">{{tarjeta.maskCreditCard}} - {{tarjeta.nameHolderCreditCard}} </option>
                                </select>
                                <i class="arrow"></i>
                                <small *ngIf="form.controls['tdc'].hasError('required') && form.controls['tdc'].touched"
                                        class="form-error-msg"> Tarjeta es querido
                                </small>
                            </div> 
                        </div>

                      

                    </div>


                    <div class="right">

                        <div class="items">
                            <label for="address">Plan*</label>
                            <div class="select-wrapper">
                                <select id="plan" [formControl]="form.controls['plan']" >
                                    <option disabled="" selected="" value="">Seleccione una opción</option>
                                    <option [disabled]="true"  *ngFor="let plan of servicePlans" [value]="plan.id">{{plan.name_plan}} </option>
                                </select>
                                <i class="arrow"></i>
                                <small *ngIf="form.controls['plan'].hasError('required') && form.controls['plan'].touched"
                                        class="form-error-msg"> Plan es querido
                                </small>
                            </div>

                           

                            <!-- <input type="text" readonly   value="{{ chp?.plan?.name_plan || '-' }}"> -->
                        </div>

                        <div class="items">
                            <label for="status_customerPlan">Estatus*</label>
                             <div class="select-wrapper">
                                <select id="status_customerPlan" [formControl]="form.controls['status_customerPlan']" >
                                        <option disabled="" selected="" value="">Seleccione una opción</option>
                                        <option [disabled]="true" *ngFor="let status of estatus" [value]="status.value">{{status.description}} </option>
                                </select>
                                <i class="arrow"></i>
                                <small *ngIf="form.controls['status_customerPlan'].hasError('required') && form.controls['status_customerPlan'].touched"
                                        class="form-error-msg"> Estatus es querido
                                </small>
                               
                            </div> 
                        </div>

                        <div class="items">
                            <label for="address">Código de renovación de matricula del partner*</label>
                            <input [formControl]="form.controls['idPlanEnrollmentrenewalPartner']" 
                            type="text" readonly  >

                        </div>


                        <div class="items">
                            <label for="address">Fecha de culminación*</label>
                            <div class="culminacion_date">

                                <input [formControl]="form.controls['endDate_customerPlan']" 
                                type="text" readonly  >
</div>
                        </div>

                        <div *ngIf="initialStatus != 2" class="items">
                            <label for="email">Próxima fecha de cobro*</label>
                            <input type="text" readonly   value="{{ chp?.next_bill_date }}">
                        </div>
                       
                    </div>
                </div>
                </form>
            </div>
        </div>

        
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <!-- [disabled]="!showPaymentButton" -->
            <button  [disabled]="form.invalid" class="continue-btn" (click)="update()" >Guardar información</button>
        </div>
    </div>
</body>
