import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from "../plan.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentPopupComponent } from '../payment-popup/payment-popup.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-plans',
  templateUrl: './my-plans.component.html',
  styleUrls: ['./my-plans.component.scss']
})
export class MyPlansComponent implements OnInit{
  public urlCliente: string = '';
  private onboardingConfigID: any;
  public myplans : any = [];
  showPaymentButton : Boolean = false;
  isActive: boolean = false;

  constructor(private location: Location,private dialog: MatDialog,private planService : PlanService, private router : Router,private activateRoute : ActivatedRoute,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }


  ngOnInit(): void {
    this.planService.plans.subscribe( plans =>{

      var sesion = JSON.parse(localStorage.getItem('sesion')!);
      // BUSCAMOS TODOS LOS BANCOS
      let partner = -1;
      if(sesion.partner != null || sesion.partner != undefined){
        partner = sesion.partner
      }

      // NICO
       this.activateRoute.params.subscribe(params =>{
       this.webService.get(this.webService.HOST + "/onboardingConfig/" + params["id"] + "?partner=" + partner).subscribe( response =>{
        this.urlCliente = response.result.url_client;
        this.isActive = response.result.active; 
      
      }, err =>{
      })
   })



      if(plans.length > 0){
        this.myplans = [];
        this.myplans.push(...plans);
      }else{
        this.goCreatePlan();
      }

    })
    this.activateRoute.params.subscribe(params =>{
      this.onboardingConfigID = params['id'];
      this.getConfigByID();
    }, err =>{
      this.toast.showError(err);
    });

  }

  getConfigByID(){
    this.spinner.open();
    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    let partner = -1;
    if(sesion.partner != null || sesion.partner != undefined){
      partner = sesion.partner
    }

    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID + "?partner=" + partner).subscribe( config =>{
      this.spinner.close();
      
      // if(config.result.service.current_onboarding_step >= 5){
        if(config.result.customerHasPlan != null ){
          if(config.result.customerHasPlan.status_customerPlan == 1){
            console.log('Status of customerHasPlan:', config.result.customerHasPlan.status_customerPlan);
            var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
            var today = new Date();
            var isValidDate = nextBillDate > today;
            if(!isValidDate){
              this.showPaymentButton = false;
            }
          }else{
            this.showPaymentButton = true;
          }
          // if(config.result.customerHasPlan.status_customerPlan != 1){
          //   this.showPaymentButton = true;
          // }

        }else{
          this.showPaymentButton = true;
        }
      // }
      console.log("PASA POR AQUI ANTES DE CARGAR LOS PLANES");
      this.planService.plans.emit(config.result.plans)
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
      setTimeout(() => {
        this.location.back();
      }, 2000); // 3000 milliseconds = 3 seconds
    });
  }

  goCreatePlan(){
    this.router.navigate(['create'], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }


  handleCreatePlanClick() {
    this.router.navigateByUrl('/plans');
  }

  goBack(){
    this.router.navigate([".."], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  goToTemplates(){

    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/portal']);
  }

  goToEdit(){

    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID]);
  }

  goToFeatures(){

    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/features']);
  }

  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

  processPayment(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {  configID : this.onboardingConfigID }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }
      })
  }


}
