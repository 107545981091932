<div class="row">
  <div style="height: 100%;" class="col-lg-12">
    <div class="card card-sm card-transition h-100" href="../documentation/bs-icons.html" data-aos="fade-up">
      <div class="card-body">
        <h2 style="float: contour" class="card-title text-inherit">Vista previa</h2>
        <div>
          <button (click)="dialogRef.close()" style="float: right">Cerrar</button>
        </div>
      </div>

      <iframe style="height: 800px;" [src]="getUrlTemplatePreview()" title="description"></iframe>

    </div>
  </div>
</div>
