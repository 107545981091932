import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { WebService}  from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  form: FormGroup;
  public username: string = '';

  constructor(private spinner: SpinnerService, private webService: WebService, private toast: ToastService,
    private router : Router) {
    this.form = this.createFormGroup();
  }

  ngOnInit(): void {
    this.username = localStorage.getItem('username') || ''; 
    console.log(`stored username ${this.username}`);
  }

  createFormGroup(): FormGroup {
    const newPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    const newPasswordConfirm = new FormControl('', [Validators.required, this.passwordsMatchValidator(newPassword)]);

    return new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: newPassword,
      newPasswordConfirm: newPasswordConfirm
    });
  }

  // Función personalizada de validación
  passwordsMatchValidator(passwordField: AbstractControl): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === passwordField.value ? null : { 'mismatch': true };
    };
  }

  send(){
    this.form.value.user = JSON.parse(localStorage.getItem('sesion')!).user_id;

    if (!this.form.valid){
      return this.toast.showError("Por favor complete el formulario correctamente...");
    }

    this.spinner.open();

    this.webService.put(this.form.value, this.webService.HOST + "/onboarding/changePassword").subscribe( response =>{
      this.spinner.close();
      this.form.reset();
      this.toast.showSuccess("Contraseña cambiada con exito");
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
      console.log(err)
    })
  }

  goBack(){
    this.router.navigate(["/my-portals"]); 
  }
}
