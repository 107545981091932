<body>
    <header>
    <div class="grid-overlay">
        <div class="nav">
            <a href="https://billcentrix.com" class="buttondesign" >Conoce Billcentrix Enterprise</a>   <a (click)="onLoginClick()">Mi cuenta</a>  
        </div>
        <div class="block-one">
            <div class="left">
                <img (click)="goHome()" src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
                <h1><b>Automatiza los cobros</b><br> y gestiona tu programa <br>de <b>suscripciones</b></h1>
                <div class="reg">
                    <button (click)="onSignUp()">Regístrate</button>
                    <p>¿Necesitas ayuda? <a href="mailto:info@billcentrix.com">Contáctanos</a></p>
                </div>
            </div>
            <div class="right">
                <div class="tab">
                    <img src="../../assets/images/Billcentrix-icon-cash.svg" alt="Logo Billcentrix">
                    <p>Gestionamos tus suscripciones<br> maximizando el éxito de tus cobros</p>
                </div>
                <div class="tab">
                    <img src="../../assets/images/Billcentrix-icon-check.svg" alt="Logo Billcentrix">
                    <p>Permite cobrar deudas automáticamente<br> cuando la tarjeta tiene saldo disponible</p>
                </div>
                <div class="tab">
                    <img src="../../assets/images/Billcentrix-icon-message.svg" alt="Logo Billcentrix">
                    <p>Envía mensajes de <img class="iconw" src="../../assets/icons/whatsapp.svg" alt="whatsapp">Whatsapp y correos<br> electrónicos a tus clientes en morosidad</p>
                </div>
                <div class="tab">
                    <img src="../../assets/images/Billcentrix-icon-box.svg" alt="Logo Billcentrix">
                    <p>Incluye un portal web opcional para que <br> tus clientes se afilien en línea</p>
                </div>
                <h3>Plan único $49.99 al mes + 1% por transacción</h3>
                <p>*Registrarte es gratis, comienzas a pagar cuando publicas tu negocio.</p>
            </div>
        </div>
        
</div>
</header>
        <div class="block-two">
            <h3>Ideal para</h3>
            <div class="circlebox">
                <div class="circle">
                    <img src="../../assets/images/escuelas.png" alt="Escuelas">
                    <h3>Escuelas</h3>
                </div>
                <div class="circle">
                    <img src="../../assets/images/entrenar.png" alt="Entrenado personal">
                    <h3>Entrenadores<br> personales</h3>
                </div>
                <div class="circle">
                    <img src="../../assets/images/administracion.png" alt="administracion de condominios">
                    <h3>Administración<br> de condominios</h3>
                </div>
                <div class="circle">
                    <img src="../../assets/images/suscripcion.png" alt="Productos de suscripcion">
                    <h3>Productos por <br>suscripción</h3>
                </div>
            </div>
        </div>
        <div class="block-tree">
            <div class="two-column">
                <div class="logos">
                    <h3>Algunos de nuestros clientes</h3>
                    <div class="box-logo">
                        <div class="blogo">
                            <img class="aliados" src="../../assets/images/felipe.svg" alt="Felipe de Motta">
                            <a target="_blank" href="https://club.felipemotta.com/">ver</a>
                        </div>
                        <div class="blogo">
                            <img class="aliados" src="../../assets/images/powerclub.svg" alt="powerclub">
                            <a target="_blank" href="https://www.powerclubpanama.com">ver</a>
                        </div>
                        <div class="blogo">
                            <img class="aliados" src="../../assets/images/cafe_unido.svg" alt="Cafe Unido">
                            <a target="_blank" href="https://club.cafeunido.com">ver</a>
                        </div>
                    </div>
                    <h3>Bancos afiliados</h3>
                    <div class="box-logo">
                        <div class="blogo">
                            <img src="../../assets/images/bac.svg" alt="Bac">
                           
                        </div>
                        <div class="blogo">
                            <img src="../../assets/images/bancoAliado.svg" alt="Banco Aliado">
                            
                        </div>
                        <div class="blogo">
                            <img src="../../assets/images/mercantil.svg" alt="Banco mercantil">
                           
                        </div>
                        <div class="blogo">
                            <img src="../../assets/images/logo-bg-2.svg" alt="Banco mercantil">
                           
                        </div>
                    </div>
                </div>
                
                <div class="icon">
                    <img src="../../assets/images/loop_cash.svg" alt="Banco mercantil">
                </div>
            </div>
            <p>Billcentrix procesa tarjetas de crédito con un terminal virtual de su empresa que está asociado a su cuenta bancaria. Billcentrix lo ayudará a abrir su cuenta de comercio en alguno de nuestros bancos afiliados. No necesita una cuenta para probar nuestro servicio. </p>
        </div>
    <app-footer></app-footer>
    <div class="modal-login"><app-login *ngIf="!showWelcomeComponent"></app-login></div>
    <a Target="_blank" href="https://wa.me/50766114000?text=Hola,%20estoy%20interesado%20en%20automatización%20de%20cobros,%20me%20gustar%C3%ADa%20conocer%20m%C3%A1s%20informaci%C3%B3n"><img class="chat" src="../../assets/icons/whatsapp_chat.svg" alt=""></a>
</body>