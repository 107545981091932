import { HttpParams } from '@angular/common/http';

export class HistoricFilter {

    id: String | undefined;
    dateSearchType : number | undefined ;
    dateFrom : String | undefined ;
    dateUntil : String | undefined ;
    status : number | undefined ;
    minimum : String | undefined ;
    maximum : String | undefined ;
    name_customer: string | undefined;
    email_customer : string | undefined;


    constructor(filter?: HistoricFilter) {
        if (filter) {
            this.id = filter.id;
            this.name_customer = filter.name_customer;
            this.email_customer = filter.email_customer;
            this.dateSearchType = filter.dateSearchType;
            this.dateUntil = filter.dateUntil;
            this.dateFrom  = filter.dateFrom;
            this.status = filter.status;
            this.minimum = filter.minimum;
            this.maximum = filter.maximum;
        }
    }

    getHttpParams(httpParams = new HttpParams()): HttpParams {

        if (this.id) {
            httpParams = httpParams.set('id', this.id.toString());
        }

        if(this.dateSearchType){
            httpParams = httpParams.set('dateSearchType', this.dateSearchType);
        }
        if(this.dateUntil && this.dateUntil !== ''){
            httpParams = httpParams.set('dateUntil', this.dateUntil.toString());
        }
        if (this.dateFrom && this.dateFrom !== '') {
            httpParams = httpParams.set('dateFrom', this.dateFrom.toString());
        }
        if(this.status){
            httpParams = httpParams.set('status', this.status);
        }
        if(this.minimum && this.minimum !== ''){
            httpParams = httpParams.set('minimum', this.minimum.toString());
        }
        if(this.maximum && this.maximum !== ''){
            httpParams = httpParams.set('maximum', this.maximum.toString());
        }
        if (this.name_customer) {
            httpParams = httpParams.set('name_customer', this.name_customer);
        }
        if( this.email_customer){
            httpParams = httpParams.set('email_customer', this.email_customer);
        }
        return httpParams;
    }
}