 <!-- app-suscription -->
 <div class="card-body">
    <div class="row">

        <div class="column">
            <div class="logo-bank">
                <img src="/assets/images/subscription.png" alt="">
            </div>
            <div class="credit_data">
                <h4 class="tx_tipo"> Mi Suscripciones
                    <span class="estatus">plan 
          </span>
                    </h4>
                <p class="tc_number"> Costo $19.99 </p>
                <span class="verified"> Próxima facturación es el 10/04/2025 </span>
            </div>
            <button class="disable">Actualizar</button>
        </div>

    </div>
 </div>
