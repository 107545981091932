import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { ThemeService } from '../../theme.service';
//import {  AddCardFormComponent } from '../add-card-form/add-card-form.component'
import { MatDialog } from '@angular/material/dialog';
//import { AddAddressComponent } from '../add-address/add-address.component';
//import { LoaderService } from 'src/app/services/loader.service';
//import { NotificationService } from 'src/app/services/notification.service';
//import { WebService } from 'src/app/services/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';




@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})

export class CreditCardComponent {

  tdc: any = [];
  tarjetas : any = [];
  items: Array<any> = [];
  
  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  constructor(private dialog: MatDialog,private auth : AuthService  ){


  }


}