import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateCardPopupComponent } from './create-card-popup/create-card-popup.component';
import { AppConfirmService } from '../app-confirm/app-confirm.service';
// import { HasplanFilter } from './hasplan.filter';

@Component({
  selector: 'app-customer-cards',
  templateUrl: './customer-cards.component.html',
  styleUrls: ['./customer-cards.component.scss'],
  providers : [AppConfirmService]
})
export class CustomerCardsComponent implements OnInit {

  tarjetas : any;
  editar = false;
  estado = false;
  IDcustomer : any;
  confirm: boolean = false;
  res : any;
  items : any;

      // PARA MANEJO DE TABLAS
      @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
      @ViewChild(MatSort) sort: MatSort | undefined;
      displayedColumns: string[] = ['maskCreditCard', 'expMonthCreditCard', 'expMonth' ,'expYearCreditCard', 'nameHolderCreditCard', 'createdAt','actions'];
      dataSource: any;

    constructor(
      public  tableService: TableService<any>,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private dialog: MatDialog,
      private confirmService: AppConfirmService,
      private cdr: ChangeDetectorRef,private webService : WebService,private spinner : SpinnerService,private toast : ToastService) {
        this.tableService = new TableService;
      }

      ngOnInit() {
        this.getItems()
      }

      ngOnDestroy() {
        // if (this.getItemSub) {
        //   this.getItemSub.unsubscribe()
        // }
      }

      getItems() {
        //  this.baseService.HOST + '/customer/' + IDcustomer + '/creditcard'
        this.spinner.open();
          this.activatedRoute.params.subscribe(params =>{
            this.IDcustomer = params["id"];
           
            this.items = [];
            this.webService.get(this.webService.HOST + '/creditcard/customer/' +  this.IDcustomer ).subscribe(params => {
                if(params.result.length > 0){
                  this.tarjetas = params['result'];
                  for(let i in this.tarjetas){
                    let tdc = this.tarjetas[i];
                    if(tdc.corerId != null ){
                      this.spinner.open()
                      // Puedes crear un objeto para mapear los números a nombres de meses
                      const mesesMap = {
                        "01": "Enero",
                        "02": "Febrero",
                        "03": "Marzo",
                        "04": "Abril",
                        "05": "Mayo",
                        "06": "Junio",
                        "07": "Julio",
                        "08": "Agosto",
                        "09": "Septiembre",
                        "10": "Octubre",
                        "11": "Noviembre",
                        "12": "Diciembre"
                      };

                      // Y luego en tu código, reemplazar la línea:
                      tdc.expMonth = mesesMap[tdc.expiration_month] || tdc.expiration_month;
                      tdc.nameHolderCreditCard = tdc.customer.name_customer + " " + tdc.customer.lastName_customer
                      //Se carga el nombre del proveedor TDC en la columna indicada
                      tdc.expMonthCreditCard = tdc.creditCardProvider.description;
                      //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
                      if(tdc.mask_card != null){
                        tdc.maskCreditCard = tdc.mask_card.substr(tdc.mask_card.length-8);
                      }else {
                        tdc.maskCreditCard = "XXXXXXXXXX";
                      }

                      //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
                      tdc.expYearCreditCard = "20" + tdc.expiration_year;
                      //Se busca el estatus de la TDC de manera de mostrarlo al cliente
                      this.webService.get(this.webService.HOST + "/customer/" +this.IDcustomer + "/customerHasCreditCard/" + tdc.corerId).subscribe(parms => {

                       if(parms['result']['statusCreditCard'] == 1){
                         tdc.statusInCorer = parms['result']['statusCreditCard'];
                         //Se anade la TDC a la tabla correspondiente
                         tdc.token = parms['result']['tokenCreditCard'];
                         this.items.push(tdc);
                         this.dataSource = new MatTableDataSource<any>(this.items);
                         this.spinner.close();
                       }else {
                         this.spinner.close();
                       }



                      }, (err) => {
                        this.spinner.close();
                        this.toast.showError(err)
                      } );
                    }
                  }
                  
                }else{
                  this.spinner.close();
                  console.log("NO TDC");
                  this.toast.showInfo("Este cliente NO posee tarjetas asociadas");
                }
             
            }, (err) => {
              this.spinner.close();
              console.log("ERRORRR");
              console.log(err)
              this.toast.showError(err); }); 
    
          });
  
  
      }

      openPopUp(data: any = {}, isNew? : any) {
        let title = isNew ? 'Agregar tarjeta' : 'Editar tarjeta';
        let dialogRef: MatDialogRef<any> = this.dialog.open(CreateCardPopupComponent, {
          width: '720px',
          disableClose: true,
          data: { title: title, payload: data }
        })
        dialogRef.afterClosed()
          .subscribe(res => {
            if(!res) {
              // If user press cancel
              return;
            }
            this.spinner.open();
            if (isNew) {
              this.res = res;
              this.add();
    
            } else {
        
              this.edit(res, data.id)
            
            }
          })
      }


      add(valorconf?: boolean){
      
        let obj = this.res;
        //Si no se ha dejado un campo vacio en el formulario para agregar TDCs
        if (obj.expMonthCreditCard != null && obj.expYearCreditCard != null &&
          obj.nameHolderCreditCard != null && obj.numberCreditCard != null) {
          //Se verifica el rango del mes y ano de vencimiento de la TDC
            if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(),obj.expYearCreditCard.toString())) {
  
              //Se verifica el numero de digitos de la TDC
              if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
  
                //Se verifica que el numero de la TDC cumpla con el algoritmo de Luhm
                if (this.valid_credit_card(obj.numberCreditCard.toString())) {
  
                  //En caso de que el numero de tarjeta (ultimos digitos) coincidan con una TDC previamente agregada, pedir confirmacion
                  if (!this.confirm) {
                    //Se busca en las tarjetas
                    for (let i in this.items) {
                      //Si una TDC coincide en sus ultimos digitos
                      if (this.items[i].maskCreditCard.substr(this.items[i].maskCreditCard.length-4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length-4)) {
                        //Solicitar confirmacion
                        this.spinner.close();
                        this.confirm = true;
                        return;
                      }
                    }
                    valorconf = true;
                  }
                  //Una vez confirmada (de ser necesaria una confirmacion, en caso contrario entra directamente)
                  if (valorconf) {
                    var creditcard : any = { "estatus":  "1", "numberCreditCard": obj.numberCreditCard.toString(), "nameHolderCreditCard" : obj.nameHolderCreditCard,"expMonthCreditCard": obj.expMonthCreditCard,"expYearCreditCard":  obj.expYearCreditCard};
                    this.webService.post(creditcard, this.webService.HOST + "/customer/" + this.IDcustomer + "/creditcard").subscribe(params => {
                      this.spinner.close();
                      if (params['message'] = "Successful created") {
                        this.toast.showSuccess("Tarjeta de crédito creada correctamente");
                        //Se reinician los componentes visuales de la pantalla
                        this.ngOnInit();
                      }
                      else {
                        this.toast.showError("No se pudo crear tarjeta");
                      }
                    }, err => {
                      this.spinner.close();
                      //En caso de error al no reconocer el proveedor de la TDC
                      if (err == "Credit card provider doesn't exist") {
                        this.toast.showError("Proveedor de la tarjeta de crédito desconocido");
                      }else{
                        this.toast.showError("No se pudo crear tarjeta");
                      }
                    });
                  }
                 
                  this.confirm = false;
                }
                else {
                  this.spinner.close();
                  this.toast.showError("El número no corresponde a una tarjeta de crédito");
                }
              }
              else {
                this.spinner.close();
                this.toast.showError("Verifique el número de la tarjeta. Cantidad de digitos inválida");
              }
            }
            else {
              this.spinner.close();
              this.toast.showError("Verifique el año/mes de vencimiento de la tarjeta introducida");
            }
        } else {
          this.spinner.close();
          this.toast.showError("Todos los campos de la tarjeta son requeridos");
        }
    }

      edit(res: any, idedit : number){
        //Busca dentro de las TDC del customer
        this.webService.get(this.webService.HOST +"/customer/"+ this.IDcustomer + "/creditcard").subscribe(params => {
          if(params['result'] != null){
            let lastarjetas = params['result'];
            //Por cada tarjeta
            for(let i in lastarjetas){
              let latdc : any = lastarjetas[i].creditCard;
              //La tarjeta encontrada es la que se va a editar
              if(latdc.id == idedit) {
                latdc.nameHolderCreditCard = res.nameHolderCreditCard;
                //Se verifica que el nombre en la TDC no se encuentre vacio
                if (res.nameHolderCreditCard.toString().trim().length > 1) {
                  //Se actualiza la informacion de la tarjeta

                  let tdc = {"id": latdc.id, "nameHolderCreditCard" : latdc.nameHolderCreditCard,
                  "expMonthCreditCard" : latdc.expMonthCreditCard, "expYearCreditCard" : latdc.expYearCreditCard}
                  this.webService.put(tdc, this.webService.HOST +"/customer/"+ this.IDcustomer + "/creditcard" ).subscribe(params => {
                    if (params['message'] == 'Successful updated') {
                      this.toast.showSuccess("Tarjeta de crédito actualizada correctamente");
                      //Se actualizan los componentes de la pantalla
                      this.spinner.close();
                      this.editar = false;
                      this.ngOnInit();
                    }
                    else {
                      this.toast.showError("No se pudo actualizar la tarjeta");
                    }
                  }, err => {
                    this.toast.showError(err)
                  });
                }else{
                  this.toast.showError("El nombre es requerido");
                }
              }
            }
          }
        });
      }

          //Metodo que permite verificar el mes y ano de la TDC introducida
    verifyCCYearAndMonth(month : string, year: string) : boolean{
      //Verifica que el mes se encuentre entre 01 y 12
      if(1 < Number.parseInt(month) && Number.parseInt(month) > 12){
        return false;
      }
  
      //Verifica el limite inferior del ano de vencimiento
      if((Number.parseInt(month)-1) < new Date().getMonth()){
        if(Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))){
          return false;
        }
      }
      else{
        if(Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))){
          return false;
        }
      }
  
      //Verifica el limite superior del ano de vencimiento
      if(Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2))+15)){
          return false;
      }

      return true;
    }
  
    //Metodo que permite verificar si la TDC introducida cumple con el algoritmo de Luhm (es una tarjeta valida)
    valid_credit_card(value : any) {
      // accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false;
  
      // The Luhn Algorithm. It's so pretty.
      var nCheck = 0, nDigit = 0, bEven = false;
      value = value.replace(/\D/g, "");
  
      for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10);
  
        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9;
        }
  
        nCheck += nDigit;
        bEven = !bEven;
      }
  
      return (nCheck % 10) == 0;
    }

    deleteItem(row : any) {
      this.confirmService.confirm({title: 'Confirmación', message: "¿Está seguro que desea eliminar la tarjeta seleccionada?"})
        .subscribe(res => {
          if (res) {
            this.deletes(row);
          }
        })
    }

    deletes(tdc : any){
      console.log(tdc);

      this.spinner.open();
      if (!tdc.corerId) {
        this.spinner.close();
        return this.toast.showInfo("No se puede cambiar el estatus de la tarjeta");
      }

      const action = tdc.statusInCorer === 1 ? 'delete' : 'activate';
      const url = `${this.webService.HOST}/customer/${this.IDcustomer}/creditcard/${tdc.corerId}/${action}`;
      const json = { token: tdc.token_creditCardToken };
      console.log(json);
      console.log(url);
      this.webService.post(json, url).subscribe(
          () => {
            this.spinner.close();
            this.toast.showSuccess("Actualizada correctamente");
            window.location.reload();
          },
          () => {
            this.spinner.close();
            this.toast.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
          }
      );
    }
  
    //Metodo que permite desactivar/reactivar una tarjeta para poder ser utilizada
    cambioestado(ids : number[]){
      for(let id of ids) {
        let mask="";
        for(let i in this.tarjetas){
          if(this.tarjetas[i].creditCard.id == id){
            mask = this.tarjetas[i].creditCard.maskCreditCard;
          }
        }

        this.webService.get(this.webService.HOST + "/customer/" + this.IDcustomer + "/customerHasCreditCard/" + id).subscribe(tdc => {
          //Se guarda la descripcion del estado en la columna correspondiente
          this.webService.post( { token : tdc.result.tokenCreditCard } ,this.webService.HOST + "/customer/" + this.IDcustomer + "/creditcard/" + tdc['result'].id + "/delete").subscribe(params => {
            this.toast.showSuccess("Tarjeta eliminada correctamente")
            this.ngOnInit();
        }, (err) => {
            this.toast.showError("No se pudo eliminar la tarjeta " + mask + ". Verifique que no se encuentre asociada a un plan activo");
        });
        }, (err) => this.toast.showError(err) );
      }
    }

    goBack(){
      this.router.navigate(["/reports"]); 
    }
    


}
