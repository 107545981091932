<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <button (click)="goBack()">Regresar</button>
               
                <!-- <img src="../../../assets/icons/cubo-green.png" alt="icono portales"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="create-portal">
            <div class="second-headboard">
                <h1>Personalización</h1>
                <!-- <img src="../../../assets/icons/2341.png" alt="logo de Billcentrix"> -->
                <div class="linked-icons" >
                    <button class="active" >Template</button>
                    <button   (click)="goToFeatures()">Personalización</button>
                    <button>Activar portal</button>
                    <!-- <img src="../../assets/icons/1_cuenta_off.png" alt="Paso 1: Cuenta" (click)="goBack()" >
                    <img src="../../assets/icons/2_portales_on.png" alt="Paso 2: Portal" >
                    <img src="../../assets/icons/3_personalizar_off.png" alt="Paso 3: Personalizar" (click)="goToFeatures()">
                    <img src="../../assets/icons/4_planes_off.png" alt="Paso 4: Planes" (click)="goToPlans()"> -->
                </div>
            </div>
            <div class="portal-info">
                <h1>Selecciona un tema para tu portal de pagos</h1>
                <p>Puedes volver y variar los colores cuando quieras</p>
                <div class="grid">
                    <div class="essential template-item" [class.selected]="checked === 3" (click)="selectTemplate(3)">
                        <h1>1. Básico</h1>
                        <p>Este tema incluido en tu plan, es moderno, de código libre, incluye librería de iconos y
                            estilos. </p>
                        <img src="../../../assets/images/portal-basico.png" alt="vista previa del portal basico">
                        <div class="preview">
                            <div class="preview-text">
                                <a  [routerLink]="'/basic'" target="_blank"><img src="../../../assets/icons/ver.png" alt="vista previa"></a>
                                <!-- <p class="text-right">Vista previa</p> -->
                            </div>
                            <div class="round-checkbox">
                                <input type="checkbox" id="checkboxBasic"  name="checkboxBasic"
                                (click)="selectTemplate($event)" [checked]="checkboxBasicValue" [value]="3"
                                [(ngModel)]="checkboxBasicValue" (change)="handleCheckboxChange('basic')">
                            </div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="practical template-item" [class.selected]="checked === 2" (click)="selectTemplate(2)" >
                            <h1>2. Práctico</h1>
                            <p>Este tema también incluido en tu plan, es de código libre, con librería de iconos, y de
                                estilo básico.</p>
                            
                            <img src="../../../assets/images/portal-practico.png" alt="vista previa del portal practico">
                            <div class="preview">
                                <div class="preview-text">
                                   <a [routerLink]="'/practic'" target="_blank"> <img  src="../../../assets/icons/ver.png" alt="vista previa"></a>
                                    <!-- <p class="text-right">Vista previa</p> -->
                                </div>
                                <div class="round-checkbox">
                                    <input type="checkbox" id="checkboxPractical" name="checkboxPractical" 
                                    (click)="selectTemplate($event)"  [checked]="checkboxPracticalValue"  [value]="2"
                                    [(ngModel)]="checkboxPracticalValue" (change)="handleCheckboxChange('practical')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contemporary template-item" [class.selected]="checked === 1" (click)="selectTemplate(1)">
                        <h1>3. Contempo</h1>
                        <p>Este tema incluido en tu plan, y de código libre, incluye librería de iconos y se distingue
                            por su simpleza.</p>
                        <img src="../../../assets/images/portal-contempo.png" alt="vista previa del portal contempo">
                        <div class="preview">
                            <div class="preview-text">
                               <a [routerLink]="'/contempo'" target="_blank" > <img src="../../../assets/icons/ver.png" alt="vista previa"></a>
                                <!-- <p class="text-right">Vista previa</p> -->
                            </div>
                            <div class="round-checkbox">
                                <input type="checkbox" id="checkboxContempo" name="checkboxContempo" 
                                (click)="selectTemplate($event)"  [checked]="checkboxContempoValue" [value]="1"
                                [(ngModel)]="checkboxContempoValue" (change)="handleCheckboxChange('contempo')">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="options-buttons">
            <button (click)="goBack()" class="back-btn">Atrás</button>
            <button class="continue-btn" (click)="saveTemplate()"*ngIf="exist" >Guardar y continuar</button>
            <button class="continue-btn" (click)="saveTemplate()"  *ngIf="!exist">Continuar</button>
        </div>
    </div>
</body>