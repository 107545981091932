<div class="column">
            <div class="logo-bank">
                <img src="/assets/images/visa.png" alt="">

            </div>
            <div class="credit_data">
                <h4 class="tx_tipo">Visa</h4>
                <p class="tc_number">Bank </p>
                <span class="verified">Activa</span>
            </div>
            <div ><button class="disable" type="submit">Desactivar</button></div>
</div>
