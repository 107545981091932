import { Injectable } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(/*private jwtAuth: JwtAuthService*/) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    var changedReq;
    // BUSCAMOS EL TOKEN DE ACCESO DEL USUARIO
    if(localStorage.getItem('sesion') != null){
      let sesion = JSON.parse(localStorage.getItem('sesion')!);
      var token = sesion.access_token;
     
      var user = Number(sesion.user_id) ;

      changedReq = req.clone({
        headers: new HttpHeaders({
          'Authorization': token,
          'User': user.toString(),
        })
      });

    }else{
      changedReq = req;
    }
   
    return next.handle(changedReq);
  }
}
