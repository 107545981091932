import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']

})
export class WelcomeComponent {
  showWelcomeComponent: boolean = true;
  showCreateComponent: boolean = true;

  constructor(private router : Router){}
  ngOnInit(): void {
    // Envía un mensaje al parent cuando se carga el componente
    if (window.parent !== window) {  // Verifica si el componente está en un iframe
      window.parent.postMessage({ status: 'loaded', url: window.location.href }, '*');
    }
  }
  onLoginClick() {
    this.router.navigate(["/login"])
    
  }
  goHome() {
    this.router.navigate(['/']);
  }
  onSignUp(){
    this.router.navigate(["/create"]); 
  }

 
  
}