import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  public portals = new EventEmitter<any[]>();

  constructor() { }
}
