<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button id="createQuestionBtn">Cree una pregunta</button>
                <button class="create-question-btn"><h1>+</h1></button>
                <img src="../../assets/icons/informacion_aqua.png" alt="icono informacion">
                <app-menu></app-menu>
            </div>
        </div>
        <div class="question-content">
            <h1 class="main-title">Preguntas frecuentes</h1>
            <div class="faqs">
                <div class="header">
                    <div class="title">
                        <img src="../../assets/icons/informacion2.png" alt="icono de informacion">
                        <h1><strong>FAQs</strong> ---- Más información sobre los templates</h1>
                    </div>
                    <img src="../../assets/icons/cerrar.png" (click)="onCloseModal()" alt="cerrar el modal"
                        class="close">
                </div>
                <div class="question-grid">
                    <div class="left">
                        <div class="header-description">
                            <p>Banner principal</p>
                            <p>Idea principal</p>
                            <p>llamada a la acción</p>
                        </div>
                        <div class="plans-description">
                            <p>Nombre del portal</p>
                            <p>Nombres de los planes</p>
                            <p>Imagen del plan</p>
                            <p>Distintivo</p>
                            <p>Precio</p>
                            <p>Frecuencia</p>
                            <p>Descripción del plan</p>
                            <p>CTA selector</p>
                        </div>
                        <div class="banner-description">
                            <p>Banner promocional</p>
                            <p>Titular de la promoción</p>
                            <p>Llamada a la acción</p>
                        </div>
                        <p>Contacto directo</p>
                    </div>
                    <div class="right">
                        <div class="sky-blue">
                            <div class="line-horizontal"></div>
                            <span class="line-text-horizontal"><p>1440 pixels de ancho</p></span>
                            <div class="line-vertical"></div>
                            <span class="line-text-vertical"><p>720 pixels de alto</p></span>
                            <div class="text">
                                <h1>Esta unidad es el gráfico que representa su negocio, la idea principal que quiere
                                    comunicar.</h1>
                                <p>Llamado a actuar, a hacer clic</p>
                            </div>
                        </div>
                        <div class="plans-section">
                            <div class="text">
                                <p>Precios</p>
                                <h1>Ej. Club, subscripción, servicio, o recurrencia</h1>
                            </div>
                            <div class="plan-grid">
                                <div class="plan">
                                    <div class="preview">
                                        <div class="header">
                                            <h1>Plan 1</h1>
                                        </div>
                                        <div class="see-photo"></div>
                                        <h3>Club VIP</h3>
                                        <h1 class="big-number">$0</h1>
                                        <h3>Mensuales</h3>
                                        <div class="including">
                                            <ul class="dotted-list">
                                                <li>Incluye sed ut perspiciatis</li>
                                                <li>Sunde omnis iste natus</li>
                                            </ul>
                                        </div>
                                        <div class="select">
                                            <button>Seleccionar</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="plan-info">
                                    <h1>Información de los planes, detalles de beneficios, precio y frecuencia.</h1>
                                    <p>Propuesta de valor. Un plan, dos planes, tres, o más, esto es lo que estás
                                        vendiendo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="banner-section">
                            <div class="banner-grid">
                                <div class="left">
                                    <h1>
                                        Texto que promueve su producto, o servicio
                                    </h1>
                                    <p>Subtitulo</p>
                                </div>
                                <div class="right">
                                    <p>Imagen representativa  para la promoción</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="mail">
                            <img src="../../../assets/icons/mail.png" alt="icono de mail">
                            <h2>Forma@<strong>decontacto.com</strong></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="options-buttons">
            <button class="back-btn">Atrás</button>
            <button class="continue-btn">Continuar</button>
        </div>
        <div class="contact-us">
            <p>¿No consigues lo que buscas?</p>
            <button>Contáctenos</button>
        </div>
    </div>
</body>