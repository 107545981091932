
import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.css']
})
export class PreviewPopupComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PreviewPopupComponent>,) { }

  ngOnInit(): void {
  }

  getUrlTemplatePreview(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

}
