import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit {
  selectedPalette: string = 'Billcentrix';

  constructor() { }

  ngOnInit(): void {
    // Aquí se puede inicializar el componente
  }

  // Cualquier otro método que necesites
}