import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-loginpreview',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginpreviewComponent {
  themeColors: any; 

  form : any;
  fromCheckout : Boolean = false;
  isLoginFromCheckout : Boolean = false;
  isPreview : Boolean = false;
  param : any;

  constructor(private router: Router,private activeRoute : ActivatedRoute) {

  }

  goToMainPage() {
    this.router.navigate(['/basic']);
  }



}
 