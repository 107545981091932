<body>
    <div class="main">
        <div class="grid-overlay">
            <div class="nav">
                <a href="/">regresar</a> 
            </div>
            <div class="logoblock"><img (click)="goHome()" src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
                <h2>Crear Cuenta</h2>
            </div>
            
            
                <div class="background-white">
                     <h1>Crear Cuenta</h1>
                     <form class="user" [formGroup]="signUpForm">
                        <input type="email" placeholder="Correo" formControlName="email" id="exampleInputEmail">
                        <small 
    *ngIf="signUpForm.get('email')?.hasError('required') && signUpForm.get('email')?.touched" 
    class="form-error-msg">Correo es requerido</small>
<small 
    *ngIf="signUpForm.get('email')?.hasError('email') && signUpForm.get('email')?.touched" 
    class="form-error-msg">Correo inválido. El formato debe ser example@dot.com</small>
                        <div class="options">
                            <!-- Aquí deshabilitamos el botón si el formulario es inválido -->
                            <button class="crear" type="button" (click)="singUp()">Crear</button>

                        </div>
                    </form>
                    <div class="logear">¿Ya tienes cuenta?<button (click)="onLoginClick()">Entrar</button></div>
                        
                </div>              
        </div>
    
    </div>
            <app-footer></app-footer>





