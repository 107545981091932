<div class="color-palette">
    <h1>Selecciona la paleta de colores que mejor se adapte a tu marca</h1>
    <p>Puedes regresar y cambiar los colores cuando quieras.</p>
    <div class="color-palette-content">
        <div class="carousel-nav-left" (click)="previous()" [ngClass]="{ 'disabled': currentIndex === 0 }">
            <div class="arrow-left"></div>
        </div>
        <div class="carousel-container">
            <ng-container *ngFor="let palette of visiblePalettes; let i = index">
              <div class="palette" [class.active]="palette === selectedPalette" (click)="selectPalette(palette)">
                <img [src]="palette.image" [alt]="palette.title">
                <h1>{{ palette.title }}</h1>
                <div class="preview">
                  <div class="preview-text">
                    <!-- <img src="../../../assets/icons/ver.png" alt="preview">
                    <p class="text-right">Vista previa</p> -->
                  </div>
                  <div  class="round-checkbox">
                    <input type="checkbox" [name]="'paletteCheckbox'" id="{{ 'checkbox-' + i }}" [checked]="palette === selectedPalette" >
                  </div>                  
                </div>
              </div>
            </ng-container>
          </div>
        <div class="carousel-nav-right" [ngClass]="{ 'disabled': currentIndex === palettes.length - 1 }"
            (click)="next()">
            <div class="arrow-right"></div>
        </div>
    </div>
</div>