import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  palettes: any[] = [
    { image: '../../../assets/images/color-palette/billcentrix.png', title: 'Billcentrix', selected: true },
    { image: '../../../assets/images/color-palette/moderna.png', title: 'Moderna' },
    { image: '../../../assets/images/color-palette/violeta.png', title: 'Violeta' }
    // { image: '../../../assets/images/color-palette/paleta4.png', title: 'Multicolor' }
    // { image: '../../../assets/images/color-palette/paleta5.png', title: 'Paleta 2' },
    // { image: '../../../assets/images/color-palette/paleta6.png', title: 'Paleta 3' },
    // { image: '../../../assets/images/color-palette/paleta7.png', title: 'Paleta 4' },
    // { image: '../../../assets/images/color-palette/paleta8.png', title: 'Paleta 5' },
    // { image: '../../../assets/images/color-palette/paleta9.png', title: 'Paleta 6' }
  ];
  currentIndex: number = 0;
  visiblePalettes: any[] = [];
  selectedPalette: string = '';

  @Output() paletteSelected = new EventEmitter<any>();
  

  ngOnInit() {
    this.selectedPalette = this.palettes[0];
    this.updateVisiblePalettes();
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateVisiblePalettes();
    }
  }
  
  next() {
    if (this.currentIndex < this.palettes.length - 1) {
      this.currentIndex++;
      this.updateVisiblePalettes();
    }
  }
  
  updateVisiblePalettes() {
    const start = this.currentIndex;
    const end = start + 3;
    this.visiblePalettes = this.palettes.slice(start, end);
  }

  selectPalette(palette: any): void {
    
    this.selectedPalette = palette;
    // this.palettes.forEach(p => {
    //   if (p !== palette) {
    //     p.selected = false;
    //   }
    // });

    this.paletteSelected.emit(palette.title);
  }  

}
