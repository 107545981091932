import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TableService} from "./pager";
import {ActivatedRoute, Router} from "@angular/router";
import {WebService} from "../services/web/web.service";
import {SpinnerService} from "../services/spinner/spinner.service";
import {ToastService} from "../services/notification/toast.service";
import {MatTableDataSource} from "@angular/material/table";
import {HistoricFilter} from "./historic.filter";

@Component({
    selector: 'app-historic-report',
    templateUrl: './historic-report.component.html',
    styleUrls: ['./historic-report.component.scss']
})
export class HistoricReportComponent implements OnInit {
// PARA MANEJO DE PANEL DE BUSQUEDA
    isOpenSearchPanel = false;
    filter = new HistoricFilter(this.tableService.filter);

    services: any[] = [];

    datesFrom : any[] = [{"description":"ultimos 7 dias", "value" : 1}, {"description":"ultimos 30 dias", "value" : 2} , {"description":"Mes actual", "value" : 3} , {"description":"Año actual", "value" : 4}, {"description":"Rango personalizado", "value" : 5}];
    invoiceStatus : any[] = [];


    // PARA MANEJO DE TABLAS
    @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
    @ViewChild(MatSort) sort: MatSort | undefined;
    displayedColumns: string[] = ['service', 'plan', 'nameClient', 'emailClient', 'transaction', 'date', 'status', 'amount', 'lastDigits', 'motive'];
    dataSource: any;

    constructor(
        public tableService: TableService<any>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef, private webService: WebService, private spinner: SpinnerService, private toast: ToastService) {
        this.tableService = new TableService;
    }


    ngOnInit(): void {
        this.isOpenSearchPanel = true;
        // console.log(JSON.parse(localStorage.getItem('sesion') || '{}'));
        this.loadStatuses();
        this.list();
    }

    list(event?: PageEvent) {

        this.spinner.open();
        if(this.filter.name_customer == undefined && this.filter.email_customer == undefined && this.filter.dateSearchType == undefined && this.filter.id == undefined && this.filter.minimum == undefined && this.filter.maximum == undefined && this.filter.status == undefined) {
            this.spinner.close();
            this.toast.showInfo("Por favor seleccione un criterio de busqueda");
            return ;
        }
        const formatDate = (date: Date): string => {
            return date.toISOString().split('T')[0];
        };

        if(this.filter.dateSearchType != 5 ){ // EN CASO QUE SEA 5 YA LA FECHA VIENE EN RANGO
            const today = new Date();
            // Format date as string in YYYY-MM-DD format
            this.filter.dateUntil = formatDate(today);
            if(this.filter.dateSearchType == 1){
                // Last 7 days
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(today.getDate() - 7);
                this.filter.dateFrom = formatDate(sevenDaysAgo);
            } else if(this.filter.dateSearchType == 2) {
                // Last 30 days
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(today.getDate() - 30);
                this.filter.dateFrom = formatDate(thirtyDaysAgo);
            } else if(this.filter.dateSearchType == 3) {
                // Current month (from day 1)
                const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                this.filter.dateFrom = formatDate(firstDayOfCurrentMonth);
            } else if(this.filter.dateSearchType == 4) {
                // Current year (from January 1st)
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                this.filter.dateFrom = formatDate(firstDayOfYear);
            }
        } else if(this.filter.dateSearchType == 5) {
            // Cuando dateSearchType es 5, validar que dateFrom y dateUntil tengan valores
            if (!this.filter.dateFrom || this.filter.dateFrom === '' || !this.filter.dateUntil || this.filter.dateUntil === '') {
                // Mostrar mensaje de error
                this.toast.showError("Debe seleccionar un rango de fechas valido");
                this.spinner.close();
                return; // Detener la ejecuciￃﾳn si las fechas no son vￃﾡlidas
            }
            // Validar que dateUntil sea mayor o igual que dateFrom
            const fromDate = new Date(this.filter.dateFrom.toString());
            const untilDate = new Date(this.filter.dateUntil.toString());

            if (fromDate > untilDate) {
                this.toast.showError("La fecha final debe ser mayor o igual que la fecha inicial");
                this.spinner.close();
                return; // Detener la ejecuciￃﾳn si las fechas no son vￃﾡlidas
            }

            // Formatear las fechas a YYYY-MM-DD
            this.filter.dateFrom = fromDate.getFullYear() + '-' +
                String(fromDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(fromDate.getDate()).padStart(2, '0');

            this.filter.dateUntil = untilDate.getFullYear() + '-' +
                String(untilDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(untilDate.getDate()).padStart(2, '0');

        }

        let httpParams = this.webService.buildRequestParams(this.tableService.sort, "m",
            {
                pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex,
                pageSize: event ? event.pageSize : this.tableService.pager.pageSize
            })

        httpParams = this.filter.getHttpParams(httpParams);
        // 118  JSON.parse(localStorage.getItem('sesion') || '{}').partner
        if (JSON.parse(localStorage.getItem('sesion') || '{}').partner == undefined) {
            this.spinner.close();
            return this.toast.showInfo("NO se puede cargar reporte de clientes porque NO posees datos de tu compañía, por favor crea una suscripcion para poder cargar tus datos en nuestro sistema.")
        }

        this.webService.get(this.webService.HOST + "/partner/" + JSON.parse(localStorage.getItem('sesion') || '{}').partner + "/invoices/list", httpParams).subscribe(response => {
            if(response['result'].length == 0){
                this.toast.showWarning("No existen resultados para mostrar")
            }

            // Procesar cada registro para establecer el valor por defecto en el campo motive cuando es null
            if (response['result'] && response['result'].length > 0) {
                response['result'].forEach(record => {
                    if (record.motive === null || record.motive === undefined) {
                        record.motive = "No aplicaba envío a banco";
                    }
                });
            }

            this.dataSource = new MatTableDataSource<any>(response['result']);
            this.tableService.pager = response['pager'];
            this.tableService.selection.clear();
            this.spinner.close();
        }, (err) => {
            this.spinner.close();
            this.toast.showError(err);
        });

    }


    search() {
        this.tableService.filter = new HistoricFilter(this.filter);
        this.router.navigate([], {
            queryParams: {
                name_customer: this.filter.name_customer
            }
        });
        this.list();
    }

    download(){
        this.spinner.open();
        if(this.filter.name_customer == undefined && this.filter.email_customer == undefined && this.filter.dateSearchType == undefined && this.filter.id == undefined && this.filter.minimum == undefined && this.filter.maximum == undefined && this.filter.status == undefined) {
            this.spinner.close();
            this.toast.showInfo("Por favor seleccione un criterio de busqueda");
            return ;
        }
        const formatDate = (date: Date): string => {
            return date.toISOString().split('T')[0];
        };

        if(this.filter.dateSearchType != 5 ){ // EN CASO QUE SEA 5 YA LA FECHA VIENE EN RANGO
            const today = new Date();
            // Format date as string in YYYY-MM-DD format
            this.filter.dateUntil = formatDate(today);
            if(this.filter.dateSearchType == 1){
                // Last 7 days
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(today.getDate() - 7);
                this.filter.dateFrom = formatDate(sevenDaysAgo);
            } else if(this.filter.dateSearchType == 2) {
                // Last 30 days
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(today.getDate() - 30);
                this.filter.dateFrom = formatDate(thirtyDaysAgo);
            } else if(this.filter.dateSearchType == 3) {
                // Current month (from day 1)
                const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                this.filter.dateFrom = formatDate(firstDayOfCurrentMonth);
            } else if(this.filter.dateSearchType == 4) {
                // Current year (from January 1st)
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                this.filter.dateFrom = formatDate(firstDayOfYear);
            }
        } else if(this.filter.dateSearchType == 5) {
            // Cuando dateSearchType es 5, validar que dateFrom y dateUntil tengan valores
            if (!this.filter.dateFrom || this.filter.dateFrom === '' || !this.filter.dateUntil || this.filter.dateUntil === '') {
                // Mostrar mensaje de error
                this.toast.showError("Debe seleccionar un rango de fechas valido");
                this.spinner.close();
                return; // Detener la ejecuciￃﾳn si las fechas no son vￃﾡlidas
            }
            // Validar que dateUntil sea mayor o igual que dateFrom
            const fromDate = new Date(this.filter.dateFrom.toString());
            const untilDate = new Date(this.filter.dateUntil.toString());

            if (fromDate > untilDate) {
                this.toast.showError("La fecha final debe ser mayor o igual que la fecha inicial");
                this.spinner.close();
                return; // Detener la ejecuciￃﾳn si las fechas no son vￃﾡlidas
            }

            // Formatear las fechas a YYYY-MM-DD
            this.filter.dateFrom = fromDate.getFullYear() + '-' +
                String(fromDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(fromDate.getDate()).padStart(2, '0');

            this.filter.dateUntil = untilDate.getFullYear() + '-' +
                String(untilDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(untilDate.getDate()).padStart(2, '0');

        }

        let httpParams = this.webService.buildRequestParams(this.tableService.sort, "m",
            {
                pageIndex: -1,
                pageSize: -1
            })

        httpParams = this.filter.getHttpParams(httpParams);
        // 118  JSON.parse(localStorage.getItem('sesion') || '{}').partner
        if (JSON.parse(localStorage.getItem('sesion') || '{}').partner == undefined) {
            this.spinner.close();
            return this.toast.showInfo("NO se puede cargar reporte de clientes porque NO posees datos de tu compañía, por favor crea una suscripcion para poder cargar tus datos en nuestro sistema.")
        }

        this.webService.get(this.webService.HOST + "/partner/" + JSON.parse(localStorage.getItem('sesion') || '{}').partner + "/invoices/list", httpParams).subscribe(response => {
            if(response['result'].length == 0){
                this.toast.showWarning("No existen resultados para descargar");
                this.spinner.close();
                return;
            }
            // Convertir el resultado a CSV y descargarlo
            this.convertToCSVAndDownload(response['result']);
            this.spinner.close();
        }, (err) => {
            this.spinner.close();
            this.toast.showError(err);
        });

    }

    /**
     * Convierte un array de objetos a formato CSV y lo descarga
     * @param data Array de objetos a convertir
     */
    private convertToCSVAndDownload(data: any[]): void {
        // Verificar si hay datos
        if (!data || !data.length) {
            this.toast.showWarning("No hay datos para exportar");
            return;
        }

        try {
            // Obtener las cabeceras (nombres de las propiedades)
            const headers = Object.keys(data[0]);

            // Mapeo de nombres de cabeceras originales a nombres amigables en espaￃﾱol
            const headerMapping = {
                'motive': 'Respuesta del banco',
                'service': 'Servicio',
                'plan': 'Plan',
                'nameClient': 'Cliente',
                'emailClient': 'Email',
                'transaction': 'ID',
                'date': 'Fecha',
                'status': 'Estatus',
                'amount': 'Monto',
                'lastDigits': 'Últimos 4 Dígitos'
                // Agregar mￃﾡs mapeos segￃﾺn sea necesario
            };

            // Crear la lￃﾭnea de cabeceras con los nombres amigables
            const friendlyHeaders = headers.map(header =>
                headerMapping[header] || header // Usar el nombre amigable si existe, o el original si no
            );
            let csvContent = friendlyHeaders.join(',') + '\n';

            // Agregar los datos
            data.forEach(item => {
                const row = headers.map(header => {
                    // Caso especial para el campo "motive" cuando es null
                    if (header === 'motive' && (item[header] === null || item[header] === undefined)) {
                        return '"No aplicaba envío a banco"';
                    }

                    // Manejar valores nulos o indefinidos
                    const cell = item[header] === null || item[header] === undefined ? '' : item[header];

                    // Escapar comillas y encerrar en comillas si contiene comas o comillas
                    const cellStr = String(cell);
                    if (cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')) {
                        return `"${cellStr.replace(/"/g, '""')}"`;
                    }
                    return cellStr;
                });
                csvContent += row.join(',') + '\n';
            });

            // Crear un blob con el contenido CSV
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Crear URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Crear un elemento <a> para descargar
            const link = document.createElement('a');
            link.setAttribute('href', url);

            // Generar nombre del archivo con fecha actual
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            link.setAttribute('download', `reporte_historico_${formattedDate}.csv`);

            // Ocultar el enlace
            link.style.visibility = 'hidden';

            // Agregar a la pￃﾡgina, hacer clic y eliminar
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.toast.showSuccess("Reporte descargado exitosamente");
        } catch (error) {
            console.error('Error al generar CSV:', error);
            this.toast.showError("Error al generar el archivo CSV");
        }
    }


    openSearchPanel(value: boolean) {
        this.isOpenSearchPanel = value;
        this.filter = new HistoricFilter(this.filter);
    }

    reset() {
        this.filter = new HistoricFilter();
        this.tableService.filter = null;
        this.dataSource = [];
        delete this.filter.name_customer;
        // this.isOpenSearchPanel = false;
        this.search();
    }

    goBack() {
        this.router.navigate(["/my-portals"]);
    }

    private loadStatuses() {
        this.spinner.open();
        this.webService.get(`${this.webService.HOST}/statuses`)
            .subscribe(
                (response: any) => {
                    this.invoiceStatus = response.result;
                    // Sort services alphabetically by name_service
                    this.invoiceStatus.sort((a: any, b: any) => {
                        if (a.description < b.description) {
                            return -1;
                        }
                        if (a.description > b.description) {
                            return 1;
                        }
                        return 0;
                    });
                    this.spinner.close();
                },
                (error) => {
                    this.spinner.close();
                    this.toast.showError(error);
                }
            );
    }
}
