<body>
    <nav>
        <div class="nav-left"> 
            <a [routerLink]="['/']">
                <img src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
            </a>
        </div>
        
        <div class="nav-right">
            <a href="#" id="login-link">
            <p>Home</p>
        </a>
        <!-- <img src="../../../assets/icons/menu-hamb.png" alt="menu hamburguesa"> -->
        </div>
    </nav>
    <div class="welcome-page">
        <div class="login_home" >
            <div class="welcome-text">
                
            </div>
            <div class="options-buttons">
                
                <div class="register">

                    <div class="main">
    <div class="login-modal-content">
        <div class="modal-login">
            <div class="background-white">
             <h1>Página no autorizada</h1>
                   <div>
                    <h4>Por favor, inicia sesión para continuar...</h4>
                    </div>
                   <div class="options">
                     <button type="btn" (click)="goBack()" >Login</button>
                  </div>
     
            </div>
            </div>
        
    </div>
   
</div>
                </div>
            </div>
       </div>
       

    </div>
    <app-footer></app-footer>
</body>






