<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <button  (click)="goCreatePlan()">Crear nuevo plan</button>
                <!-- <button class="create-plan-btn"><h1>+</h1></button> -->
                <!-- <img src="../../../../assets/icons/planes.png" alt="icono planes"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="my-plans-data">
            <div class="second-headboard">
                <h1>Mis planes</h1>
                <!-- <img src="../../../../assets/icons/2341.png" alt="logo de pasos"> -->
                <div class="linked-icons" >
                    <button (click)="goBack()" >Registro inicial</button>
                    <button class="active"  >Planes</button>
                    <button *ngIf="!showPaymentButton" (click)="goToPortals()">Suscripción activa</button>
                    <button  *ngIf="showPaymentButton" (click)="processPayment()">Activar suscripción</button></div>
                    
                    <!-- <img src="../../assets/icons/1_cuenta_off.png" alt="Paso 1: Cuenta" (click)="goToEdit()">
                    <img src="../../assets/icons/2_portales_off.png" alt="Paso 2: Portal" (click)="goToTemplates()">
                    <img src="../../assets/icons/3_personalizar_off.png" alt="Paso 3: Personalizar" (click)="goToFeatures()">
                    <img src="../../assets/icons/4_planes_on.png" alt="Paso 4: Planes" > 
                    </div> -->
            </div>
            <div class="grid">
                <app-plan *ngFor=" let plan of myplans" [plan]="plan" ></app-plan>
               
            </div>
        </div>
        <div class="options-buttons">
            <button class="back-btn" (click)="goToPortals()">Mis suscripciones</button>
            <!-- <button  class="continue-btn" (click)="processPayment()" >Continuar</button> -->
            <div *ngIf="urlCliente"> 
  <a href="{{urlCliente}}" target="_blank">
    <button class="activar-btn" *ngIf="isActive">Ver portal</button>
  </a>
</div>
            <button *ngIf="showPaymentButton" class="activar-btn" (click)="processPayment()" >Activar suscripción</button>
            
       </div>
    </div>
</body>
