import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-creditcard-info',
  templateUrl: './creditcard-info.component.html',
  styleUrls: ['../credit-card.component.scss']
})
export class CreditcardInfoComponent {


  constructor() { }

  ngOnInit(): void {
  }

}
