import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['../direcciones.component.scss']
})
export class AddressComponent{

  constructor() { }

}
