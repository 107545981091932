<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button (click)="goBack()">Regresar</button>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="setting-data">
            <div class="second-headboard">
                <h1>Actualizar información del Cliente</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
               
            </div>
            <div class="general-data">
                <h1>Datos generales</h1>
                <form [formGroup]="form" >
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <label for="name">Nombre*</label>
                            <input [formControl]="form.controls['name_customer']" 
                                    type="text">
                                    <small
                                    *ngIf="form.controls['name_customer'].hasError('required') &&
                                    form.controls['name_customer'].touched"
                                    class="form-error-msg"> Nombre es querido </small>
                        </div>

                        <div class="items">
                            <label for="code">Código*</label>
                            <input readonly [formControl]="form.controls['code_customer']" type="text">
                            <small *ngIf="form.controls['code_customer'].hasError('required') && form.controls['code_customer'].touched"
                                    class="form-error-msg"> Código es querido
                            </small>
                        </div>

                        <div class="items">
                            <label for="email">Email*</label>
                            <input readonly [formControl]="form.controls['email_customer']" type="text">
                            <small *ngIf="form.controls['email_customer'].hasError('required') && form.controls['email_customer'].touched"
                                    class="form-error-msg"> Email es querido
                            </small>
                        </div>

                    </div>
                    <div class="right">
                        <div class="items">
                            <label for="address">Apellido*</label>
                            <input  [formControl]="form.controls['lastName_customer']"  type="text" >
                            <small *ngIf="form.controls['lastName_customer'].hasError('required') && form.controls['lastName_customer'].touched"
                                   class="form-error-msg"> Apellido es requerido </small>
                        </div>
                        <div class="items">
                            <label for="address">DNI*</label>
                            <input  [formControl]="form.controls['identifier_customer']"  type="text" >
                            <small *ngIf="form.controls['identifier_customer'].hasError('required') && form.controls['identifier_customer'].touched"
                                   class="form-error-msg"> DNI es requerido </small>
                        </div>
                    
                    </div>
                </div>
                </form>
            </div>
            <div style="padding-top: 2em;">
                <h1>Direcciones</h1>
                <mat-table [dataSource]="dataSource" >

                    <ng-container matColumnDef="suscription">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Suscripción </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="id"> {{row.plan.name_plan}} </mat-cell>
                      </ng-container>
        
                
                    <ng-container matColumnDef="adress">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="adress"> {{row?.address.adress}} </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="corregimiento">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Corregimiento </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="corregimiento"> {{row.address.corregimiento.name}} </mat-cell>
                      </ng-container>
        
                        
                    <ng-container matColumnDef="province">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Provincia </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="province"> {{row.address.province.name}} </mat-cell>
                      </ng-container>
        
                   
                
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="estatus"> 
                            <button mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                <mat-icon class="text-muted">more_vert</mat-icon>
                              </button>
                            <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                <button mat-menu-item (click)="updateAddress(row)" class="menu_report"><img src="../assets/icons/update.svg" > Actualizar </button>
                            </mat-menu>
                        </mat-cell>
                      </ng-container>
        
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"  >
                    </mat-row>
                </mat-table>
                <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        >
                </mat-paginator>  
            </div>
        </div>

        
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <button   class="continue-btn" (click)="update()" >Guardar información</button>
        </div>
    </div>
</body>
