import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobile = false;
  isMenuOpen = false;
  @Input() navigation = true;
  isPreview : Boolean = false;
  param : any;

  constructor(private router: Router,private activateRoute : ActivatedRoute,private breakpointObserver: BreakpointObserver) {
  
    this.activateRoute.params.subscribe( params =>{
      // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
   });

  }
  getImageLogo() {
    const imageName = 'logo.png';
    const imageUrl = `../assets/images/${imageName}`;
    return imageUrl;
  }
  
  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait, // Puedes ajustar las condiciones según tus necesidades
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  goLogin(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  goDashboard(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

}
