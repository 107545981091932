<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button id="createQuestionBtn">Cree una pregunta</button>
                <button class="create-question-btn"><h1>+</h1></button>
                <img src="../../assets/icons/informacion_aqua.png" alt="icono informacion">
                <app-menu></app-menu>
            </div>
        </div>
        <div class="question-content">
            <h1 class="main-title">Preguntas frecuentes</h1>
            <div class="faqs">
                <div class="header">
                    <div class="title">
                        <img src="../../assets/icons/informacion2.png" alt="icono de informacion">
                        <h1><strong>FAQs</strong> ---- Registro y portales de afiliación</h1>
                    </div>
                    <!--
                    <img src="../../assets/icons/cerrar.png" (click)="onCloseModal()" alt="cerrar el modal"
                        class="close">-->
                </div>
                <div class="question-grid">
                    <div class="left">
                        <div class="item-question">
                            <h4>¿Puedo registrar varios usuarios?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                        <div class="item-question">
                            <h4>¿Hay un máximo de portales?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                        <div class="item-question">
                            <h4>¿Qué es un plan de afiliación?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="item-question">
                            <h4>¿Puedo registrar varios usuarios?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                        <div class="item-question">
                            <h4>¿Hay un máximo de portales?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                        <div class="item-question">
                            <h4>¿Qué es un plan de afiliación?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremnxvmque
                                laudantium, totam. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam.</p>
                        </div>
                    </div>
                </div>
                <div class="more-info">
                    <h1>Acerca de los templates</h1>
                    <div class="content-info">
                        <img src="../../assets/icons/big-info.png" alt="icono de informacion blanco">
                        <div class="text">
                            <h1>más información</h1>
                            <p>Aprende sobre los detalles en los templates</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-option">
            <p>¿Don't fin what you're looking for?</p>
            <button>Contact us</button>
        </div>
    </div>
</body>