import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-card-popup',
  templateUrl: './create-card-popup.component.html',
  styleUrls: ['./create-card-popup.component.scss']
})
export class CreateCardPopupComponent  implements OnInit{

  public itemForm: FormGroup ;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateCardPopupComponent>,
    private fb: FormBuilder,
  ) { 
    if(this.data.payload.id == undefined ){ // Es una tarjeta nueva
      this.itemForm = this.fb.group({
        numberCreditCard: [this.data.payload.numberCreditCard || '', Validators.required],
        nameHolderCreditCard: [this.data.payload.nameHolderCreditCard || '', Validators.required],
        expMonthCreditCard: [this.data.payload.expMonthCreditCard || '', [Validators.required, Validators.min(1), Validators.max(12), Validators.minLength(2)]],
        expYearCreditCard: [this.data.payload.expYearCreditCard || '', [Validators.required, Validators.min(1), Validators.max(99), Validators.minLength(2)]]
      })
    }else{
      this.itemForm = this.fb.group({
        numberCreditCard: [{value: '', disabled: true}],
        nameHolderCreditCard: [this.data.payload.nameHolderCreditCard || '', Validators.required],
        expMonthCreditCard: [{value: '', disabled: true}],
        expYearCreditCard: [{value: '', disabled: true}]
      })
    }
  }

  ngOnInit() {
    
  }


  submit() {
    this.dialogRef.close(this.itemForm?.value)
  }

}
