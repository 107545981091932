
<div class="column">
    <div class="logo-bank">
        <img src="/assets/images/map.png" alt="">
    </div>
    <div class="credit_data">
        <h4 class="tx_tipo">Panama</h4>
        <p>Panama Oeste, Costa Verde</p>
        <p>6747-2679</p>
        <span class="verified">activo</span>
        
    </div>
<div class="button-action">
    <div ><button class="disable">Desactivar</button></div>
</div>
</div>
