import { Component, Input,OnChanges, SimpleChanges } from '@angular/core';
import {PortalService} from "../../account-settings/portal/my-portals/portal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PreviewPopupComponent} from "../../partials/my-portal/preview-popup/preview-popup.component";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { PaymentPopupComponent } from 'src/app/account-settings/plans/payment-popup/payment-popup.component';
import { ToastService } from 'src/app/services/notification/toast.service';
import { WebService } from 'src/app/services/web/web.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import {PlanService} from '../../account-settings/plans/plan.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';




@Component({
  selector: 'app-my-portal',
  templateUrl: './my-portal.component.html',
  styleUrls: ['./my-portal.component.scss']
})
export class MyPortalComponent implements OnChanges{
  public myportals : any = [];
  @Input() portal: any;
  configID : any;
  public myplans : any = [];
  private onboardingConfigID: any;
  isValidDate = true;
  isPortalActive : boolean = true;
  showDeactivate = false;
  iframeSrc!: SafeResourceUrl;
  showPaymentButton : Boolean = false;
  constructor(
    private webService : WebService,
    private spinner : SpinnerService,
    private toast : ToastService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private router : Router, 
    private activateRoute : ActivatedRoute,
    private portalService : PortalService,
    private planService : PlanService) { 
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['portal']) {
      this.handleIsActiveChange();
      if (this.portal && this.portal.url_template) {
        this.iframeSrc = this.getUrlTemplatePreview(this.portal.url_template);
      }
    }
    if (changes['portal'] && this.portal && this.portal.url_template) {
      this.iframeSrc = this.getUrlTemplatePreview(this.portal.url_template);
    }
  }
  onButtonClick(portal: any): void {
    if (portal.customerHasPlan == null || portal.customerHasPlan.status_customerPlan != 1) {
      this.toast.showError('Para continuar, es necesario activar y pagar el servicio. Por favor, haga click en el botón de activar suscripcion para habilitar todas las funcionalidades de tu cuenta');
    } else {
      this.goAddClient(portal);
    }
  }
  ngOnInit(): void {
    
    this.portalService.portals.subscribe( portals =>{
      this.myportals = [];
      this.myportals.push(...portals);
     
    });
    // this.handleIsActiveChange();
    // if (this.portal?.customerHasPlan) {
    //   var nextBillDate = new Date(this.portal.customerHasPlan.next_bill_date);
    //   var today = new Date();
    //   this.isValidDate = nextBillDate > today;
    //   if (this.portal.customerHasPlan.status_customerPlan == 1 && this.isValidDate) {
    //     this.showDeactivate = true;
    //   }
    // }
    this.isPortalActive = this.portal.isActive;
    // if(this.portal.customerHasPlan != null){
    //   var nexBillDate = new Date(this.portal.customerHasPlan.next_bill_date);
    //   var today = new Date();
    //   this.isValidDate = nexBillDate > today;
    //   if(this.portal.customerHasPlan != null && (this.portal.customerHasPlan.status_customerPlan == 1 && this.isValidDate)){
    //     this.showDeactivate = true;
    //   }
    // }
    
    // this.planService.plans.subscribe( plans =>{
     
    //   if(plans.length > 0){
    //     this.myplans = [];
    //     this.myplans.push(...plans);
    //   }else{
    //     this.goCreatePlan();
    //   }
     
    // })
    // this.activateRoute.params.subscribe(params =>{
    //   this.onboardingConfigID = params['id'];
    //   this.getConfigByID();
    // }, err =>{
    //   this.toast.showError(err);
    // });
  }
  private handleIsActiveChange(): void {
    if (this.portal?.isActive !== undefined) {
     
    }
  }

  // goToTemplates(){
    
  //   this.router.navigate(['.', 'portal'], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  // }

  // goToFeatures(){
    
  //   this.router.navigate(['.', 'features'], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  // }

  goEdit(portal : any){
    this.router.navigate(["/edit/portal/" + portal.id], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
  goAddClient(portal : any){
    this.router.navigate(["/portal/" + portal.id + "/client"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
  goCreatePlan(){
    this.router.navigate(['create'], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

  goToPortal(portal : any) {
    window.open(portal, '_blank');
  }

  preview(url : any){

    let dialogRef: MatDialogRef<any> = this.dialog.open(PreviewPopupComponent, {
      width: '80%',
      height: '90%',
      disableClose: true,
      data: { url : url}
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      })
  }
  getConfigByID(){
    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(sesion.partner != null || sesion.partner != undefined){
      partner = sesion.partner
    }
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID+ "?partner=" + partner).subscribe( config =>{
      this.spinner.close();
      if(config.result.service.current_onboarding_step >= 5){
        if(config.result.customerHasPlan != null ){
          if(config.result.customerHasPlan.status_customerPlan == 1){
            var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
            var today = new Date();
            var isValidDate = nextBillDate > today;
            if(!isValidDate){
              this.showPaymentButton = true;
            }
          }else{
            this.showPaymentButton = true;
          }
  
        }else{
          this.showPaymentButton = true;
        }
      }
      
      this.planService.plans.emit(config.result.plans)
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }
  getUrlTemplatePreview(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  goToTemplates(portal : any, update : Boolean){
    if(update){
      this.router.navigate(["/edit/portal/" +portal.id+'/portal'], {queryParams: { update: true }}); 
    }else{
      this.router.navigate(["/edit/portal/" +portal.id+'/portal']); 
    }
    
  }
  goToFeatures(portal : any){
    this.router.navigate(["/edit/portal/" +portal.id+'/features'], {queryParams: { update: true }}); 
  }

  goDeactivatePortal(portal: any){
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
    
    });
  
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.spinner.open();
        const data = { id: portal.id, type: 2 };
        this.webService.post(data, this.webService.HOST + "/onboarding/manageStatus").subscribe(response => {
          this.spinner.close();
          this.toast.showSuccess("Portal desactivado correctamente");
          window.location.reload();
        }, err => {
          this.spinner.close();
          console.log(err);
          this.toast.showError(err);
        });
      } else {
        this.spinner.close();
      }
    });
  }
  

  processPayment(portal : any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {
        configID: portal.id,
        type : 1
      }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }else{
          window.location.reload();
        }
      })
  }

}
