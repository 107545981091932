import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-direcciones',
  templateUrl: './direcciones.component.html',
  styleUrls: ['./direcciones.component.scss']
})
export class DireccionesComponent {

  addresses : any = [];

  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  constructor(private dialog: MatDialog){

  }

}
