import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicComponent } from 'src/app/plans-preview/basic/basic.component';
import { PracticalComponent } from 'src/app/plans-preview/practical/practical.component';
import { ContempoComponent } from 'src/app/plans-preview/contempo/contempo.component';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import {Location} from "@angular/common";
import { PreviewPopupComponent } from '../../partials/my-portal/preview-popup/preview-popup.component';
import { Overlay } from '@angular/cdk/overlay';
import {PlanService} from "../plans/plan.service";

import { PaymentPopupComponent } from '../plans/payment-popup/payment-popup.component';

@Component({
  selector: 'app-update-portal-features',
  templateUrl: './update-portal-features.component.html',
  styleUrls: ['./update-portal-features.component.scss']
})
export class UpdatePortalFeaturesComponent {
  dialogRef!: MatDialogRef<PracticalComponent>;
  selectedTheme: string = 'Práctico';
  processPayment(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {  configID : this.onboardingConfigID }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }
      })
  }

 private _selectedPalette: string = 'Billcentrix';

  get selectedPalette(): string {
    return this._selectedPalette;
}

set selectedPalette(value: string) {
    this._selectedPalette = value;
    // console.log('_selectedPalette:',value)
    this.selectedPaletteChange.emit(value);  // emit the new value
}
@Output()
selectedPaletteChange = new EventEmitter<string>();

  checked : any;
  pallete1 = false;
  pallete2 = false;
  pallete3 = false;
  pallete4 = false;
  exist = false;

  showPreview = false;
  urlClientPreview : any;

  logoName = null;
	logo64: any;
	logoType: any;

	principalName: string = '';
  //principalName = null;
	principal64: any;
	principalType: any;

  promotionalName: string = '';
  //promotionalName = null;
	promotional64: any;
	promotionalType: any;

  clickOnPreview = false;

  form: FormGroup ;
  onboardingConfigID: any;
  fromUpdate : Boolean = false;
  public myplans : any = [];
  showPaymentButton : Boolean = false;

  constructor(private planService : PlanService,private dialog: MatDialog,private location : Location,private activateRoute : ActivatedRoute,private sanitizer: DomSanitizer,private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService,
    private overlay: Overlay) {
  

    this.form = new FormGroup({
      id: new FormControl(''),
      page_title: new FormControl('', Validators.required),
      description_page: new FormControl('', Validators.required),
      price_title : new FormControl('', Validators.required),
      banner_title: new FormControl('', Validators.required),
      description_banner : new FormControl('', Validators.required)

    });

  }

  ngOnInit(): void {
    this.planService.plans.subscribe( plans =>{
     
      if(plans.length > 0){
        this.myplans = [];
        this.myplans.push(...plans);
      }else{
        this.goCreatePlan();
      }
     
    })
    window.scrollTo(0, 0);
    this.activateRoute.queryParams.subscribe(queryParams=>{
      if(queryParams['update']){
        this.fromUpdate = true;
      }
      this.activateRoute.params.subscribe(params =>{
        this.onboardingConfigID = params['id'];
        this.getConfigByID();
      }, err =>{
        this.toast.showError(err);
      });
    },err =>{
      this.toast.showError(err);
    })
  }

  getConfigByID(){


    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(sesion.partner != null || sesion.partner != undefined){
      partner = sesion.partner
    }

    this.webService.get(this.webService.HOST + "/onboardingConfig/" +  this.onboardingConfigID + "?partner=" + partner).subscribe( config =>{
      // console.log(config);
      let theme = config.result.url_template;
      this.changeTheme(theme);
      this.exist = true;
      this.urlClientPreview = config.result.url_template + "/preview/" + config.result.service.id;
     
      if(config.result.color == "Billcentrix"){
        this.checked = "pal1" ;
        this.pallete1 = true;
        this.selectedPalette = 'Billcentrix';
      }else if(config.result.color == "Moderna"){
        this.checked = "pal2" ;
        this.pallete2 = true;
        this.selectedPalette = 'Moderna';
      }else if(config.result.color == "Violeta"){
        this.checked = "pal3" ;
        this.pallete3 = true;
        this.selectedPalette = 'Violeta';
      }else{
        this.checked = "pal1" ;
        this.pallete1 = true;
        this.selectedPalette = 'Billcentrix';
      }

      if(config.result.onboardingPortalContent != null){
        this.form.controls["id"].setValue(config.result.onboardingPortalContent.id);
        this.form.controls["page_title"].setValue(config.result.onboardingPortalContent.page_title);
        this.form.controls["description_page"].setValue(config.result.onboardingPortalContent.description_page);
        this.form.controls["price_title"].setValue(config.result.onboardingPortalContent.price_title);
        this.form.controls["banner_title"].setValue(config.result.onboardingPortalContent.banner_title);
        this.form.controls["description_banner"].setValue(config.result.onboardingPortalContent.description_banner);
      }
      if(config.result.onboardingImages != null){
        this.logo64 = config.result.onboardingImages.logo;
        this.principal64 = config.result.onboardingImages.principal_banner;
        this.promotional64 = config.result.onboardingImages.promotional_banner;
      }
      this.spinner.close();


// if(config.result.service.current_onboarding_step >= 5){
  if(config.result.customerHasPlan != null ){
    if(config.result.customerHasPlan.status_customerPlan == 1){
      console.log('Status of customerHasPlan:', config.result.customerHasPlan.status_customerPlan);
      var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
      var today = new Date();
      var isValidDate = nextBillDate > today;
      if(!isValidDate){
        this.showPaymentButton = true;
      }
    }else{
      this.showPaymentButton = true;
    }
    // if(config.result.customerHasPlan.status_customerPlan != 1){
    //   this.showPaymentButton = true;
    // }

  }else{
    this.showPaymentButton = true;
  }
// }
this.planService.plans.emit(config.result.plans)

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  selectPalette(event : any){
      // console.log( 'selected palette:',event.target.value)
      this.checked = event.target.value;
  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
      this.logoName = file.name;
      this.logoType = file.type;
      this.logo64 = reader.result!.toString();
      };
    }
  }

 onPrincipalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Generar un nombre de archivo único
      const uniqueFileName = 'nombre_principal' + generateUniqueString() + getFileExtension(file.name);

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.principalName = uniqueFileName; // Usar el nuevo nombre
        //this.principalName = file.name;
      this.principalType = file.type;
      this.principal64 = reader.result!.toString();
      };
    }
  }

  onPromotionalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Generar un nombre de archivo único
      const uniqueFileName = 'promotional' + generateUniqueString() + getFileExtension(file.name);

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.promotionalName = uniqueFileName; // Usar el nuevo nombre
  
        //this.promotionalName = file.name;
      this.promotionalType = file.type;
      this.promotional64 = reader.result!.toString();
      };
    }
  }

  sendConfiguration(){

    if(this.checked == undefined){
      return this.toast.showError("Por favor, seleccione una paleta de colores para el template");
    }

    if(this.showPreview){
      this.showPreview = false;
    }

    if(!this.exist){ // EN CASO DE QUE NO EXISTAN ENTONCES COMPROBAMOS QUE NO SE VAYAN VACIAS LAS IMAGENES
      if(this.logoName == null || this.logoName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su logo");
      }

      if(this.principalName == null || this.principalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner principal");
      }

      if(this.promotionalName == null || this.promotionalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner promocional");
      }
    }

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario de Datos Generales");
    }

    this.spinner.open();
   
    let json = { config : this.onboardingConfigID, content : this.form.value, partner : JSON.parse(localStorage.getItem('sesion')!).partner, palete : this.selectedPalette , logo : { name : this.logoName, base64 : this.logo64, type : this.logoType}, principal : { name : this.principalName, base64 : this.principal64, type : this.principalType}, promotional : { name : this.promotionalName, base64 : this.promotional64, type : this.promotionalType}};
    console.log(json);
    this.webService.put(json,this.webService.HOST + "/onboarding/step3").subscribe( response =>{

      this.spinner.close();

      this.toast.showSuccess("Configuracion actualizada correctamente, en unos minutos podra visualizarlo correctamente en su portal");

      // if(!this.clickOnPreview){
      //   this.location.back();
      // }else{
      //   this.form.controls["id"].setValue(response.onboardingPortalContent.id);
      //   this.clickOnPreview = false;
      //   this.showPreview = true;
      //   this.showPreviewPopup();
      //   // this.openModal();
      //   //window.location.href = this.urlClientPreview;
      // }

      if(!this.fromUpdate){
        this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/my-plans']); 
      }
      

    }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    );

  }

  showPreviewPopup(){
    console.log('url',this.urlClientPreview)
    let dialogRef: MatDialogRef<any> = this.dialog.open(PreviewPopupComponent, {
      width: '95%',
      height: '100%',
      disableClose: true,
      data: { url : this.urlClientPreview}
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      });
  }

  goCreatePlan(){
    this.router.navigate(['create'], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
  preview(){
    this.clickOnPreview = true;
    this.sendConfiguration();
  }

  getUrlTemplatePreview(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlClientPreview);
  }

  goBack(){
    this.router.navigate([".."], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }
scrollToElement(): void {
  document.getElementById('save')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
  changeTheme(theme: string) {
      if(theme === "https://dev-onboarding-practical.billcentric.com"){
      this.selectedTheme = 'Práctico';
    }else if (theme === "https://dev-onboarding-contemporary.billcentric.com"){
      this.selectedTheme = 'Contempo';
    }else{
      this.selectedTheme = 'Básico';
    }
  
  }

  handlePaletteSelected(palette: any): void {
    this.selectedPalette = palette;
    if(this.selectedPalette == "Billentrix"){
      this.checked = "pal1" ;
      this.pallete1 = true;
    }else if(this.selectedPalette == "Moderna"){
      this.checked = "pal2" ;
      this.pallete2 = true;
    }else if(this.selectedPalette == "Violeta"){
      this.checked = "pal3" ;
      this.pallete3 = true;
    }else{
      this.checked = "pal1" ;
      this.pallete1 = true;
    }
  }

  openModal(): void {
    let component: any; // Variable para almacenar el componente a abrir
     
    if (this.selectedTheme === 'Básico') {
      component = BasicComponent;
    } else if (this.selectedTheme === 'Práctico') {
      component = PracticalComponent;
    } else if (this.selectedTheme === 'Contempo') {
      component = ContempoComponent;
    }

    if (component) {
      this.dialogRef = this.dialog.open(component, {
        width: '90%',
        height: '100%',
        scrollStrategy: this.overlay.scrollStrategies.reposition()
      });

      this.dialogRef.componentInstance.selectedPalette = this.selectedPalette;

      this.dialogRef.afterClosed().subscribe(result => {
        // Después de que el modal se haya cerrado ejecutar acciones de ser necesario
      });
    }
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  goToPlans(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/my-plans']); 
  }

  goToEdit(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID]);
  }

  goToTemplates(){
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/portal']); 
  }
  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }


}
// Asegúrate de que las funciones getFileExtension y generateUniqueString estén definidas
function getFileExtension(fileName: string): string {
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex < 0) return ''; // No hay extensión
  return fileName.slice(lastIndex); // Incluye el punto en la extensión
}

function generateUniqueString(): string {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 5; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return new Date().getTime().toString() + result; // Combinar fecha y hora con caracteres aleatorios
}

