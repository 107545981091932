<body>
<div class="grid-overlay">
    <div class="header">
        <div class="logo">
            <a  href="/my-portals">
                <img src="../../assets/images/BRAND2.png"  alt="logo de Billcentrix">
            </a>
        </div>
        <div class="buttons">
            <!-- <button class="create-report-btn"><h1>+</h1></button> -->
            <img src="../../assets/icons/reportes_aqua.png" alt="icono reportes">
            <app-menu></app-menu>
        </div>
    </div>

    <div class="reports-data">
        <!-- <div class="second-headboard">
            <h1>Reportes</h1>
            <button>Gestión de clientes</button>
            <button>Facturación</button>
            <button>Reporte diario</button>
        </div> -->
        <div> <h1>Historial de transacciones</h1></div>
        <mat-accordion>
            <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                <mat-expansion-panel-header>

                    <button class="btn-icon btn"  title="Buscar" type="button">
                        <i class="material-icons">search</i>
                    </button>
                </mat-expansion-panel-header>

                <form>
                    <fieldset class="fieldset">



                        <div fxLayout="row wrap" class="fild_search">

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field appearance="fill">
                                        <mat-label>Rango de fechas</mat-label>
                                        <mat-select  [(ngModel)]="filter.dateSearchType" [ngModelOptions]="{standalone: true}">
                                            <mat-option *ngFor="let d of datesFrom" [value]="d.value">{{ d.description }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div *ngIf="filter.dateSearchType == 5" fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">
                                    <mat-form-field class="full-width">
                                        <input matInput
                                               [matDatepicker]="picker"
                                               name="since"
                                               placeholder="Desde"
                                               [(ngModel)]="filter.dateFrom"   readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker touchUi #picker></mat-datepicker>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div *ngIf="filter.dateSearchType == 5" fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <input matInput
                                               [matDatepicker]="picker2"
                                               name="until"
                                               placeholder="Hasta"
                                               [(ngModel)]="filter.dateUntil"   readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker touchUi #picker2></mat-datepicker>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field appearance="fill">
                                        <mat-label>Estado de la Transacción </mat-label>
                                        <mat-select  [(ngModel)]="filter.status" [ngModelOptions]="{standalone: true}">
                                            <mat-option *ngFor="let d of invoiceStatus" [value]="d.value">{{ d.description }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <mat-label>Monto Mínimo</mat-label>
                                        <input
                                                matInput
                                                name="min"
                                                type="number"
                                                [(ngModel)]="filter.minimum">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <mat-label>Monto Máximo</mat-label>
                                        <input
                                                matInput
                                                name="max"
                                                type="number"
                                                [(ngModel)]="filter.maximum">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <mat-label>Nombre del cliente</mat-label>
                                        <input
                                                matInput
                                                name="nameCustomer"
                                                value=""
                                                [(ngModel)]="filter.name_customer">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <mat-label>Email del cliente</mat-label>
                                        <input
                                                matInput
                                                name="nameCustomer"
                                                value=""
                                                [(ngModel)]="filter.email_customer">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                <div class="pb-16">

                                    <mat-form-field class="full-width">
                                        <mat-label>ID de Transacción</mat-label>
                                        <input
                                                matInput
                                                name="nameCustomer"
                                                [(ngModel)]="filter.id">
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>

                    </fieldset>
                    <div class="button_report_align"fxLayout="row" fxLayoutAlign="end center" >

                        <button
                                mat-raised-button
                                type="button"
                                color="warn"
                                class="ml-16 mr-16 button_report light"
                                (click)="reset()"
                        >
                            <mat-icon>refresh</mat-icon>
                        </button>

                        <button
                                mat-raised-button
                                type="button"
                                color="primary"
                                class="ml-16 button_report blue"
                                (click)="search()">
                            <mat-icon>search</mat-icon>
                        </button>

                        <button
                                mat-raised-button
                                type="button"
                                color="primary"
                                class="ml-16 button_report blue"
                                (click)="download()">
                            <mat-icon>download</mat-icon>
                        </button>

                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>

        <div style="padding-top: 2em;">
            <mat-table [dataSource]="dataSource"  >


                <ng-container matColumnDef="service">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="service"> {{row.service}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="plan">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Plan </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="plan"> {{row.plan}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="nameClient">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nameClient"> {{row.nameClient}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailClient">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="emailClient"> {{row.emailClient}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="transaction">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="transaction"> {{row.transaction}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="date"> {{row.date}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Estado </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status"> {{row.status}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Monto </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="amount"> {{row.amount}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastDigits">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Últimos 4 Dígitos </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="lastDigits"> {{row.lastDigits}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="motive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Respuesta del Banco </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="motive"> {{row.motive}} </mat-cell>
                </ng-container>





                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" >
                </mat-row>
            </mat-table>
            <mat-paginator showFirstLastButtons
                           [length]="tableService.pager.totalEntities"
                           [pageIndex]="tableService.pager.pageIndex"
                           [pageSize]="tableService.pager.pageSize"
                           [pageSizeOptions]="[20, 50, 100]"
                           (page)="list($event)">
            </mat-paginator>
        </div>

    </div>
    <div class="options-buttons">
        <button class="back-btn" (click)="goBack()" >Atrás</button>
    </div>
</div>
</body>