<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <button (click)="goToTemplates()">Regresar</button>
                
                <!-- <img src="../../../assets/icons/cubo-green.png" alt="icono portales"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="personalize-content">
            <div class="second-headboard">
                <h1>Personalización</h1>
                <!-- <img src="../../../assets/icons/3412.png" alt="logo de Billcentrix"> -->
                <div class="linked-icons" >
                    <button (click)="goToTemplates()" >Templates</button>
                    <button class="active">Personalización</button>
                    <button *ngIf="!showPaymentButton" (click)="goToPortals()">Guardar</button>
                    <button  *ngIf="showPaymentButton" (click)="processPayment()">Activar portal</button>
                    <!-- <img src="../../assets/icons/1_cuenta_off.png" alt="Paso 1: Cuenta" (click)="goToEdit()" >
                    <img src="../../assets/icons/2_portales_off.png" alt="Paso 2: Portal" (click)="goToTemplates()" >
                    <img src="../../assets/icons/3_personalizar_on.png" alt="Paso 3: Personalizar" >
                    <img src="../../assets/icons/4_planes_off.png" alt="Paso 4: Planes" (click)="goToPlans()"> -->
                </div>
            </div>
            <div class="options-content">
                <!-- <div class="selected-theme">
                    <h1>Tema seleccionado: <strong>{{ selectedTheme }}</strong></h1>
                    <p>Puedes cambiar el template cuando quieras</p>
                    <div class="change-btn">
                        <button mat-icon-button [matMenuTriggerFor]="menuPlan">Cambiar</button>
                        <mat-menu #menuPlan="matMenu" class="menu">
                            <button mat-menu-item (click)="changeTheme('Básico')">Básico</button>
                            <button mat-menu-item (click)="changeTheme('Práctico')">Práctico</button>
                            <button mat-menu-item (click)="changeTheme('Contempo')">Contempo</button>
                        </mat-menu>
                    </div>
                </div> -->
                <app-update-slider (paletteSelected)="handlePaletteSelected($event)" [storedPalette]="selectedPalette"></app-update-slider>
                <div class="brand">
                    <h1>Su marca</h1>
                    <div class="grid-brand">
                        <div class="upload-file">
                            <p class="principal-text">Suba el logo de su empresa, preferiblemente .svg para mejor
                                calidad.
                                El tamaño recomendado:
                                200 x 50 pixels. Será cargado en la parte superior izquierda del portal</p>
                            <div class="upload">
                                <img src="../../../assets/icons/subir.png" alt="subir archivo">
                                <p class="action-description">Subir archivo</p>
                            </div>
                            <div class="preview">
                                <!-- <img src="../../../assets/icons/ver.png" alt="vista previa">
                                <p class="action-description">Vista previa</p> -->
                               
                               
                               
                                <input class="upload-photo" type="file" id="formFile" accept="image/png, image/jpeg, .svg"  (change)="onLogoChange($event)" >
                            </div>
                        </div>
                        <div class="image-width">
                            <div class="line-horizontal"></div>
                            <span class="line-text-horizontal">200 pixels</span>
                            <div class="line-vertical"></div>
                            <span class="line-text-vertical">50 pixels</span>
                            <img src="../../../assets/images/BRAND3.png" alt="medidas del logo">
                            <br>
                            <br>
                            <br>
                            <img [src]="logo64" *ngIf="logo64" alt="foto" width="250" height="100">
                            
                        </div>
                    </div>
                </div>
                <div class="main-image">
                    <div class="grid-main-image">
                        <div class="left">
                            <h1>Imagen principal</h1>
                            <p class="principal-text">Esta imagen será la bienvenida al cliente, use una imagen tipo
                                .jpg
                                para el mejor y rápido
                                desempeño de su portal sin sacrificar calidad. El tamaño recomendado: 1440 x 720 pixels</p>
                            <div class="upload">
                                <img src="../../../assets/icons/subir.png" alt="subir archivo">
                                <p class="action-description">Subir archivo</p>
                            </div>
                            <div class="preview">
                                <input class="upload-photo" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onPrincipalChange($event)" >
                                <!-- <img src="../../../assets/icons/ver.png" alt="vista previa">
                                <p class="action-description">Vista previa</p> -->
                            </div>
                            <div class="sample-measurements">
                                <div class="line-horizontal"></div>
                                <span class="line-text-horizontal">1440 pixels</span>
                                 <img [src]="principal64" *ngIf="principal64" alt="foto" width="710" height="180">
                                <div class="line-vertical"></div>
                                <span class="line-text-vertical">720 pixels</span>
                            </div>
                        </div>
                        <div class="right">
                            <img class="section" src="../../../assets/images/indicadores-pag.png" alt="sectores de la pagina">
                            <img class="sample" src="../../../assets/images/pag-min.png" alt="muestra de la pagina">
                       </div>
                      </div>
                </div>
                <div class="promotional-banner">
                    <div class="grid-promotional-banner">
                        <div class="left">
                            <h1>Banner promocional</h1>
                            <p class="principal-text">Esta imagen será mostrada en la parte final del portal, puede ser
                                una
                                imagen promocional o
                                una segunda imagen representativa del portal. Use una imagen tipo .jpg para el mejor y
                                rápido desempeño de su portal sin sacrificar calidad. El tamaño recomendado: 320 x 400
                                pixels</p>
                            <div class="upload">
                                <img src="../../../assets/icons/subir.png" alt="subir archivo">
                                <p class="action-description">Subir archivo</p>
                            </div>
                            <div class="preview">
                                <!-- <img src="../../../assets/icons/ver.png" alt="vista previa">
                                <p class="action-description">Vista previa</p> -->
                                <input class="upload-photo" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onPromotionalChange($event)" >

                            </div>
                            <div class="frequent-questions">
                                <!-- <img src="../../../assets/icons/informacion2.png" alt="icono preguntas frecuentes">
                                <p>Haz clic aquí para preguntas frecuentes</p> -->
                            </div>
                        </div>
                        <div class="right">
                            <div class="sample-measurements">
                                <div class="line-horizontal"></div>
                                <span class="line-text-horizontal">320 pixels</span>
                                <img [src]="promotional64" *ngIf="promotional64" alt="foto" width="160" height="260">
                                <div class="line-vertical"></div>
                                <span class="line-text-vertical">400 pixels</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-image">
                    <div class="grid-main-image">
                        <div class="left">
                            <h1>Datos generales del portal</h1>
                            <div class="grid-general-data">
                                <div class="left">
                                    <div class="items">
                                        <label for="image-title">Título para la imagen principal</label>
                                        <input  [formControl]="form.controls['page_title']" type="text" placeholder="Ej. Bienvenidos a Club Platino">
                                        <small  *ngIf="form.controls['page_title'].hasError('required') && form.controls['page_title'].touched"
                                                class="form-error-msg"> Título para la imagen es requerido </small>
                                    </div>
                                    <div class="items">
                                        <label for="description">Descripción de la página</label>
                                        <textarea type="textarea" [formControl]="form.controls['description_page']"
                                            placeholder="Ej. Servicio dedicado a suplir a la audiencia amante de los vinos, se ofrece aquí un servicio de suscripción a los mejores precios y con extraordinaria calidad."></textarea>
                                            <small *ngIf="form.controls['description_page'].hasError('required') && form.controls['description_page'].touched"
                                                   class="form-error-msg"> Descripción es requerido </small>
                                        </div>
                                   
                                    <div class="items">
                                        <label for="action">Título para planes</label>
                                        <input [formControl]="form.controls['price_title']"  type="text" placeholder="¡Ej. Suscríbase ya!">
                                        <small *ngIf="form.controls['price_title'].hasError('required') && form.controls['price_title'].touched"
                                        class="form-error-msg"> Título para planes es requerido </small>
                                    </div>
                                </div>
                                <div class="rihgt">

                                    <div class="items">
                                        <label for="banner-text">Título banner producto</label>
                                        <input [formControl]="form.controls['banner_title']" type="text" placeholder="Ej. Aprovecha nuestra oferta con la promoción...">
                                        <small *ngIf="form.controls['banner_title'].hasError('required') && form.controls['banner_title'].touched"
                                                class="form-error-msg"> Título del banner es requerido </small>
                                    </div>
                                   
                                    <div class="items">
                                        <label for="banner-description">Descripción del banner del producto</label>
                                        <input [formControl]="form.controls['description_banner']"  type="text" placeholder=" Ej.Oferta del día de la madre">
                                        <small
                                        *ngIf="form.controls['description_banner'].hasError('required') && form.controls['description_banner'].touched"
                                        class="form-error-msg"> Descripción del banner es requerido </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <img class="sample" src="../../../assets/images/datosPortalpng.png" alt="muestra de la pagina">
                       </div>
                      </div>
                </div>
                <div class="main-image">
                    <div class="grid-main-image">
                        <div class="left">
                            <h1>Idioma</h1>
                            <div class="grid-general-data">
                                <div class="left">
                                    <div class="items">
                                        <label for="language-select">Selecciona el idioma</label>
                                        <select id="language-select" [formControl]="form.controls['language']">
                                            <option value="es">Español</option>
                                            <option value="en">Inglés</option>
                                        </select>
                                        <small *ngIf="form.controls['language'].hasError('required') && form.controls['language'].touched"
                                               class="form-error-msg"> El idioma es requerido </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        <div class="buttons">
            <!-- <div class="preview-mobile" >
                <button class="finish" (click)="preview()">Vista previa</button>
            </div> -->
            <div class="action-buttons">
                <button class="back-btn" (click)="goToPortals()">Mis suscripciones</button>
                <!-- <button class="finish" (click)="sendConfiguration()"*ngIf="exist" >Guardar y continuar</button> -->
                <!-- <button class="finish" (click)="sendConfiguration()"  *ngIf="!exist">Continuar</button> -->
          </div>
            <div class="action-buttons">
                <!-- <div class="preview" (click)="preview()">
                    <button class="finish" (click)="preview()">Vista previa</button>
                </div> -->
                <button id="save" class="finish" (click)="sendConfiguration()">Guardar</button>
            </div>
        </div>
    </div>
</body>