import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-card-popup',
  templateUrl: './create-card-popup.component.html',
  styleUrls: ['./create-card-popup.component.scss']
})
export class CreateCardPopupComponent implements OnInit {

  public itemForm: FormGroup;
  public isManualInput = true;

  // Lista de meses para el select
  public months = [
    { value: '01', label: '01 - Enero' },
    { value: '02', label: '02 - Febrero' },
    { value: '03', label: '03 - Marzo' },
    { value: '04', label: '04 - Abril' },
    { value: '05', label: '05 - Mayo' },
    { value: '06', label: '06 - Junio' },
    { value: '07', label: '07 - Julio' },
    { value: '08', label: '08 - Agosto' },
    { value: '09', label: '09 - Septiembre' },
    { value: '10', label: '10 - Octubre' },
    { value: '11', label: '11 - Noviembre' },
    { value: '12', label: '12 - Diciembre' }
  ];

  // Lista dinámica de años (año actual + 15 años)
  public years: { value: string, label: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateCardPopupComponent>,
    private fb: FormBuilder,
  ) { 
    this.generateYears(); // Llamamos a la función para generar los años dinámicamente

    if (this.data.payload.id == undefined) { // Es una tarjeta nueva
      this.itemForm = this.fb.group({
        numberCreditCard: [
          this.data.payload.numberCreditCard || '', 
          [Validators.required, this.validateCreditCardNumber]
        ],
        nameHolderCreditCard: [this.data.payload.nameHolderCreditCard || '', Validators.required],
        expMonthCreditCard: [this.formatMonth(this.data.payload.expMonthCreditCard || ''), [Validators.required]],
        expYearCreditCard: [this.formatYear(this.data.payload.expYearCreditCard || ''), [Validators.required]]
      });      
    } else {
      this.itemForm = this.fb.group({
        numberCreditCard: [{value: '', disabled: true}],
        nameHolderCreditCard: [this.data.payload.nameHolderCreditCard || '', Validators.required],
        expMonthCreditCard: [{value: '', disabled: true}],
        expYearCreditCard: [{value: '', disabled: true}]
      });
    }
  }

  ngOnInit() {}

  submit() {
    console.log('Datos antes de enviar:', this.itemForm.value);
  
    // Limpiar número de tarjeta dentro del formulario ANTES de copiar los datos
    const cleanCardNumber = this.itemForm.controls['numberCreditCard'].value.replace(/-/g, '');
    this.itemForm.controls['numberCreditCard'].setValue(cleanCardNumber, { emitEvent: false });
  
    const formData = { ...this.itemForm.value };
  
    // Asegurar que el mes tenga siempre 2 dígitos
    formData.expMonthCreditCard = this.formatMonth(formData.expMonthCreditCard);
  
    // Asegurar que el año tenga solo los últimos 2 dígitos
    formData.expYearCreditCard = this.formatYear(formData.expYearCreditCard);
  
    console.log('Datos después de formatear:', formData);
  
    this.dialogRef.close(formData);
  }

  onlyNumberKey(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) { // Solo permite números (0-9)
      event.preventDefault();
    }
  }

  formatCreditCardNumber(): void {
    if (!this.isManualInput) return; // Salir si no es entrada manual
  
    const control = this.itemForm.controls['numberCreditCard'];
    let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos
  
    if (value.length > 16) {
      value = value.substring(0, 16); // Limitar a 16 caracteres numéricos
    }
  
    const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
    control.setValue(formattedValue, { emitEvent: false });
  }

  validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
    const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación

    if (!value || !this.isManualInput) return null; // No aplicar validación si no es entrada manual

    // Comprobar si tiene 15 o 16 dígitos
    if (value.length !== 15 && value.length !== 16) {
      return { invalidLength: true };
    }

    // Validar usando el Algoritmo de Luhn
    if (!this.luhnCheck(value)) {
      return { invalidCardNumber: true };
    }

    return null;
  };

  luhnCheck(value: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i));

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10) === 0;
  }

  // Función para generar los años dinámicamente (año actual + 15 años)
  generateYears(): void {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 15; i++) {
      const fullYear = currentYear + i;
      const shortYear = fullYear.toString().slice(-2); // Solo los últimos 2 dígitos
      this.years.push({ value: shortYear, label: `${fullYear}` });
    }
  }

  // Formatea el mes para asegurarse de que siempre tenga 2 dígitos (01, 02, ..., 12)
  formatMonth(value: string): string {
    if (!value) return '';
    return value.padStart(2, '0'); // Si es un solo dígito, agrega un '0' delante
  }

  // Formatea el año para enviar solo los últimos 2 dígitos
  formatYear(value: string): string {
    if (!value) return '';
    return value.length === 4 ? value.slice(-2) : value; // Si es un año de 4 dígitos, corta los primeros 2
  }
  
}
