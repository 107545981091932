import { Component, OnInit } from '@angular/core';
import {PortalService} from "./portal.service";
import {WebService} from "../../../services/web/web.service";
import { ToastService } from '../../../services/notification/toast.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import { DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-portals',
  templateUrl: './my-portals.component.html',
  styleUrls: ['./my-portals.component.scss']
})

export class MyPortalsComponent implements OnInit {
  public myportals : any = [];
  constructor(private router: Router,private sanitizer: DomSanitizer,private portalService : PortalService, private webService : WebService,private spinner : SpinnerService,private toast : ToastService) { }
  //control color
  get isEmpty(): boolean {
    return this.myportals.length === 0;
  }
  //end control color
  ngOnInit(): void {
    this.portalService.portals.subscribe( portals =>{
      this.myportals = [];
      this.myportals.push(...portals);
    })

    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    this.spinner.open();
    if(sesion.partner== null){
      this.spinner.close();
      return this.toast.showInfo("No posees suscripciones creadas, por favor crea tu primera suscripcion");
    }

    this.webService.get(this.webService.HOST + "/onboarging/partner/" + sesion.partner).subscribe( portals =>{

      for (let portal of portals.result) {
        let service =
          portal.service.id != null ? portal.service.id : portal.service;
        portal.url_template = portal.url_template + "/preview/" + service;
      }

      //  // Separar portales pagados y no pagados
      //  let pagados = portals.result.filter(
      //   (portal: { customerHasPlan: { status_customerPlan: number; }; }) =>
      //     portal.customerHasPlan != null &&
      //     portal.customerHasPlan.status_customerPlan == 1
      // );
      // let noPagados = portals.result.filter(
      //   (portal: { customerHasPlan: { status_customerPlan: number; }; }) =>
      //     portal.customerHasPlan == null ||
      //     portal.customerHasPlan.status_customerPlan != 1
      // );


      // Ordenar cada grupo por createdAt en orden descendente
      // pagados.sort((a: { id: number; }, b: { id: number; }) => b.id - a.id);
      // noPagados.sort((a: { id: number; }, b: { id: number; }) => b.id - a.id);

      portals.result.sort((a: { id: number; }, b: { id: number; }) => b.id - a.id);

      // Combinar los grupos
      // let ordenados = pagados.concat(noPagados);
      this.portalService.portals.emit(portals.result);

      // this.portalService.portals.emit(portals.result)

      this.spinner.close();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    });
  }

  goSettings(){
    this.router.navigate(['/settings'], { queryParams: { new: true } });
  }

}
