import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit } from '@angular/core';
import { Validators,ValidatorFn, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FacPopupComponent} from '../fac-popup/fac-popup.component';
import { DomSanitizer} from '@angular/platform-browser';
import { config } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { UniqueCodeService } from './unique-code.service';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';
import { AppDialogContainerComponent } from '../app-dialog-container/app-dialog-container.component'; // Importa tu componente aquí
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {

  private pollingSuccess = new Subject<void>();
  form: any ;
  errorNumber = true;
  numberRequired = false;
  id_process: any;
  iframeElement: HTMLIFrameElement;
  onboardingPlan : any;
  configID: any;
  feeCostPlan: number | null = null;
  uniqueCode: string = "";
  myIp: any;
  myHeight: any;
  myWidth: any;
  jwtTokenForThird: string; // Añadir esta línea
  transactionIdForThird: string; // Añadir esta línea
  transactionId: string;
  iframeValue: string;
  iframeName: string;
  iframeUrl: string;
  redirectUrl: string;
  dataObject : any;
  chp : any;
  retryCount = 0;
  maxRetries = 5;
  httpAcceptBrowserValue;
  httpAcceptContent ;
  httpBrowserColorDepth;
  httpBrowserJavaEnabled;
  httpBrowserJavaScriptEnabled;
  httpBrowserLanguage ;
  httpBrowserTimeDifference ;
  refundJson: { chp: any; sop: any; call: string; };

  constructor(private activateRoute : ActivatedRoute,public dialogRef: MatDialogRef<PaymentPopupComponent>,
    private sanitizer: DomSanitizer,private dialog: MatDialog,private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private uniqueCodeService: UniqueCodeService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
   
    @Inject(DOCUMENT) private document: Document,
    private webService : WebService, private toast : ToastService,
     private spinner : SpinnerService,
     private router : Router,
     @Inject(MAT_DIALOG_DATA) public data: any) {

    this.spinner.open();
    this.form = new FormGroup({
      plan: new FormControl('1', [
        Validators.required
      ]),
      nameHolderCreditCard: new FormControl('', [Validators.required]),
  //numberCreditCard: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
  numberCreditCard: new FormControl('', [Validators.required, Validators.pattern(/^[0-9-]*$/), this.validateCreditCardNumber]),
  ccv: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]),
  expMonthCreditCard: new FormControl('', [Validators.required]),
  expYearCreditCard: new FormControl('', [Validators.required]),
  street: new FormControl('', [Validators.required]),
  city: new FormControl('', [Validators.required]),
  postalCode: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
  phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{7,10}$/)])
    });

    this.webService.get(this.webService.HOST + "/config/key/" + "billcentrix_onboarding_suscription_plan").subscribe(response =>{
      this.spinner.open();
      this.onboardingPlan = +response.result.value_config;
     
      this.activateRoute.queryParams.subscribe(queryParams =>{

        this.webService.get(this.webService.HOST + "/plan/" + this.onboardingPlan).subscribe(plan=>{
          // NICO ESTE ES EL COSTO DEL PLAN
          this.feeCostPlan = +plan.result.feeCost_plan;
          console.log(plan.result.feeCost_plan);
          if(queryParams['config']){
        
            this.configID = +queryParams['config'];
          
           
          }else{
           
                  this.configID = data.configID;
            
            
          }
          this.spinner.close();
        }, err =>{
          this.spinner.close();
          console.log(err)
        });
       
        

       

      }, err =>{
        this.toast.showError(err);
      });


    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    })

  }

  ngOnInit(): void {
    this.myHeight = window.innerHeight;
    this.myWidth = window.innerWidth;
    this.myIp = '189.127.166.102';
    this.httpAcceptBrowserValue = navigator.userAgent;
    this.httpAcceptContent = navigator.userAgent; // Similar al anterior
    this.httpBrowserColorDepth = window.screen.colorDepth;
    this.httpBrowserJavaEnabled = true;
    this.httpBrowserJavaScriptEnabled = true; // Si el código se ejecuta, JavaScript está habilitado
    this.httpBrowserLanguage = navigator.language;
    this.httpBrowserTimeDifference = new Date().getTimezoneOffset().toString();
    this.pollingSuccess.subscribe(() => {
      this.continueToNextStep();
    });
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const uniqueCode = this.generateUniqueCode();
      this.uniqueCodeService.setUniqueCode(uniqueCode);
      this.uniqueCode = uniqueCode;
      console.log('Código único generado al cargar la página:', uniqueCode);
      this.addUniqueCodeToHead(uniqueCode); // Añadir el uniqueCode al head
      this.addUniqueCodeToBody(uniqueCode); // Añadir el uniqueCode al body
      this.cdr.detectChanges();
    }, 0);
  }

//YO SOY NICO
  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  formatCreditCardNumber(): void {
    const control = this.form.get('numberCreditCard');
    let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

    if (value.length > 16) {
      value = value.substring(0, 16); // Limitar a 16 caracteres
    }

    // Agregar guiones automáticamente cada 4 dígitos
    const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
    control.setValue(formattedValue, { emitEvent: false }); // Actualizar el valor del campo sin emitir eventos adicionales
  }
  validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
    const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación
    if (!value) return null;

    // Comprobar si tiene exactamente 16 dígitos
    if (value.length !== 16) {
        return { invalidLength: true };
    }

    // Validar usando el Algoritmo de Luhn
    if (!this.luhnCheck(value)) {  // Asegúrate de que 'this.luhnCheck' está correctamente referenciado.
        return { invalidCardNumber: true };
    }

    return null;
}
  // Algoritmo de Luhn para validar el número de tarjeta
  luhnCheck(cardNumber: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10) === 0;
  }
  //fin nico
  
  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  // LO DE ABAJO NO CAMBIA MUCHO
  send(){
    console.log("ENVIANDO DATA")
    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario");
    }

    this.spinner.open();
     // Limpia el número de tarjeta de crédito de guiones YO SOY NICO
  const cleanedCreditCardNumber = this.form.value.numberCreditCard.replace(/-/g, '');

    // AQUI EMPIEZAN ALGUNOS CAMBIOS . COMO VES SE AGREGAN PARAMETROS a LOS JSONS
    if(!this.data.type){
      const data = {
        creditcard:{
          // numberCreditCard : this.form.value.numberCreditCard.trim(),
           numberCreditCard :  cleanedCreditCardNumber.trim(),
           nameHolderCreditCard : this.form.value.nameHolderCreditCard,
           expMonthCreditCard : this.form.value.expMonthCreditCard,
           expYearCreditCard : this.form.value.expYearCreditCard,
           ccv : this.form.value.ccv
         },
         extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
           ip: this.myIp, //MUESTRA LA IP CORRESPONDIENTE AHORITA LA TENGO CABLEADA
           fingerprint: this.uniqueCode, // ESTO ES LO QUE NECESITAMOS PARA CYBERSOURCE
           screenHeight: this.myHeight,
           screenWidth: this.myWidth,
           email : localStorage.getItem('username'),
           street: this.form.value.street,
           city: this.form.value.city,
           postalCode: this.form.value.postalCode,
           phone : this.form.value.phone,
           httpAcceptBrowserValue : this.httpAcceptBrowserValue,
           httpAcceptContent : this.httpAcceptContent,
           httpBrowserColorDepth : this.httpBrowserColorDepth,
           httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
           httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
           httpBrowserLanguage : this.httpBrowserLanguage,
           httpBrowserTimeDifference : this.httpBrowserTimeDifference
        },
         plan : this.onboardingPlan,
         config : this.configID,
         param : 7,
         email :localStorage.getItem('username')
       }
       console.log('Payload de la solicitud:', data);
       this.sendData(data,"/suscription",true);
    }else{
      const data = {
        creditcard:{
           numberCreditCard : cleanedCreditCardNumber.trim(),//YO SOY NICO
           nameHolderCreditCard : this.form.value.nameHolderCreditCard,
           expMonthCreditCard : this.form.value.expMonthCreditCard,
           expYearCreditCard : this.form.value.expYearCreditCard,
           ccv : this.form.value.ccv
         },
         extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
          ip: this.myIp,
          fingerprint: this.uniqueCode,
          screenHeight: this.myHeight,
          screenWidth: this.myWidth,
          email : localStorage.getItem('username'),
          street: this.form.value.street,
          city: this.form.value.city,
          postalCode: this.form.value.postalCode,
          phone : this.form.value.phone,
        },
         plan : this.onboardingPlan,
         config : this.configID,
         id:this.configID,
         param : 7,
         email :localStorage.getItem('username'),
         type : this.data.type
       }
       console.log('Payload de la solicitud:', data);
       this.sendData(data,"/onboarding/manageStatus",true);
    }

  }

  sendData(data : any, url : string, habilitate : boolean){
  // console.log("DATA QUE SE DEBE IR AL PAYER AUTH / O 3DS: ", data)
  console.log("SOLICITAMOS EL 3DS a esta URL : " + this.webService.HOST + url)
  // AQUI ES DONDE VIENE LO BUENO
  // REALIZAMOS EL PROCESO DE 3DS
  this.webService.post(data,this.webService.HOST + url).subscribe(response =>{
    console.log("RESPONSE DEL PROCESO DE 3DS: ", response)
    this.chp = response.id;
    this.id_process = this.chp;
        // AQUI VAS A ENCONTRAR UNA SERIE DE CASOS DONDE powerTranz ES EL CASO DE USO DE SIEMPRE - ESTO NO CAMBIA PARA NADA
        // REPITO NO CAMBIA
        // ESTE PARAMETRO DE bank TE TIENE QUE RETORNAR SIEMPRE SINO NO SABREMOS QUE HACER
        // EN CASO DE QUE NO VENGA HAZMELO SABER 
        if(response.bank == 'powerTranz'){ // ESTE PARAMETRO NUEVO ES CLAVE PARA SABER COMO PROCEDER 
          // POWERTRANZ ES EL BANCO DE SIEMPRE ASI QUE ESO SE DEJA TAL CUAL ESTA - NO SUFRE CAMBIOS
          // this.toast.showInfo("ESTE BANCO ES POWERTRANZ")
            if(response.htmlFormData){
              this.spinner.close();
              this.id_process = response.id;
              let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
                width: '50%',
                disableClose: true,
                data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: this.id_process }
              })
              dialogRef.afterClosed()
                .subscribe(res => {
                  this.myLoadEvent(habilitate);
                })
  
            }else{
              this.spinner.close();
            // EN ESTE CASO EL CHALLENGE NO FUE REQUERIDO, POR LO TANTO OBTENEMOS LA SUSCRIPCION EN EL SERVICIO
              if(response.result.status_customerPlan == 1 ){
                
                this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
                if(habilitate){
                  this.activatePortal();
                }else{
                  this.spinner.close();
                  this.dialogRef.close(1);
                  this.router.navigate(["/my-portals"]); 
                }
      
              }else{
      
                this.spinner.close();
                this.dialogRef.close(2);
                this.toast.showError("Su pago fue rechazado por el banco");
              
              }
  
            }
        }else if(response.bank == 'cyberSource'){   // AQUI VIENE TODO EL FLUJO DE CYBERSOURCE
          // APARTIR DE AQUI SE DEBE CREAR UNA IMPLEMENTACION PARA CADA BANCO
          // ESTA ES LA IMPLEMENTACION PARA CYBERSOURCE
          // this.toast.showInfo("ESTE BANCO ES CYBERSOURCE");
          if (response.sopResponse.responseCodeDescription != "COMPLETED") { // ESTO ESTRA PROBADO
            this.spinner.close();
            console.log("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
            this.toast.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
            return;
          }
          const transactionIdentifier = response.sopResponse.transactionId;
          const jwtToken = response.sopResponse.auth3DSDetail.token;
          this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
          if (environment.production) {
            this.createAndSubmitIframeForm(
              jwtToken, 
              "https://centinelapi.cardinalcommerce.com/V1/Cruise/Collect", 
              "ddc-iframe", 
              "ddc-form"
            );
          } else {
            this.createAndSubmitIframeForm(
              jwtToken, 
              "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", 
              "ddc-iframe", 
              "ddc-form"
            );
          }
          setTimeout(() => 
          {       
                    // AQUI SIGUE EL PROCESO DE ENROLLMENT - ESTO ESTA FUNCIONANDO CORRECTAMENTE 
                    // AQUI CREE UN SERVICIO QUE TOMA EL REQUEST Y TAMBIEN TOMA UN PARAMETRO call QUE ME DICE QUE TENGO QUE HACER
                    // EN ESTE CASO ME TOCA HACER UN enrollment
                   const updatedData = this.transactionenrollment();
                   let json = { chp : response.id , sop : updatedData , call : "enrollment"};
                   console.log("ESTE ES EL OBJETO QUE VA PARA EL ENROLLMENT", json);
                   this.webService.post(json, this.webService.HOST + "/sop").subscribe(enrollment =>{
                    console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
                    
                    if (enrollment.result.responseCodeDescription == "AUTHENTICATION_SUCCESSFUL") { // CASO FRICTION LESS PROBADO CORRECTAMENTE
                      //AQUI ES FRITCTION LESSS SE DEBE LANZAR EL COBRO DIRECTAMENTE
                      console.log("ESTE COBRO ES FRICTION LESS")
                      this.handleEnrollmentSuccess(enrollment.result);
                    } else if (enrollment.result.responseCodeDescription == "AUTHENTICATION_FAILED") { // CASO PROBADO - NO SE HACE NADA PORQUE EL ENROLLMENT FALLO
                      console.log("Unsuccessful Frictionless Authentication");
                      console.log("AUTHENTICATION_FAILED NO SE HACE NADA ESTO CUBRE EL TEST 2.2");
                      this.spinner.close();
                      return this.toast.showError("AUTHENTICATION_FAILED");
                    } else if (enrollment.result.responseCodeDescription == "PENDING_AUTHENTICATION") {
                      // ESTO ES LO QUE NECESITO QUE REVISES POR FAVOR 
                      // AQUI RETORNA EL STATUS CORRESPONDIENTE POR LO CUAL PROCEDE A REALIZAR LO QUE DEBE PERO NO MUESTRA EL IFRAME CON EL 3DS POR LO CUAL NO PUEDP AVANZAR
                      this.handlePendingAuthentication(enrollment.result);
                    } else {
                      console.log("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
                      this.spinner.close();
                      return this.toast.showError("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
                    }


                   }, err =>{
                    console.error("ERROR LLAMANDO A ENRONLLMENT: ", err);
                   });
          },
          10000);
          // timer(5000).pipe(
          //   switchMap(() => {
         
          //     console.log('REQUEST PARA ENROLLMENT :', updatedData);
          //     return this.call.post(updatedData, this.BACKENDURL + "EnrollmentAuth");
          //   })
        }else if(response.bank == 'emetec'){ // AQUI VA EL FLUJO PARA EL 2DO FORMULARIO QUE SE HIZO - EN ESTE CASO PARA EMETEC
          // ESTA ES LA IMPLEMENTACION PARA EMETEC
          // this.toast.showInfo("ESTE BANCO ES EMETEC");
          this.dataObject = data;
          this.chp = response.id;
          if(response.sopResponse.responseCodeDescription == "transaction pending"){ // CASOS PARA 3DS - SIN PODER PROBAR PORQUE LAS TARJETAS NO ME ARROJAN EL ESTATUS CORRESPONDIENTE 
            // PERO ESTO DBE SEGUIR EL FLUJO TAL CUAL 
            // Almacenar transactionId, iframeValue, iframeName, iframeUrl y redirectUrl de la respuesta
            this.transactionId = response.sopResponse.transactionId;
            this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
            this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
            this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
            this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;
            console.log('Datos almacenados:');
            console.log('transactionId:', this.transactionId);
            // Enviar solicitud POST usando fetch con no-cors para el primer iframe
            this.sendIframeRequest(this.iframeUrl, this.iframeValue).then(() => {
              console.log('Solicitud del primer iframe enviada con éxito');
              this.spinner.open(); // Detener el loader antes de abrir el popup
              
              // Abrir el segundo iframe en un popup
              this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
            }).catch(err => {
              console.error('Error al enviar la solicitud del primer iframe:', err);
              this.spinner.close();
            });
          }else{ // ESCENARIO PROBADO Y FUNCIONANDO CORRECTAMENTE
            // ESTE ESCENARIO RESPONDE SEGUN LO ESPERADO 
            this.spinner.close();
            console.log("NO RETORNO ESTATUS TRANSACTION PENDING PARA 3DS")
            console.log("USANDO EST DATA HACEMOS UN PAYMENT: ", data );
            const updatedData = this.transactionEmetec()
            updatedData.transactionIdentifier = response.sopResponse.transactionId;
            let json = { chp : response.id , sop : updatedData , call : "payment"};
            const voidJson = { chp : response.id, sop: updatedData, call: "void"};
            this.refundJson = { chp : response.id, sop: updatedData, call: "refund" };
            this.getEmetecPayment(json, voidJson);
          }


        }else{
          this.toast.showError("ESTE BANCO NO ESTA REGISTRADO")
        }
        
  
      }, err =>{
        console.log(err);
        this.spinner.close();
        this.toast.showError(err);
      });
  }

  myLoadEvent(habilitate : boolean){
    console.log("LOAD EVENT");
    
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/hasplan/" + this.id_process).subscribe(chp =>{
      // console.log("LOAD EVENT RESPONSE: ", chp);
      if(chp.result.status_customerPlan  != 4){
        if(chp.result.status_customerPlan == 1 ){

          this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
          if(habilitate){
            // console.log("SE SOLICITA HABILITAR EL PORTAL")
            this.activatePortal();
          }else{
            this.spinner.close();
            this.dialogRef.close(1);
            this.router.navigate(["/my-portals"]); 
          }
       
        }else{

          this.toast.showError("Su pago fue rechazado por el banco");
          this.spinner.close();
          timer(3000).subscribe(() => {
            this.dialogRef.close();
          });
    
        }

      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  activatePortal(){
    // this.spinner.open();
    const data = {id: this.configID, plan: this.onboardingPlan};
    // console.log(JSON.stringify(data, null, 2)); // Formato JSON con indentación de 2 espacios
    // console.log("A ESTA URL: " + this.webService.HOST + "/onboarding/habilitate")
    this.webService.post(data, this.webService.HOST + "/onboarding/habilitate").subscribe( response =>{
      this.spinner.close();
      this.dialogRef.close(1);
      this.toast.showInfo("Felicidades, su dominio ha sido registrado. En breve estara disponible...");
    }, err =>{
      this.spinner.close();
      this.dialogRef.close(1);
      this.toast.showError(err);
    });
  }

  // TODO ESTO ES NUEVO 

  addUniqueCodeToHead(uniqueCode: string) {
    const comment = this.renderer.createComment('script head nico');
    this.renderer.appendChild(this.document.head, comment);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(this.document.head, script);
    // Check if the script loaded correctly
    script.onload = () => {
      console.log('Script loaded successfully.');
    };

    script.onerror = () => {
      console.error('Failed to load the script.');
    };
  }

  addUniqueCodeToBody(uniqueCode: string) {
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setStyle(iframe, 'width', '100px');
    this.renderer.setStyle(iframe, 'height', '100px');
    this.renderer.setStyle(iframe, 'border', '0');
    this.renderer.setStyle(iframe, 'position', 'absolute');
    this.renderer.setStyle(iframe, 'top', '-5000px');
    this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }

  generateUniqueCode(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    console.log('Iniciando la creación del iframe');
  
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe creado:', iframe);
  
    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    document.body.appendChild(form);
    console.log('Formulario creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario enviado');
  
    iframe.onload = () => {
      iframe.style.display = 'block';
      console.log('Iframe loaded and displayed.');
    };
    const expectedOrigin = environment.production
  ? "https://centinelapi.cardinalcommerce.com"  // Producción
  : "https://centinelapistag.cardinalcommerce.com";  // Desarrollo

    window.addEventListener("message", (event) => {
      if (event.origin === expectedOrigin) {
        console.log('Mensaje recibido desde:', event.origin);
        let data = JSON.parse(event.data);
        console.log('Mensaje recibido:', data);
      }
    }, false);
  }
  

  private removeIframe() {
    if (this.iframeElement) {
      //this.spinner.close();
      document.body.removeChild(this.iframeElement);
      // this.iframeElement = null;
    }
  }


  transactionEmetec(){
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
      }
    };
  }

  transactionenrollment(additionalData: any = {}) {
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
        httpAcceptBrowserValue : this.httpAcceptBrowserValue,
        httpAcceptContent : this.httpAcceptContent,
        httpBrowserColorDepth : this.httpBrowserColorDepth,
        httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
        httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
        httpBrowserLanguage : this.httpBrowserLanguage,
        httpBrowserTimeDifference : this.httpBrowserTimeDifference,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  private handleEnrollmentSuccess(secondResponse: any) {
    // ESTO QUEDA TAL CUAL SE ESTABA MANEJANDO EN EL FORMULARIO QUE SE HIZO PARA CYBERSOURCE, TOMO LOS DATOS QUE NECESITO Y PROCEDO A REALIZAR UN SALE
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "Y") {
      console.log("Successful Frictionless Authentication");
      console.log("veresEnrolled y paresStatus son = 'Y' ESTO CUBRE EL TEST PARA 2.1 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "A") {
      console.log("Attempts Processing Frictionless Authentication");
      console.log("TEST 2.3 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "U") {
      console.log("Unavailable Frictionless Authentication");
      console.log("TEST 2.4 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "R") {
      console.log("Rejected Frictionless Authentication");
      console.log("TEST 2.5 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "U") {
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.6 DE LA DOCUMENTACION");
      console.log("O tambien")
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.7 DE LA DOCUMENTACION")
      console.log("O tambien")
      console.log("Time-Out");
      console.log("TEST 2.8 DE LA DOCUMENTACION")
      console.log("YA QUE TODOS TIENEN EL MISMO PARAMETRO veresEnrolled == 'U'")
    } else if (secondResponse.extraData.veresEnrolled == "B") {
      console.log("Bypassed Authentication");
      console.log("TEST 2.9 DE LA DOCUMENTACION");
    } else {
      console.log("ENROLLMENT NO RETORNO NINGUNO DE LOS veresEnrolled y paresStatus PREDETERMINADOS");
    }
    // TODO ESTO QUEDA TAL CUAL NO CAMBIA
    // REPITO : NO CAMBIA . TODOS ESTOS PARAMETROS SON ESENCIALES PARA EL PROCESO DE TOMA DE DECISIONES EN EL SALE PARA CYBERSOURCE
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId : secondResponse.extraData.authenticationTransactionId,
      acsTransactionId : secondResponse.extraData.acsTransactionId,
      ecommerceIndicator : secondResponse.extraData.ecommerceIndicator,
      specificationVersion : secondResponse.extraData.specificationVersion,
      directoryServerTransactionId : secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData : secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator : secondResponse.extraData.ucafCollectionIndicator
    });
    let json = { chp : this.chp , sop : fourthData , call : "sale"};
    console.log('REQUEST DIRECTO PORQUE ES FRICTION LESS', json);
    this.webService.post(json, this.webService.HOST + "/sop").subscribe(fourthResponse => {
      console.log('respuesta del SALE :', fourthResponse);
      // LUEGO DEL ENROLLMENTE PROCEDO A VALIDAR LA SUSCRIPCION Y A MOSTRAR EL MENSAJE CORRESPONDIENTE
      this.myLoadEvent(true);
      // this.spinner.close();
    });
  }

  private handlePendingAuthentication(secondResponse: any) {
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();
  
    // Asigna las variables jwtTokenForThird y transactionIdForThird correctamente.
    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode; // Asegúrate de que 'htmlCode' sea el valor correcto.
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
    console.log('Transaction ID para el 3DS:', this.transactionIdForThird); // Añadido para debugging
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
        console.log("Successful Step-Up Authentication");
        console.log("TEST 2.10 DE LA DOCUMENTACION");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
}

  
  //aqui
  createAndSubmitStepUpForm(jwtToken: string) {
    //this.spinner.close();
    console.log('Iniciando StepUp para 3DS');
    
    // Crear el iframe y aplicarle los estilos de centrado
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    iframe.style.position = 'fixed';
    iframe.style.top = '50%';
    iframe.style.left = '50%';
    iframe.style.transform = 'translate(-50%, -50%)'; // Centrar en la pantalla
    iframe.style.zIndex = '100011';
    iframe.style.background = 'white';
  
    // Añadir el iframe directamente al body
    document.body.appendChild(iframe);
    
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe para StepUp creado y centrado en la pantalla.');
  
    // Crear el formulario
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    if (environment.production) {
      // Producción
      form.action = 'https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp';
    } else {
      // Desarrollo
      form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
    }
    
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
    
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);
    
    document.body.appendChild(form);
    console.log('Formulario de StepUp creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario de StepUp enviado');
  
    iframe.onload = () => {
      console.log('Iframe StepUp loaded and displayed.');
    };
    
    const expectedOrigin = environment.production
  ? "https://centinelapi.cardinalcommerce.com"  // Producción
  : "https://centinelapistag.cardinalcommerce.com";  // Desarrollo

window.addEventListener(
  "message",
  (event) => {
    if (event.origin === expectedOrigin) {
      // console.log('Mensaje StepUp recibido desde:', event.origin);
      let data = JSON.parse(event.data);
      // console.log('Mensaje StepUp recibido:', data);

      if (data.success) {
        // this.spinner.open();
        if (this.iframeElement) {
          if (this.iframeElement.parentNode) {
            this.iframeElement.parentNode.removeChild(this.iframeElement); // Eliminar el iframe del DOM
          }
        }
      }
    }
  },
  false
);
  }
  
  //fin
  
  

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}) {
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
        httpAcceptBrowserValue : this.httpAcceptBrowserValue,
        httpAcceptContent : this.httpAcceptContent,
        httpBrowserColorDepth : this.httpBrowserColorDepth,
        httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
        httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
        httpBrowserLanguage : this.httpBrowserLanguage,
        httpBrowserTimeDifference : this.httpBrowserTimeDifference,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Crear el cuerpo de la solicitud en formato URL-encoded
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);

    console.log('Datos que se envían al iframe:');
    console.log('URL:', url);
    console.log('Datos del formulario:', body.toString());

    // Enviar la solicitud POST al iframeUrl
    try {
        let response: Response;
        let attempts = 0;
        const maxAttempts = 3;
        const delay = 200; // 0.5 segundos

        do {
            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
                mode: 'no-cors', // Añadir modo no-cors
                credentials: 'include'
            });

            if (response.status === 200) {
                console.log('Solicitud iframe enviada con éxito.');
                break;
            } else {
                console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
            }

            attempts++;
            if (attempts < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, delay)); // Esperar 0.5 segundos antes del siguiente intento
            }
        } while (attempts < maxAttempts);

        if (attempts === maxAttempts && response.status !== 200) {
            console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
        }
    } catch (error) {
        console.error('Error al enviar la solicitud del primer iframe:', error);
    }
}





// aqui cosas nuevas
// aqui cosas nuevas
openInteractiveIframePopup(url: string, iframeValue: string, transactionID: string): void {
  // Abre el diálogo utilizando AppDialogContainerComponent como contenedor
  this.spinner.close();
  const dialogRef = this.dialog.open(AppDialogContainerComponent, {
    width: '600px',
    panelClass: 'custom-dialog-zindex', // Aplica la clase CSS personalizada para el z-index si es necesario
    disableClose: true // Asegura que no se puede cerrar el diálogo haciendo clic fuera
  });

  // Crear el contenido del diálogo dinámicamente
  const iframeContainer = this.renderer.createElement('div');
  const iframe = this.renderer.createElement('iframe');
  const spinner = this.renderer.createElement('div'); // Spinner del loader
  const backdrop = this.renderer.createElement('div'); // Fondo (backdrop)

  // Configurar el fondo (backdrop) usando la clase CSS proporcionada
  this.renderer.addClass(backdrop, 'custom-backdrop');
  this.renderer.setStyle(backdrop, 'z-index', '9998'); // Asegura que el backdrop esté detrás del contenido del diálogo
  this.renderer.setStyle(backdrop, 'pointer-events', 'none'); // Previene clics en el fondo

  // Configurar elementos del iframe
  this.renderer.setAttribute(iframe, 'src', url);
  this.renderer.setAttribute(iframe, 'width', '100%');
  this.renderer.setAttribute(iframe, 'height', '400px');
  this.renderer.setAttribute(iframe, 'sandbox', 'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts');
  this.renderer.setStyle(iframe, 'z-index', '9999'); // Asegura que el iframe esté por encima del backdrop
  this.renderer.setStyle(iframe, 'position', 'relative'); // Hace que el iframe esté posicionado relativamente

  // Spinner config
  this.renderer.addClass(spinner, 'spinner');
  this.renderer.setStyle(spinner, 'position', 'absolute');
  this.renderer.setStyle(spinner, 'top', '50%');
  this.renderer.setStyle(spinner, 'left', '45%');
  this.renderer.setStyle(spinner, 'transform', 'translate(-50%, -50%)');
  this.renderer.setStyle(spinner, 'z-index', '100000'); // Asegura que el spinner esté por encima del iframe

  // Añadir el backdrop al contenedor del diálogo antes de los otros elementos
  this.renderer.appendChild(iframeContainer, backdrop); // Añadir el backdrop primero
  this.renderer.appendChild(iframeContainer, spinner); // Añadir el spinner al contenedor
  this.renderer.appendChild(iframeContainer, iframe); // Añadir el iframe después del spinner

  dialogRef.afterOpened().subscribe(() => {
    const dialogComponentInstance = dialogRef.componentInstance;
    if (dialogComponentInstance.dialogContent) {
      this.renderer.appendChild(dialogComponentInstance.dialogContent.nativeElement, iframeContainer);
    }
  });

  // Ocultar el spinner cuando el iframe haya terminado de cargar
  this.renderer.listen(iframe, 'load', () => {
    this.renderer.setStyle(spinner, 'display', 'none');
  });

  // Configurar los datos relevantes
  this.dataObject.transactionIdentifier = transactionID;

  // Añadir listener para recibir mensajes del iframe
  window.addEventListener('message', (event) => this.handleIframeMessage(event, dialogRef), false);
}

/**
 * Maneja los mensajes postMessage recibidos desde el iframe de cloud.billcentrix.com.
 * @param event 
 * @param dialogRef 
 */
private handleIframeMessage(event: MessageEvent, dialogRef: MatDialogRef<any>): void {
  let trustedOrigin = '';

  // Configura la URL confiable según el environment
  if (environment.production) {
    trustedOrigin = "https://cloud.billcentrix.com"; // URL de producción
  } else {
    trustedOrigin = "https://dev-onboarding-new.billcentric.com"; // URL de desarrollo
  }
// console.log(`Environment: ${environment.production ? 'Producción' : 'Desarrollo'}`);
//   console.log(`Trusted Origin: ${trustedOrigin}`);
  // Validar el origen del mensaje
  if (event.origin !== trustedOrigin) {
    console.warn('Mensaje recibido de un origen no confiable:', event.origin);
    return;
  }

  // Procesar el mensaje
  const { status, url } = event.data;
  if (status === 'loaded') {
    // Cerrar el loader si estaba abierto
    this.spinner.close();
  
    // Cerrar el diálogo después de recibir la respuesta de cloud.billcentrix.com
    this.onIframeLoadedSuccessfully(dialogRef);
  }
}

/**
 * 
 * @param dialogRef Referencia al diálogo que se cerrará.
 */
private onIframeLoadedSuccessfully(dialogRef: MatDialogRef<any>): void {
  console.log('Iframe cargado exitosamente, realizando acciones adicionales...');
  // Cerrar el diálogo
  dialogRef.close();
  
  const json = { chp: this.chp, sop: this.dataObject, call: "payment" };
  const voidJson = { chp: this.chp, sop: this.dataObject, call: "void"  };
  this.refundJson = { chp : this.chp, sop: this.dataObject, call: "refund" };
  this.getEmetecPayment(json, voidJson);
}

getEmetecPayment(json : any, voidJson: any){
    this.spinner.open();
    console.log("USANDO ESTA DATA HACEMOS EL Payment: ", json );
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      timer(3000).pipe(
          switchMap(() => this.webService.post(json, this.webService.HOST + "/sop")),
          takeWhile(() => this.retryCount <= this.maxRetries)
      ).subscribe(response => {
          console.log("RETRY RESPONSE PAYMENT: ", response);
          if (response.result.responseCodeDescription == "Transaction succeeded") {
            setTimeout(() => {
              this.spinner.close();
            }, 7000); 
              this.myLoadEvent(true);
          } else if (response.result.responseCodeDescription == "transaction pending") {
              // Continue retrying
              this.getEmetecPayment(json, voidJson);
          } else {
            setTimeout(() => {
              this.spinner.close();
            }, 2000); 
              this.toast.showError("Hubo un error en el proceso: " + response.result.responseCodeDescription);
            this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR


          }
      }, err => {
          console.error("ERROR: ", err);
        this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR

      });
  } else {
    this.spinner.close();
      this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR
      // this.toast.showError("Se alcanzó el número máximo de intentos. Transaccion sigue en estatus pending. contacte con el administrador");
  }
}

  emetecVoid(json: any): void {


    console.log("CON ESTO BUSCAMOS HACER EL VOID: ", JSON.stringify(json, null, 2));
    this.webService.post(json, `${this.webService.HOST}/sop`).subscribe(
        (response) => {
          console.log("VOID EXITOSO");
          this.spinner.close();
          // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
        },
        (err) => {
          console.error("Error realizando VOID:", err);
          this.spinner.close();
          // this.notification.showError(
          //   "Error realizando VOID de la transaccion, por favor contacte con el administrador"
          // );
          this.emetecRefund(); // COMO EL VOID DIO ERROR INTENTAMOS CON EL REFUND
        }
    );
  }

  emetecRefund() : void {
    this.webService.post(this.refundJson, `${this.webService.HOST}/sop`).subscribe(
        (response) => {
          console.log("REFUND EXITOSO");
          this.spinner.close();
          // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
        },
        (err) => {
          console.error("Error realizando REFUND:", err);
          this.spinner.close();
          // this.notification.showError(
          //     "Error realizando REFUND de la transaccion, por favor contacte con el administrador"
          // );
        }
    );
  }

  startPolling() {
    this.spinner.open();
    const url = this.webService.HOST + "/hasplan/" + this.id_process;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling), // Mantener el polling mientras la condición sea verdadera
      switchMap(() => this.http.get(url, { observe: 'response' }).pipe(
        catchError(error => {
          if (error.status === 404) {
            this.spinner.close();
            console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
            this.spinner.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {

      console.log("RESPUESTA",response)
      // NICO ESTA ES LA RESPUESTA QUE VARIA .. AQUI VAS A ESTAR BUSCANDO LO QUE DEJO ABAJO
      // MIENTRAS EL status_customerPlan SEA == 100 QUIERE DECIR QUE EL BANCO NO HA RESPONDIDO 
      // POR LO TANTO TIENES QUE SEGUIR INTENTANDO EL REQUEST HASTA QUE ESTO TE CAMBIE
      // ESTO NO ESTA TOTALMENTE PROBADO PORQUE NO HE PODIDO EJECUTAR BIEN EL 3DS NO ME MUESTRA EL IFRAME

      // Acceder al cuerpo de la respuesta utilizando response.body
      if (response && response.body) { // Verifica si 'response' y 'response.body' no son nulos
        console.log("RESPUESTA", response);
  
        const responseBody: any = response.body;
  
        if (responseBody.result && responseBody.result.status_customerPlan == 100) {
          console.log('Respuesta 200: OK');
          console.log('Datos:', responseBody);
          this.spinner.open();
          polling = false; // Detener el polling
          this.pollingSuccess.next(); // Emitir evento de éxito
          this.removeIframe(); // Eliminar el iframe
        }
      }

    });
  }


  // SE SUPONE QUE ESTA ES LA FUNCION QUE SE VA A EJECUTAR LUEGO DEL 3DS 
  // HACE EL VALIDATE Y EL SALE EN CASO DE QUE CORRESPONDA
  continueToNextStep() {
    this.spinner.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
      this.spinner.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    let json = { chp: this.chp, sop: thirdData, call: "validate" };
    this.spinner.open();
    console.log('REQUEST PARA VALIDATE AUTH', json);
    this.webService.post(json, this.webService.HOST + "/sop")
      .pipe(
        switchMap(thirdResponse => {
          console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
          if (thirdResponse && thirdResponse.result && thirdResponse.result.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
            console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
  
            const extraData = thirdResponse.result.extraData;
  
            const fourthData = this.createTransactionData(transactionIdentifier, {
              auth: {
                authenticationTransactionId: this.transactionIdForThird
              },
              eci: extraData.eci,
              eciRaw: extraData.eciRaw,
              cavv: extraData.cavv,
              authenticationTransactionId: extraData.authenticationTransactionId,
              acsTransactionId: extraData.acsTransactionId,
              paresStatus: extraData.paresStatus,
              veresEnrolled: extraData.veresEnrolled,
              xid: extraData.xid,
              ecommerceIndicator: extraData.ecommerceIndicator,
              specificationVersion: extraData.specificationVersion,
              directoryServerTransactionId: extraData.directoryServerTransactionId,
              ucafAuthenticationData: extraData.ucafAuthenticationData,  
              ucafCollectionIndicator: extraData.ucafCollectionIndicator
            });
  
            let saleJson = { chp: this.chp, sop: fourthData, call: "sale" };
            console.log('REQUEST PARA SALE', saleJson);
  
            return this.webService.post(saleJson, this.webService.HOST + "/sop");
          } else {
            console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
            this.spinner.close()
            return of(null);
          }
  
        }),
        catchError(error => {
          console.error('Error en la cadena de peticiones:', error);
          this.toast.showError(`Error en VALIDATE AUTH: ${error.message}`);
          this.spinner.close();
          return of(null);
        })
      )
      .subscribe(fourthResponse => {
        this.spinner.open()
        if (fourthResponse && fourthResponse.result) {
          this.spinner.close();
          console.log('Respuesta del SALE:', fourthResponse);
          if (fourthResponse.result.responseCodeDescription === 'AUTHORIZED') {  // Aquí puedes ajustar según la respuesta que recibas
            this.dialogRef.close(1);
            this.myLoadEvent(true);
          } else {
            this.toast.showError("El pago no se procesó correctamente. Por favor, intente nuevamente.");
          }
        } else {
          this.spinner.close();
          console.log('SALE no fue exitoso o no se ejecutó debido a un error previo.');
          this.toast.showError("No se obtuvo una respuesta del proceso de pago. Verifique la transacción o intente nuevamente.");
        }
        this.spinner.close();
      });
      
  }
  
  

}

// @Component({
//   selector: 'interactive-iframe-dialog',
//   template: `
//     <h1 mat-dialog-title>Complete la verificación</h1>
//     <div mat-dialog-content>
//       <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.url)" width="100%" height="400px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"></iframe>
//     </div>
//     <div mat-dialog-actions>
//       <button mat-button (click)="onClose()">Cerrar</button>
//     </div>
//   `,
//   styles: []
// })
// export class InteractiveIframeDialog {
//   constructor(
//     public dialogRef: MatDialogRef<InteractiveIframeDialog>,
//     @Inject(MAT_DIALOG_DATA) public dataconsult: { url: string },
//     public sanitizer: DomSanitizer
//   ) {}

//   onClose(): void {
//     this.dialogRef.close();
//     console.log("AQUI DEBERIA IR EL SALE")
//   }
// }
