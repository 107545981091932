<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button class="create-report-btn"><h1>+</h1></button>
                <img src="../../assets/icons/reportes_aqua.png" alt="icono reportes">
                <app-menu></app-menu>
            </div>
        </div>
        <div class="reports-data">
            <!-- <div class="second-headboard">
                <h1>Reportes</h1>
                <button>Gestión de clientes</button>
                <button>Facturación</button>
                <button>Reporte diario</button>
            </div> -->
            <!-- <mat-accordion>
                <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                    <mat-expansion-panel-header>
        
                        <button class="btn-icon btn"  title="Buscar" type="button">
                            <i class="material-icons">search</i>
                        </button>
                    </mat-expansion-panel-header>
            
                    <form>
                        <fieldset class="fieldset">
                               
                                <div fxLayout="row wrap">
                                    <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">
                                        <div class="pb-16">
                                           
                                            <mat-form-field class="full-width">
                                                <mat-label>Plan</mat-label>
                                                <mat-select [(value)]="selected" disabled="true" >
                                                    <mat-option *ngFor="let opt of plans" [value]="opt.name_plan">{{opt.name_plan}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                    </div>
        
                                    <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">
                                        <div class="pb-16">
                                            
                                             <mat-form-field class="full-width">
                                            <mat-label>Servicio</mat-label>
                                            <mat-select [(value)]="selectedService" (selectionChange)="onItemChange($event.value)">
                                                <mat-option *ngFor="let opt2 of services" [value]="opt2.name_service">{{opt2.name_service}}</mat-option>
                                            </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
    
                    
                                </div>
        
                        </fieldset>
                        <div fxLayout="row" fxLayoutAlign="end center" >
                            
                                <button
                                mat-raised-button
                                type="button"
                                color="warn"
                                class="ml-16 mr-16"
                                (click)="reset()"
                                >
                                <mat-icon>refresh</mat-icon> 
                                </button>
        
                                <button
                                mat-raised-button
                                type="button"
                                color="primary"
                                class="ml-16"
                                (click)="search()">
                                <mat-icon>search</mat-icon>
                                </button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion> -->

            <div style="padding-top: 2em;">
                <div class="title_planes"> <h1>Planes del cliente</h1></div>

                <mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();" >
<!-- 

                    <ng-container matColumnDef="select">
                        <th style="border: none;" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
                                [checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
                                [indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)">
                            </mat-checkbox>
                        </th>
                        <td style="border: none;" mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="tableService.selection.toggle(row)"
                                [checked]="tableService.selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container> -->
                    
        
                
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="id"> {{row.id}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="endDate_customerPlan">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="endDate_customerPlan"> {{row.endDate_customerPlan}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="contractNumber_Plan">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Plan </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="contractNumber_Plan"> {{row.contractNumber_Plan}} </mat-cell>
                        </ng-container>
                        
          
                        <ng-container matColumnDef="status_customerPlan">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="status_customerPlan"> {{row.show_status}} </mat-cell>
                        </ng-container>
          
                      <ng-container matColumnDef="actions">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="estatus"> 
                              <button mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                  <mat-icon class="text-muted">more_vert</mat-icon>
                                </button>
                              <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                    <button mat-menu-item (click)="update(row)" class="menu_report"><img src="../assets/icons/update.svg">  Actualizar </button>
                                    <button mat-menu-item (click)="openDialog(row)" class="menu_report"><img src="../assets/icons/toggle.svg">{{ row.status_customerPlan == 1 ? "Desactivar" : "Activar"}}  </button>
                              </mat-menu>
                          </mat-cell>
                        </ng-container>
        
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </mat-row>
                </mat-table>
                <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="list($event)">
                </mat-paginator>  
            </div>
            
        </div>
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
        </div>
    </div>
</body>