import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
// import { CustomerFilter } from './customer.filter';
import { ReportFilter } from './report.filter';
import { DatePipe } from '@angular/common';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';
import { AppConfirmService } from '../app-confirm/app-confirm.service';
import {CustomerFilter} from "../reports/customer.filter";

@Component({
  selector: 'app-customer-invoices',
  templateUrl: './customer-invoices.component.html',
  styleUrls: ['./customer-invoices.component.scss'],
  providers : [AppConfirmService,DatePipe]
})
export class CustomerInvoicesComponent implements OnInit  {


  // PARA MANEJO DE PANEL DE BUSQUEDA
  isOpenSearchPanel = false;
  filter = new ReportFilter(this.tableService.filter);
 
   // PARA MANEJO DE TABLAS
   @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
   @ViewChild(MatSort) sort: MatSort | undefined;
   displayedColumns: string[] = [ 'invoice', 'estatus', 'creada', 'plan' ,'portal','actions'];
   dataSource: any;
  items: any[] = [];
  partner: any;
  customer : any;

  extraFields: boolean = false;
  currency:string | undefined;

   constructor(
    public  tableService: TableService<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe : DatePipe,
    private confirmService: AppConfirmService,
    private webService : WebService,
    private spinner : SpinnerService,
    private toast : ToastService) {
      this.tableService = new TableService;
    }

    ngOnInit(): void {
      this.isOpenSearchPanel = true;
      this.activatedRoute.params.subscribe(params =>{

        this.customer = params["id"];
        this.list();
        
      }, err =>{
        this.toast.showError(err)
      })
     
    
    }

    list(event?: PageEvent) {

      this.spinner.open();
      // , 'customer','centro'
      this.displayedColumns = [ 'invoice', 'estatus', 'creada','plan' ,'portal','actions'];
      this.webService.get(this.webService.HOST + "/config/key/currency_default").subscribe(config =>{
        this.currency = config.value_config;
      });  
      //  118 
      this.filter.company = +JSON.parse(localStorage.getItem('sesion') || '{}').partner ;
      this.filter.customer = +this.customer;
      this.items = [];
      if(Object.keys(this.filter).length > 0){ // Para realizar busquedas solo si hay objetos en el filtro


        this.filter.since = this.datePipe.transform(this.filter.since, 'yyyy-MM-dd');
        this.filter.until = this.datePipe.transform(this.filter.until, 'yyyy-MM-dd');

        // @ts-ignore
        var since = new Date(this.filter.since);
        // @ts-ignore
        var until = new Date(this.filter.until);

        if (until.getTime() < since.getTime()) {
          this.spinner.close();
          this.toast.showError("Fecha: Hasta. NO debe ser anterior a fecha : Desde");
          return;
        }
        this.tableService.filter = new ReportFilter(this.filter);

        let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
            {pageIndex: event ? event.pageIndex  : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

        httpParams = this.filter.getHttpParams(httpParams);

        this.webService.get(this.webService.HOST + "/partner/-1/customer/" + this.customer + "/invoice",httpParams)
        .subscribe(params => {
          console.log(params);
            this.items = params['result']; // items que mostrara la tabla
            if (this.items.length === 0) {
              this.dataSource = [];
              this.spinner.close();
              this.toast.showInfo('Cliente NO posee facturas asociadas');
              return;
            }

            for (let item of this.items) {
              var created = new Date(item.createdAt);
              item.createdAt = this.datePipe.transform(new Date(created.setHours(created.getHours() - 5)), 'yyyy-MM-dd HH:mm:ss');
            }

            this.dataSource = new MatTableDataSource<any>(this.items);
            this.tableService.pager = params['pager'];
            this.tableService.selection.clear();

            this.spinner.close();


          }, err => {
           this.spinner.close();
            this.toast.showError(err);
            console.log(err);
        });
      }else{
        console.log("Hola2")
      }
  }

  openPopUp(data: any = {}) {
    this.confirmService.confirm({title: 'Confirmación de reembolso', message: "¿Está seguro de realizar el reembolso de la factura seleccionada?"})
      .subscribe(res => {
        if (res) {
         return this.sendInvoiceToRefund(data);
        }
      });
    }

    sendInvoiceToRefund(data : any){
      this.spinner.open();
      let obj = {id:data.id}
      this.webService.post(obj,this.webService.HOST + "/invoice/refund").subscribe(response =>{
        this.spinner.close();
        this.toast.showSuccess("Reembolso procesado correctamente");
        this.ngOnInit();

      }, err=>{
        console.log(err);
        this.toast.showError(err);
        this.spinner.close();
      });
    }

    goBack(){
      this.router.navigate(["/reports"]); 
    }

  read(item: any) { // LEER
    this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
  }

  openSearchPanel(value: boolean) {
    this.isOpenSearchPanel = value;
    this.filter = new ReportFilter(this.filter);
  }

  search(){
    this.tableService.filter = new ReportFilter(this.filter);
    this.router.navigate([], { queryParams: {  } });
    this.list();
  }

  reset() {
    this.filter = new ReportFilter();
    this.tableService.filter = null;
    this.dataSource = [];
    // delete this.filter.since;
    // delete this.filter.until;
    this.search();
  }
    

}
