import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginOnboardComponent } from './login-onboard/login-onboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PreferencesComponent } from './account-settings/preferences/preferences.component';
import { PlansComponent } from './account-settings/plans/plans.component';
import { MyPlansComponent } from './account-settings/plans/my-plans/my-plans.component';
import { PortalComponent } from './account-settings/portal/portal.component';
import { MyPortalsComponent } from './account-settings/portal/my-portals/my-portals.component';
import { PortalCustomizationComponent } from './account-settings/portal-customization/portal-customization.component';
import { FAQsComponent } from './faqs/faqs.component';
import { FAQsHoversComponent } from './faqs-hovers/faqs-hovers.component';
import { ReportsComponent } from './reports/reports.component';
import { BasicComponent } from './plans-preview/basic/basic.component';
import { PracticalComponent } from './plans-preview/practical/practical.component';
import { ContempoComponent } from './plans-preview/contempo/contempo.component';
import { UpdatePortalComponent } from './account-settings/update-portal/update-portal.component';
import { UpdatePortalTemplatesComponent } from './account-settings/update-portal-templates/update-portal-template.component';
import { UpdatePortalFeaturesComponent } from './account-settings/update-portal-features/update-portal-features.component';
import { UpdatePlanComponent } from './account-settings/update-portal-plan/update-plan/update-plan.component';
import { CreatePlanComponent } from './account-settings/update-portal-plan/create-plan/create-plan.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UnauthorizedStepComponent } from './unauthorized-step/unauthorized-step.component';
import { NotFoundComponent } from './not found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CongratulationComponent } from './congratulation/congratulation.component';
import { ChangeTemplateComponent } from './change-template/change-template.component';
import { CustomerPlansComponent } from './customer-plans/customer-plans.component';
import { CustomerCardsComponent } from './customer-cards/customer-cards.component';
import { CustomerInvoicesComponent } from './customer-invoices/customer-invoices.component';
import { CustomerUpdateComponent } from './customer-update/customer-update.component';
import { CustomerPlanUpdateComponent } from './customer-plans/customer-plan-update/customer-plan-update.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import { CheckoutComponent } from './partials/checkout/checkout.component';
import { NavbarcheckoutComponent } from './partials/navbar/navbar.component';
import { DashboardComponent } from './partials/dashboard/dashboard.component';
import { ContentDashboardComponent } from './partials/content-dashboard/content-dashboard.component';
import { SuscriptionComponent } from './partials/mis-suscripciones/suscription/suscription.component';
import { DireccionesComponent } from './partials/direcciones/direcciones.component';
import { CreditCardComponent } from './partials/credit-card/credit-card.component';
import { ChangePassComponent } from './partials/change-pass/change-pass.component';
import { LoginpreviewComponent } from './partials/login/login.component';
import { LoginComponent } from './login/login.component';
import { AddClientComponent } from './add-client/add-client.component';
import { CustomerAddressUpdateComponent } from './customer-address-update/customer-address-update.component';
import { ConfigurationsComponent } from './account-settings/configurations/configurations.component';
import { ProcessingComponent } from './processing/processing.component';
import {HistoricReportComponent} from "./historic-report/historic-report.component";


const routes: Routes = [
  { path: "", component: WelcomeComponent },
  { path: "onboard", canActivate: [AuthGuard], component: LoginOnboardComponent },
  { path:"login", component:LoginComponent},
  { path: 'create', component: CreateAccountComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'edit/portal/:id',  canActivate: [AuthGuard], component: UpdatePortalComponent},
  { path: 'edit/portal/:id/my-plans',  canActivate: [AuthGuard] , component: MyPlansComponent },
  { path: 'edit/portal/:id/portal',  canActivate: [AuthGuard] , component: UpdatePortalTemplatesComponent },
  { path: 'edit/portal/:id/features',  canActivate: [AuthGuard] , component: UpdatePortalFeaturesComponent },
  { path: 'edit/portal/:id/my-plans/update/:plan',  canActivate: [AuthGuard] , component: UpdatePlanComponent },
  { path: 'edit/portal/:id/my-plans/create',  canActivate: [AuthGuard] , component: CreatePlanComponent },
  { path: "settings",canActivate: [AuthGuard], component: AccountSettingsComponent,data: { step: 1} }, //step 1
  { path: "portal", canActivate: [AuthGuard] ,  component: PortalComponent , data: { step: 3} }, //step 4
  { path: "personalize",canActivate: [AuthGuard] ,  component: PortalCustomizationComponent,data: { step: 4}}, //step 3
  { path: "plans", canActivate: [AuthGuard], component: PlansComponent, data: { step: 2} }, //step 4
  { path: "my-plans", canActivate: [AuthGuard] , component: MyPlansComponent},
  { path: "my-portals", canActivate: [AuthGuard] , component: MyPortalsComponent },
  { path: "preferences",canActivate: [AuthGuard] ,  component: PreferencesComponent}, 
  { path: "configurations",canActivate: [AuthGuard] ,  component: ConfigurationsComponent}, 
  { path: "faqs",canActivate: [AuthGuard] ,  component: FAQsComponent },  
  { path: "faqs-hover", canActivate: [AuthGuard] , component: FAQsHoversComponent },
  { path: "reports",canActivate: [AuthGuard] ,  component: ReportsComponent },
  { path: "reports/:id/plans",canActivate: [AuthGuard] ,  component: CustomerPlansComponent },
  { path: "reports/:id/plans/:plan/update",canActivate: [AuthGuard] ,  component: CustomerPlanUpdateComponent },
  { path: "reports/:id/cards",canActivate: [AuthGuard] ,  component: CustomerCardsComponent },
  { path: "reports/:id/invoices",canActivate: [AuthGuard] ,  component: CustomerInvoicesComponent },
  { path: "reports/:id/invoices/:invoice",canActivate: [AuthGuard] ,  component: CustomerInvoiceDetailComponent },
  { path: "reports/:id/update",canActivate: [AuthGuard] ,  component: CustomerUpdateComponent },
  { path: "reports/:id/update/address/:address",canActivate: [AuthGuard] ,  component: CustomerAddressUpdateComponent },
  { path: "historic",canActivate: [AuthGuard] ,  component: HistoricReportComponent },
  { path: "basic", component: BasicComponent },
  { path: "practic",canActivate: [AuthGuard] ,  component: PracticalComponent },
  { path: "contempo", canActivate: [AuthGuard] , component: ContempoComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'unauthorized-step', component: UnauthorizedStepComponent },
  { path: 'congratulation', component:CongratulationComponent},
  { path: 'change/:id', component:ChangeTemplateComponent},
  {path: 'processing', component:ProcessingComponent},
  { path: 'checkout', component:CheckoutComponent},
  { path: "portal/:id/client",canActivate: [AuthGuard], component: AddClientComponent },
  {path:'loginpreview', component:LoginpreviewComponent},
  { path: 'edit/portal/:id/features/create', canActivate: [AuthGuard], component: CreatePlanComponent },
  {
    path: "dashboard",
    canActivate:  [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'dashboard-content', pathMatch: 'full' }, 
      { path: 'tarjetas', component: CreditCardComponent },
      { path: 'dashboard-content', component:ContentDashboardComponent},
      { path: 'suscripciones', component:SuscriptionComponent},
      { path: 'direcciones', component:DireccionesComponent},
      { path: 'password', component:ChangePassComponent},

      // Otras rutas hijas del dashboard aquí si es necesario
    ],
  },


  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }