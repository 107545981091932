<div class="popup">
    <div class="popup-header">
        <h1 class="title" matDialogTitle>Agregar tarjeta</h1>
        <button mat-icon-button class="close-button" (click)="dialogRef.close(false)">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <form [formGroup]="itemForm" (ngSubmit)="submit()" class="form_edit_card">
        <div class="edit_card grid">
            <div class="items">
                <label for="nameHolderCreditCard">Nombre del Tarjetahabiente*</label>
                <div class="input-container">
                    <input 
                        matInput 
                        id="nameHolderCreditCard" 
                        name="nameHolderCreditCard"
                        [formControl]="itemForm.controls['nameHolderCreditCard']" 
                        type="text"
                        placeholder="Nombre">
                </div>
                <small *ngIf="itemForm.controls['nameHolderCreditCard'].hasError('required') && itemForm.controls['nameHolderCreditCard'].touched" 
                       class="form-error-msg"> Nombre es requerido
                </small>
            </div>

            <div class="items">
                <label for="numberCreditCard">Número de Tarjeta*</label>
                <div class="input-container">
                    <input 
                        matInput 
                        id="numberCreditCard" 
                        name="numberCreditCard"
                        [formControl]="itemForm.controls['numberCreditCard']" 
                        type="tel"
                        placeholder="xxxx-xxxx-xxxx-xxxx"
                        maxlength="19"
                        pattern="[0-9\-]*"
                        (keypress)="onlyNumberKey($event)"
                        (input)="formatCreditCardNumber()"
                    />
                </div>
                <small *ngIf="itemForm.controls['numberCreditCard'].hasError('required') && itemForm.controls['numberCreditCard'].touched" 
                       class="form-error-msg"> Número es requerido
                </small>
                <small *ngIf="itemForm.controls['numberCreditCard'].hasError('invalidLength') && itemForm.controls['numberCreditCard'].touched" 
                       class="form-error-msg"> El número de tarjeta debe tener 15 o 16 dígitos
                </small>
                <small *ngIf="itemForm.controls['numberCreditCard'].hasError('invalidCardNumber') && itemForm.controls['numberCreditCard'].touched" 
                       class="form-error-msg"> Número de tarjeta inválido
                </small>
            </div>

            <div class="items">
                <label for="expMonthCreditCard">Mes de Expiración*</label>
                <div class="select-wrapper">
                    <select 
                        id="expMonthCreditCard"
                        name="expMonthCreditCard"
                        [formControl]="itemForm.controls['expMonthCreditCard']">
                        <option value="" selected disabled>Selecciona un mes</option>
                        <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
                    </select>
                </div>
                <small *ngIf="itemForm.controls['expMonthCreditCard'].hasError('required') && itemForm.controls['expMonthCreditCard'].touched" 
                       class="form-error-msg"> Mes es requerido
                </small>
            </div>

            <div class="items">
                <label for="expYearCreditCard">Año de Expiración*</label>
                <div class="select-wrapper">
                    <select 
                        id="expYearCreditCard"
                        name="expYearCreditCard"
                        [formControl]="itemForm.controls['expYearCreditCard']">
                        <option value="" selected disabled>Selecciona un año</option>
                        <option *ngFor="let year of years" [value]="year.value">{{ year.label }}</option>
                    </select>
                </div>
                <small *ngIf="itemForm.controls['expYearCreditCard'].hasError('required') && itemForm.controls['expYearCreditCard'].touched" 
                       class="form-error-msg"> Año es requerido
                </small>
            </div>
        </div>

        <div fxFlex="100" class="button-container">
            <button mat-raised-button color="primary" [disabled]="itemForm.invalid" class="save">
                Agregar tarjeta
            </button>
        </div>
    </form>
</div>
