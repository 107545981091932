<body>
   
    <div [ngClass]="{
        'main-content': selectedPalette === 'Billcentrix',
        'main-content-modern': selectedPalette === 'Moderna',
        'main-content-violet': selectedPalette === 'Violeta'
      }">
      <app-header></app-header>
        <div class="main-image">
            <div class="text">
                <h1>Revive Tus Mañanas con nuestro Café Premium</h1>
                <p>Calidad inigualable en cada taza. Suscribete ahora.</p>
            </div>
        </div>
        <div class="plans">
            <h1>Tenemos un plan especialmente para ti</h1>
            <p>PRECIOS</p>
            <app-plan-practical ></app-plan-practical>
        </div>
        <div class="background-banner">
            <div class="banner-content">
                <div class="text">
                    <h1>Aroma en cada taza</h1>
                    <p>Nuestro café, vestido de colores vivos, invita a una experiencia única. Ven, siente el calor de la tradición en cada taza.</p>
                </div>
                <div class="white-image">
                    
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="left">
                <p>Copyright © 2022 Billcentrix. All Rights Reserved.</p>
            </div>
           
        </div>
    </div>
    <!-- <div class="options-buttons">
        <button class="back-btn">Atrás</button>
        <button class="continue-btn">Seleccionar</button>
    </div> -->
</body>