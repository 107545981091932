<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <!-- <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix"> -->
                <a  href="/my-portals">
                    <img src="../../assets/images/BRAND2.png"  alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <button (click)="openPopUp({}, true)" >Crear Tarjeta</button>
                <app-menu></app-menu>
            </div>
        </div>
        <div class="reports-data">

            <div style="padding-top: 2em;">
                <mat-table [dataSource]="dataSource" matSort  >
                                 
                    <ng-container matColumnDef="maskCreditCard">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Número de tarjeta </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="maskCreditCard"> {{row.maskCreditCard}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="expMonthCreditCard">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor</mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="expMonthCreditCard"> {{row.expMonthCreditCard}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="expMonth">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Mes de vencimiento </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="expMonth"> {{row.expMonth}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="expYearCreditCard">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Año de vencimiento </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="expYearCreditCard"> {{row.expYearCreditCard}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="nameHolderCreditCard">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="nameHolderCreditCard"> {{row.nameHolderCreditCard}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="createdAt">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="createdAt"> {{row.createdAt}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="estatus"> 
                              <button mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                  <mat-icon class="text-muted">more_vert</mat-icon>
                                </button>
                              <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                  <!--button mat-menu-item (click)="openPopUp(row)"  class="menu_report"><img src="../assets/icons/update.svg">  Actualizar </button-->
                                  <button mat-menu-item (click)="deleteItem(row)"  class="menu_report"><img src="../assets/icons/toggle.svg">  Eliminar </button>
                                  <!-- <button *ngIf="hasPermission('tdc.delete')" mat-menu-item (click)="deleteItem(row)"><mat-icon>delete</mat-icon> Cambiar estado  </button> -->
                              </mat-menu>
                          </mat-cell>
                        </ng-container>
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </mat-row>
                </mat-table>
                <!-- <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="list($event)">
                </mat-paginator>   -->
            </div>
            
        </div>

        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
        </div>
        
    </div>
</body>