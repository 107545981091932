import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
import { DatePipe } from '@angular/common';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';

@Component({
  selector: 'app-customer-invoice-detail',
  templateUrl: './customer-invoice-detail.component.html',
  styleUrls: ['./customer-invoice-detail.component.scss'],
  providers: [DatePipe]
})
export class CustomerInvoiceDetailComponent implements OnInit {

  invoice : any;
  items: any[] = [];
  dataSource: any;
  dataCuponSource: any;
  cupons: any[] = [];
  currency : any = null;
  displayedColumns: string[] = [ 'id', 'description_payment', 'resultStatus_payment', 'dateCreated_payment','transactionId','authorizationNumber','transactionRefundId','amount_payment'];
  displayedCuponColumns: string[] = [ 'cupon.id', 'cupon.name_cupon', 'cupon.value', 'cupon.type', 'cupon.status','cupon.createdAt'];

  constructor(
    public  tableService: TableService<any>,
    private activatedRoute: ActivatedRoute,
    private datePipe : DatePipe,
    public tableCuponService: TableService<any>,
    private router: Router,
    private cdr: ChangeDetectorRef,private webService : WebService,private spinner : SpinnerService,private toast : ToastService) {
      this.tableService = new TableService;
    }

    ngOnInit(): void {
      this.spinner.open()
      this.activatedRoute.params.subscribe( params =>{
        this.webService.get( this.webService.HOST + "/invoice/" +params["invoice"]).subscribe(invoice => {  // BUSCAMOS LA FACTURA
          this.invoice = invoice.result;

          var created = new Date(this.invoice.createdAt);
          var updated = new Date(this.invoice.updatedAt);

          this.invoice.dateCreated_invoice = this.datePipe.transform(new Date(created.setHours(created.getHours() - 5)), 'yyyy-MM-dd HH:mm:ss');
          this.invoice.updatedAt = this.datePipe.transform( new Date(updated.setHours(updated.getHours() - 5)), 'yyyy-MM-dd HH:mm:ss');
          
         
          this.currency = this.invoice?.currencyBase?.codeCurrency;
            this.webService.get(this.webService.HOST + "/invoice/status/" + this.invoice.status_invoice).subscribe(status =>{ // BUSCAMOS SU ESTATUS
              this.invoice.status_invoice = status.result.description;
              this.list();
                  this.webService.get(this.webService.HOST + "/cupon/invoice/" + this.invoice.id,this.webService.buildRequestParams()).subscribe(cupons=>{
                    this.spinner.open();
                    this.cupons = cupons['result'];
                    for(const c of this.cupons){
                      c.cupon.status = c.cupon.status === 1 ? 'Activo' : 'Inactivo';
                      c.cupon.type = c.cupon.type === 1 ? '%' : '$';
                    }
                    this.dataCuponSource = new MatTableDataSource<any>(this.cupons);
                    this.tableCuponService.pager = cupons['pager'];
                    this.tableCuponService.selection.clear();
                    this.spinner.close();
            
              }, err =>{
                this.toast.showError(err);
                console.log(err);
                this.spinner.close();
              })
            }, err =>{
              this.toast.showError(err);
              console.log(err);
              this.spinner.close();
            });
          }, err=>{
            this.toast.showError(err);
            console.log(err);
            this.spinner.close();
          });


      }, err =>{
        this.toast.showError(err);
        console.log(err);
      })
    }

    list(event?: PageEvent) {
      this.spinner.open()
      let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
      {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

      this.webService.get(this.webService.HOST + "/payment/list/" + this.invoice.id ,httpParams).subscribe(params => {
        this.items = params['result'];
        console.log(this.items)
        for(let p of this.items){ // BUSCAMOS LA DESCRIPCION DEL CODIGO DE LOS PAYMENTS
          console.log(p);
              p.resultStatus_payment = p.resultStatus_payment != null ? p.resultStatus_payment : "N/A"
              p.transactionRefundId = p.transactionRefundId != null ? p.transactionRefundId : "N/A"
              p.description_payment = p.result_description != null ? p.result_description : p.description_payment;
              var created = new Date(p.dateCreated_payment);
              p.dateCreated_payment = this.datePipe.transform(new Date(created.setHours(created.getHours() - 5)), 'yyyy-MM-dd HH:mm:ss');
        }
        this.spinner.close();
            this.dataSource = new MatTableDataSource<any>(this.items);
            this.tableService.pager = params['pager'];
            this.tableService.selection.clear();
          }, err => {
            this.spinner.close();
            this.toast.showError(err);
            console.log(err);
          });
      }

    goBack(){
      this.router.navigate(["/reports"]); 
    }

    listCupon(event?: PageEvent) {
      console.log("Hi!!!")
    }

}
