import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from "../plans/plan.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-update-portal-template',
  templateUrl: './update-portal-template.component.html',
  styleUrls: ['./update-portal-template.component.scss']
})
export class UpdatePortalTemplatesComponent implements OnInit {
  checkboxBasicValue = false;
  checkboxPracticalValue = false;
  checkboxContempoValue = false;
  exist = false;
  onboardingConfigID : any;
  checked : any;
  tmpChecked : any;
  fromUpdate : Boolean = false;
  public myplans : any = [];
  showPaymentButton : Boolean = false;

  constructor(private planService : PlanService,private activateRoute : ActivatedRoute,private router : Router,private location : Location,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }

  ngOnInit(): void {

    this.activateRoute.queryParams.subscribe( queryParams =>{

      if(queryParams['update']){
        this.fromUpdate = true;
      }
      this.activateRoute.params.subscribe( params =>{
        this.onboardingConfigID = params['id'];
        this.getConfigByID();
      }, err =>{
        this.toast.showError(err);
      });

    }, err =>{
      this.toast.showError(err);
    })
    
    
  }

  getConfigByID(){

    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    // BUSCAMOS TODOS LOS BANCOS
    let partner = -1;
    if(sesion.partner != null || sesion.partner != undefined){
      partner = sesion.partner
    }
   
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID + "?partner=" + partner).subscribe( config =>{
      this.spinner.close();
      if(environment.production){
        if(config.result.url_template === "https://practic.billcentrix.com"){
          this.checked = 2;
          this.checkboxPracticalValue = true;
        }else if (config.result.url_template === "https://contempo.billcentrix.com"){
          this.checked = 1;
          this.checkboxContempoValue = true;
        }else{ // config.result.url_template === https://dev.onboarding.basic.billcentric.com
          this.checked = 3;
          this.checkboxBasicValue = true;
        }
      }else{
        if(config.result.url_template === "https://dev.onboarding.practic.billcentric.com"){
          this.checked = 2;
          this.checkboxPracticalValue = true;
        }else if (config.result.url_template === "https://dev.onboarding.contempo.billcentric.com"){
          this.checked = 1;
          this.checkboxContempoValue = true;
        }else{ // config.result.url_template === https://dev.onboarding.basic.billcentric.com
          this.checked = 3;
          this.checkboxBasicValue = true;
        }
      }
      
// if(config.result.service.current_onboarding_step >= 5){
  if(config.result.customerHasPlan != null ){
    if(config.result.customerHasPlan.status_customerPlan == 1){
      console.log('Status of customerHasPlan:', config.result.customerHasPlan.status_customerPlan);
      var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
      var today = new Date();
      var isValidDate = nextBillDate > today;
      if(!isValidDate){
        this.showPaymentButton = true;
      }
    }else{
      this.showPaymentButton = true;
    }
    // if(config.result.customerHasPlan.status_customerPlan != 1){
    //   this.showPaymentButton = true;
    // }

  }else{
    this.showPaymentButton = true;
  }
// }
this.planService.plans.emit(config.result.plans)

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
      console.log("AQUI")
    });
  }

  selectTemplate(templateNumber: number) {
    this.checked = templateNumber;
  
    if (templateNumber === 1) {
      this.checkboxContempoValue = true;
      this.checkboxPracticalValue = false;
      this.checkboxBasicValue = false;
    } else if (templateNumber === 2) {
      this.checkboxContempoValue = false;
      this.checkboxPracticalValue = true;
      this.checkboxBasicValue = false;
    } else if (templateNumber === 3) {
      this.checkboxContempoValue = false;
      this.checkboxPracticalValue = false;
      this.checkboxBasicValue = true;
    }
  }

  saveTemplate(){
    if(this.checked == undefined){
      this.toast.showError("Debes seleccionar un template para continuar");
    }

    this.spinner.open();
    let json = { partner : JSON.parse(localStorage.getItem('sesion')!).partner, config : this.onboardingConfigID , template : this.checked};
    this.webService.put(json,this.webService.HOST + "/onboarding/step2").subscribe( response =>{
        this.spinner.close();

        this.toast.showSuccess("Template actualizado correctamente, en unos minutos podra visualizarlo correctamente en su portal");

        if(!this.fromUpdate){
          this.goToFeatures();
        }

      }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    )
  }

  goBack(){
    this.router.navigate([".."], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  handleCheckboxChange(checkboxType: string) {
    if (checkboxType === 'basic') {
      this.checkboxPracticalValue = false;
      this.checkboxContempoValue = false;
    } else if (checkboxType === 'practical') {
      this.checkboxBasicValue = false;
      this.checkboxContempoValue = false;
    } else if (checkboxType === 'contempo') {
      this.checkboxBasicValue = false;
      this.checkboxPracticalValue = false;
    }
  }

  // handleCreatePlanClick() {
  //   this.router.navigateByUrl('/plans');
  // }  

  goToPlans(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/my-plans']); 
  }

  goToEdit(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID]);
  }

  goToFeatures(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/features']); 
  }
  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }


}
