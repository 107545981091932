<div class="content-plan">
  <div class="content-form">

    <div>
      <button (click)="dialogRef.close()" class="btn-default btn"   style="float: right; background : #15448f; color: white;">X</button>
    </div>

    <div class="rendered-form form-pay">
<div class="description-payment">
  <p style="text-align:left">Estamos listos para que empiece a utilizar Billcentrix su plataforma de suscripciones! Para activarla, simplemente ingrese los detalles de su tarjeta de crédito. Se realizará un cargo mensual de ${{ feeCostPlan }} y podrá comenzar a inscribir a sus clientes en su servicio utilizando tarjetas de crédito. Si optó por crear un portal de afiliación web, recibirá la dirección (url) final en su correo electrónico.</p>

 <b>Beneficios de utilizar Billcentrix:</b> 
  
 <ul>
  <li> <mat-icon class="check">check_circle</mat-icon>Plataforma de cobros recurrentes en línea.</li>
  
  <li><mat-icon class="check">check_circle</mat-icon>Reintentos automáticos de cargos fallidos.</li>
  
  <li><mat-icon class="check">check_circle</mat-icon>Comunicación automática con sus clientes via whatsapp e email en caso de fallo en la tarjeta de crédito.</li>
  
 <li><mat-icon class="check">check_circle</mat-icon>(Opcional) Activación de un portal web para que sus clientes se inscriban en línea y gestionen sus suscripciones.</li> 
  
 <li><mat-icon class="check">check_circle</mat-icon>Reportes diarios automáticos por correo electrónico.</li>
</ul> 
 
</div>

<form [formGroup]="form" (ngSubmit)="send()">
  <h2>Ultimo Paso, Realiza el pago</h2>
  <p>¡Active su cuenta hoy ingresando los datos de su tarjeta de crédito! 
    (usted podrá desactivar su servicio cuando desee).
  </p>
  
  <!-- Nombre del tarjetahabiente -->
  <div class="form-group field-tc full-width">
    <label for="nombreTitular">Nombre del tarjetahabiente</label>
    <small *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched" class="form-error-msg"> **Requerido </small>
    <input [formControl]="form.controls['nameHolderCreditCard']" type="text" placeholder="Nombre del tarjetahabiente" class="form-control" name="cc-name" access="false" id="nombreTitular" required="required" aria-required="true" autocomplete="cc-name">
  </div>
  
  <!-- Número de Tarjeta -->
  <div class="two-column">
    <div class="form-group full-width">
      <label for="Numero-de-Tarjeta">Número de Tarjeta</label>
      <small *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched" class="form-error-msg"> **Requerido </small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('pattern') && form.controls['numberCreditCard'].touched" class="form-error-msg"> **Solo se permiten números</small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidLength') && form.controls['numberCreditCard'].touched" class="form-error-msg"> **Debe tener exactamente 16 dígitos</small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidCardNumber') && form.controls['numberCreditCard'].touched" class="form-error-msg"> **Número de tarjeta no válido</small>
      <img style="height: 20px;" src="../assets/images/visa-mastercard.png" alt="">
      <input 
        (keypress)="onlyNumberKey($event)" 
        (input)="formatCreditCardNumber()" 
        [formControl]="form.controls['numberCreditCard']" 
        type="text" 
        placeholder="Número de Tarjeta" 
        class="form-control" 
        name="cc-number" 
        access="false" 
        id="Numero-de-Tarjeta" 
        required="required" 
        aria-required="true" 
        autocomplete="cc-number">
    </div>

    <!-- CCV -->
    <div class="form-group full-width">
      <label for="CCV">CCV</label>
      <small *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched" class="form-error-msg"> **Requerido</small>
      <small *ngIf="form.controls['ccv'].hasError('pattern') && form.controls['ccv'].touched" class="form-error-msg"> **Solo se permiten números</small>
      <small *ngIf="form.controls['ccv'].hasError('maxlength') && form.controls['ccv'].touched" class="form-error-msg"> **Debe tener un máximo de 4 dígitos</small>
      <input 
        (keypress)="onlyNumberKey($event)" 
        [formControl]="form.controls['ccv']" 
        type="password" 
        maxlength="4"
        placeholder="CCV" 
        class="form-control" 
        name="cc-csc" 
        autocomplete="cc-csc" 
        access="false" 
        id="CCV">
    </div>

    <!-- Mes de Expiración -->
    <div class="form-group full-width">
      <label for="mes-exp">Mes de expiración</label>
      <small *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched" class="form-error-msg"> **Requerido</small>
      <select [formControl]="form.controls['expMonthCreditCard']" class="form-control" name="cc-exp-month" autocomplete="cc-exp-month" id="mes-exp">
        <option disabled="" selected="" value="">Mes de expiración</option>
        <option value="01" id="mes-exp-0">Enero</option>
        <option value="02" id="mes-exp-1">Febrero</option>
        <option value="03" id="mes-exp-2">Marzo</option>
        <option value="04" id="mes-exp-3">Abril</option>
        <option value="05" id="mes-exp-4">Mayo</option>
        <option value="06" id="mes-exp-5">Junio</option>
        <option value="07" id="mes-exp-6">Julio</option>
        <option value="08" id="mes-exp-7">Agosto</option>
        <option value="09" id="mes-exp-8">Septiembre</option>
        <option value="10" id="mes-exp-9">Octubre</option>
        <option value="11" id="mes-exp-10">Noviembre</option>
        <option value="12" id="mes-exp-11">Diciembre</option>
      </select>
    </div>

    <!-- Año de Expiración -->
    <div class="form-group half-width">
      <label for="ano-exp">Año de expiración</label>
      <small *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched" class="form-error-msg">**Requerido </small>
      <select [formControl]="form.controls['expYearCreditCard']" class="form-control" name="cc-exp-year" autocomplete="cc-exp-year" id="ano-exp">
        <option disabled="" selected="" value="">Año de expiración</option>
        <option value="24" id="ano-exp-2">2024</option>
        <option value="25" id="ano-exp-3">2025</option>
        <option value="26" id="ano-exp-4">2026</option>
        <option value="27" id="ano-exp-5">2027</option>
        <option value="28" id="ano-exp-6">2028</option>
        <option value="29" id="ano-exp-7">2029</option>
        <option value="30" id="ano-exp-8">2030</option>
        <option value="31" id="ano-exp-9">2031</option>
        <option value="32" id="ano-exp-10">2032</option>
        <option value="33" id="ano-exp-11">2033</option>
        <option value="34" id="ano-exp-12">2034</option>
        <option value="35" id="ano-exp-13">2035</option>
        <option value="36" id="ano-exp-14">2036</option>
        <option value="37" id="ano-exp-15">2037</option>
        <option value="38" id="ano-exp-16">2038</option>
        <option value="39" id="ano-exp-17">2039</option>
        <option value="40" id="ano-exp-18">2040</option>
      </select>
    </div>
     
    <!-- Ciudad -->
    <div class="form-group full-width">
      <label for="city">Ciudad</label>
      <small *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched" class="form-error-msg"> **Requerido </small>
      <input [formControl]="form.controls['city']" type="text" placeholder="Ciudad" class="form-control" name="cc-city" access="false" id="city" required="required" aria-required="true" autocomplete="cc-city">
    </div>

    <!-- Calle -->
    <div class="form-group full-width">
      <label for="street">Calle</label>
      <small *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched" class="form-error-msg"> **Requerido </small>
      <input [formControl]="form.controls['street']" type="text" placeholder="Calle" class="form-control" name="cc-street" access="false" id="street" required="required" aria-required="true" autocomplete="cc-street">
    </div>

    <!-- Código Postal -->
    <div class="form-group full-width">
      <label for="postalCode">Código postal</label>
      <small *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched" class="form-error-msg"> **Requerido </small>
      <small *ngIf="form.controls['postalCode'].hasError('pattern') && form.controls['postalCode'].touched" class="form-error-msg"> **Formato inválido </small>
      <input [formControl]="form.controls['postalCode']" type="text" placeholder="Código postal" class="form-control" name="cc-postalCode" access="false" id="postalCode" required="required" aria-required="true" autocomplete="cc-postalCode" (keypress)="onlyNumberKey($event)">
    </div>
   <!-- Teléfono -->
<div class="form-group full-width">
  <label for="phone">Teléfono </label>
  <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="form-error-msg"> **Requerido </small>
  <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" class="form-error-msg"> **Formato inválido </small>
  <input [formControl]="form.controls['phone']" type="text" placeholder="Teléfono" class="form-control" name="cc-phone" access="false" id="phone" required="required" aria-required="true" autocomplete="cc-phone" (keypress)="onlyNumberKey($event)">
</div>


  </div>
  
  <!-- Botón de Suscripción -->
  <div class="form-group">
    <button type="submit" class="btn-default btn" name="suscribirte" access="false" id="suscribirte">Suscribirse</button>
  </div>
</form>




    </div>

  </div>
</div>

