<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button (click)="goBack()">Regresar</button>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="setting-data">
            <div class="second-headboard">
                <h1>Actualizar dirección del Cliente</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
               
            </div>
            <div class="general-data">
                <h1>Datos generales</h1>
                <form [formGroup]="form" >
                <div class="grid">
                    <div class="left">
                    
                        <div class="items">
                            <label for="country">Provincia*</label>
                            <div class="select-wrapper">
                                <select (change)="onProvinceChange()" [formControl]="form.controls['province']">
                                    <option   selected="" value="">Seleccione un opción</option>
                                    <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
                                        class="form-error-msg"> Provincia es requerido 
                                </small>
                            </div>
                        </div>

                        <div class="items">
                            <label for="name">Dirección*</label>
                            <input [formControl]="form.controls['adress']" 
                                    type="text" placeholder="Dirección">
                                    <small
                                    *ngIf="form.controls['adress'].hasError('required') &&
                                    form.controls['adress'].touched"
                                    class="form-error-msg"> Dirección es requerido</small>
                        </div>

                    </div>
                    <div class="right">
                        <div class="items">
                            <label >Corregimiento*</label>
                            <div class="select-wrapper">
                                <select [formControl]="form.controls['corregimiento']" id="corregimiento" name="corregimiento">
                                    <option   selected="" value="">Seleccione un opción</option>
                                    <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
                                        class="form-error-msg"> Corregimiento es requerido 
                                </small>
                            </div>
                        </div>
                        <div class="items">
                            <label for="name">Teléfono del cliente*</label>
                            <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['phone']" 
                                    type="text" placeholder="Teléfono">
                                    <small
                                    *ngIf="form.controls['phone'].hasError('required') &&
                                    form.controls['phone'].touched"
                                    class="form-error-msg"> Teléfono es requerido</small>
                        </div>
                    
                    </div>
                </div>
                </form>
            </div>
        </div>

        
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <button   class="continue-btn" (click)="update()" >Guardar información</button>
        </div>
    </div>
</body>
