import { Component, Inject } from '@angular/core';


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQsComponent {


  onCloseModal(): void {
    
  }
}
