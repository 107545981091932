import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs-hovers',
  templateUrl: './faqs-hovers.component.html',
  styleUrls: ['./faqs-hovers.component.scss']
})
export class FAQsHoversComponent {

  onCloseModal(): void {
    
  }
}
