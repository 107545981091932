import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  checkboxBasicValue = false;
  checkboxPracticalValue = false;
  checkboxContempoValue = false;
  exist = false;
  onboardingConfigID : any;
  checked : any;
  step:any;
  

  constructor(private activateRoute : ActivatedRoute,private router : Router,private location : Location,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }

  ngOnInit(): void {
    
    this.activateRoute.queryParams.subscribe( queryParams =>{
      if(queryParams['config']){

        this.onboardingConfigID = queryParams['config'];
        this.getConfigByID()

      }else{

        let sesion  = JSON.parse(localStorage.getItem('sesion')!);
        if(sesion.config !=  null){
          this.spinner.open();
          this.exist = true;
          if(sesion.config.length == 1){
            this.onboardingConfigID = sesion.config[0];
            this.getConfigByID();
          }

        }

      }
    }, err =>{
      this.toast.showError(err);
    });
  }

  getConfigByID() {
    const sesion = JSON.parse(localStorage.getItem('sesion')!);
    const partner = sesion.partner ?? -1;
  
    this.webService.get(`${this.webService.HOST}/onboardingConfig/${this.onboardingConfigID}?partner=${partner}`).subscribe(config => {
      this.step = config.result.service.current_onboarding_step;
      this.spinner.close();
  
      const templateValue = config.result.portal_identifier;
      console.log("🧩 Template seleccionado desde backend (portal_identifier):", templateValue);
  
      this.selectTemplate(templateValue); // Usa el número 1, 2 o 3 directamente
  
    }, err => {
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  selectTemplate(templateNumber: number) {
    this.checked = templateNumber;
  
    if (templateNumber === 1) {
      this.checkboxContempoValue = true;
      this.checkboxPracticalValue = false;
      this.checkboxBasicValue = false;
    } else if (templateNumber === 2) {
      this.checkboxContempoValue = false;
      this.checkboxPracticalValue = true;
      this.checkboxBasicValue = false;
    } else if (templateNumber === 3) {
      this.checkboxContempoValue = false;
      this.checkboxPracticalValue = false;
      this.checkboxBasicValue = true;
    }
  }

  saveTemplate(){

      if(this.checked == undefined){
        this.toast.showError("Debes seleccionar un template para continuar");
      }

      this.spinner.open();
      let json = { partner : JSON.parse(localStorage.getItem('sesion')!).partner, config : this.onboardingConfigID , template : this.checked};
      this.webService.put(json,this.webService.HOST + "/onboarding/step2").subscribe( response =>{

        this.spinner.close();

        if(!this.exist){

          this.toast.showSuccess("Templates seleccionado correctamente");
          let array = []; // SE AGREGA ESL NUEVO MANEJO DE IDS PARA CONFIGS YA ES UN ARREGLO
          var sesion = JSON.parse(localStorage.getItem('sesion')!);
          // @ts-ignore
          array.push(response.id)
          sesion.config = array;
          localStorage.setItem('sesion',JSON.stringify(sesion));

        }else{
          this.toast.showSuccess("Template actualizado correctamente");
        }

        this.router.navigate(["/personalize"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP3

      }, err =>{
          this.spinner.close();
          this.toast.showError(err)
        }
      )
  }

  goBack(){
    this.router.navigate(["/settings"], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  handleCheckboxChange(checkboxType: string) {
    if (checkboxType === 'basic') {
      this.checkboxPracticalValue = false;
      this.checkboxContempoValue = false;
    } else if (checkboxType === 'practical') {
      this.checkboxBasicValue = false;
      this.checkboxContempoValue = false;
    } else if (checkboxType === 'contempo') {
      this.checkboxBasicValue = false;
      this.checkboxPracticalValue = false;
    }
  }

  // handleCreatePlanClick() {
  //   this.router.navigateByUrl('/plans');
  // }  

  goToPlans(){
    if(this.step > 3){
      this.router.navigate(["/plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.toast.showError("No se puede ir a 'Planes' sin antes haber guardado un template");
    }
   
    
  }

 
  goToFeatures(){
   
    if(this.step > 2){
      this.router.navigate(["/personalize"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.toast.showError("No se puede ir a 'Personalizar' sin antes haber guardado un template");
    }
   
  }

}
