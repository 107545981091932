import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  signinForm: FormGroup ;
  passwordFieldType: string = 'password';
  constructor(private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService) {
    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });
   
   }
   
   ngOnInit(): void {
   }
   togglePasswordVisibility() { // Nuevo método para alternar la visibilidad de la contraseña
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
   signin() {

    this.spinner.open();

    const signinData = this.signinForm.value;

    let params = new HttpParams() ;

		params = params.set('grant_type', 'password');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');

    // SOLICITAMOS TOKEN
    this.webService.post(params,this.webService.HOST + "/oauth/token").subscribe( res =>{

      this.spinner.close();
      this.toast.showSuccess("Ha iniciado sesión correctamente");
      localStorage.setItem('sesion',JSON.stringify(res));
      localStorage.setItem('username', signinData.username);

      let config = JSON.parse(localStorage.getItem('sesion')!).config;
     
      // EN CASO DE QUE NO TENGA o TENGA UNA SOLA CONFIGURACION ENTONCES LO REDIRIGIMOS A ESTE HOME
      if(Array.isArray(config)){
        this.router.navigateByUrl("/my-portals");
        return;
      }

      this.router.navigate(['/my-portals']);


    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    })


  }

  goToLoginOnBoard() {
    this.router.navigate(['/login']);
  }

  goRegistry(){
    this.router.navigateByUrl("/create");
  }
  goHome() {
    this.router.navigate(['/']);
  }
  
}
