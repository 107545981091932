import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  isIconRotated: boolean = false;

  rotateIcon() {
    // console.log("Aqui")
    this.isIconRotated = !this.isIconRotated;
  }
  
  resetIcon() {
    // console.log("Aqui2")
    this.isIconRotated = false;
  }

}
