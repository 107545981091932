import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from "../plans/plan.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-update-portal-template',
  templateUrl: './update-portal-template.component.html',
  styleUrls: ['./update-portal-template.component.scss']
})
export class UpdatePortalTemplatesComponent implements OnInit {
  checkboxBasicValue = false;
  checkboxPracticalValue = false;
  checkboxContempoValue = false;
  exist = false;
  onboardingConfigID : any;
  checked : any;
  tmpChecked : any;
  fromUpdate : Boolean = false;
  public myplans : any = [];
  showPaymentButton : Boolean = false;

  constructor(private planService : PlanService,private activateRoute : ActivatedRoute,private router : Router,private location : Location,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }

  ngOnInit(): void {

    this.activateRoute.queryParams.subscribe( queryParams =>{

      if(queryParams['update']){
        this.fromUpdate = true;
      }
      this.activateRoute.params.subscribe( params =>{
        this.onboardingConfigID = params['id'];
        this.getConfigByID();
      }, err =>{
        this.toast.showError(err);
      });

    }, err =>{
      this.toast.showError(err);
    })
    
    
  }

  getConfigByID() {
    this.spinner.open(); // ✅ Mostramos el loader desde el principio
  
    const sesion = JSON.parse(localStorage.getItem('sesion')!);
    const partner = sesion.partner ?? -1;
  
    this.webService.get(`${this.webService.HOST}/onboardingConfig/${this.onboardingConfigID}?partner=${partner}`).subscribe(config => {
      this.spinner.close(); // ✅ Cerramos el loader cuando llega la data
  
      const templateValue = config.result.portal_identifier;
      console.log("🧩 Template recibido (portal_identifier):", templateValue);
      this.selectTemplate(templateValue);
  
      if (config.result.customerHasPlan != null) {
        if (config.result.customerHasPlan.status_customerPlan == 1) {
          const nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
          const today = new Date();
          const isValidDate = nextBillDate > today;
          if (!isValidDate) {
            this.showPaymentButton = true;
          }
        } else {
          this.showPaymentButton = true;
        }
      } else {
        this.showPaymentButton = true;
      }
  
      this.planService.plans.emit(config.result.plans);
  
    }, err => {
      this.spinner.close(); // ✅ También lo cerramos si hay error
      this.toast.showError(err);
      console.log("AQUI");
    });
  }
  

  selectTemplate(templateNumber: number) {
    this.checked = templateNumber;
  
    // Reiniciar todos
    this.checkboxBasicValue = false;
    this.checkboxPracticalValue = false;
    this.checkboxContempoValue = false;
  
    if (templateNumber === 1) {
      this.checkboxBasicValue = true;
    } else if (templateNumber === 2) {
      this.checkboxPracticalValue = true;
    } else if (templateNumber === 3) {
      this.checkboxContempoValue = true;
    }
  }

  saveTemplate(){
    if(this.checked == undefined){
      this.toast.showError("Debes seleccionar un template para continuar");
    }

    this.spinner.open();
    let json = { partner : JSON.parse(localStorage.getItem('sesion')!).partner, config : this.onboardingConfigID , template : this.checked};
    this.webService.put(json,this.webService.HOST + "/onboarding/step2").subscribe( response =>{
        this.spinner.close();

        this.toast.showSuccess("Template actualizado correctamente, en unos minutos podra visualizarlo correctamente en su portal");

        if(!this.fromUpdate){
          this.goToFeatures();
        }

      }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    )
  }

  goBack(){
    this.router.navigate([".."], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  handleCheckboxChange(checkboxType: string) {
    this.checkboxBasicValue = false;
    this.checkboxPracticalValue = false;
    this.checkboxContempoValue = false;
  
    if (checkboxType === 'basic') this.checkboxBasicValue = true;
    if (checkboxType === 'practical') this.checkboxPracticalValue = true;
    if (checkboxType === 'contempo') this.checkboxContempoValue = true;
  }

  // handleCreatePlanClick() {
  //   this.router.navigateByUrl('/plans');
  // }  

  goToPlans(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/my-plans']); 
  }

  goToEdit(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID]);
  }

  goToFeatures(){
    
    this.router.navigate(["/edit/portal/" +  this.onboardingConfigID+'/features']); 
  }
  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }


}
