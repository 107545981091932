<h1 matDialogTitle>{{data.title}}</h1>

  <form [formGroup]="itemForm" (ngSubmit)="submit()" class="form_edit_card">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" class="edit_card">

    
    <div fxFlex="50"  class="pr-16">
      <mat-form-field class="full-width">
        <input
        matInput
        name="numberCreditCard"
        [formControl]="itemForm.controls['numberCreditCard']"
        placeholder="Número de tarjeta">
      </mat-form-field>
      <small 
        *ngIf="itemForm.controls['numberCreditCard'].hasError('required') && itemForm.controls['numberCreditCard'].touched" 
        class="form-error-msg"> Número es querido
      </small>
    </div>

    <div fxFlex="50"  class="pr-16">
      <mat-form-field class="full-width">
        <input
        matInput
        name="nameHolderCreditCard"
        [formControl]="itemForm.controls['nameHolderCreditCard']"
        placeholder="Nombre">
      </mat-form-field>
      <small 
        *ngIf="itemForm.controls['nameHolderCreditCard'].hasError('required') && itemForm.controls['nameHolderCreditCard'].touched" 
        class="form-error-msg"> Nombre es querido
      </small>
    </div>

    <div fxFlex="50"  class="pr-16">
      <mat-form-field class="full-width">
        <input
        matInput
        name="expMonthCreditCard"
        [formControl]="itemForm.controls['expMonthCreditCard']"
        placeholder="Mes de vencimiento">
      </mat-form-field>
      <small 
        *ngIf="itemForm.controls['expMonthCreditCard'].hasError('required') && itemForm.controls['expMonthCreditCard'].touched" 
        class="form-error-msg"> Mes es querido
      </small>
      <small 
        *ngIf="itemForm.controls['expMonthCreditCard'].hasError('min') && itemForm.controls['expMonthCreditCard'].touched" 
        class="form-error-msg"> No debe ser menor a 01
      </small>
      <small 
        *ngIf="itemForm.controls['expMonthCreditCard'].hasError('max') && itemForm.controls['expMonthCreditCard'].touched" 
        class="form-error-msg"> No debe ser mayor a 12
      </small>

      <small 
        *ngIf="itemForm.controls['expMonthCreditCard'].hasError('minlength') && itemForm.controls['expMonthCreditCard'].touched" 
        class="form-error-msg"> El formato es : XX
      </small>

    </div>

    <div fxFlex="50"  class="pr-16">
      <mat-form-field class="full-width">
        <input
        matInput
        name="expYearCreditCard"
        [formControl]="itemForm.controls['expYearCreditCard']"
        placeholder="Año de vencimiento">
      </mat-form-field>
      <small 
        *ngIf="itemForm.controls['expYearCreditCard'].hasError('required') && itemForm.controls['expYearCreditCard'].touched" 
        class="form-error-msg"> Año es querido
      </small>

      <small 
        *ngIf="itemForm.controls['expYearCreditCard'].hasError('min') && itemForm.controls['expYearCreditCard'].touched" 
        class="form-error-msg"> No debe ser menor a 01
      </small>
      <small 
        *ngIf="itemForm.controls['expYearCreditCard'].hasError('max') && itemForm.controls['expYearCreditCard'].touched" 
        class="form-error-msg"> No debe ser mayor a 99
      </small>
      <small 
        *ngIf="itemForm.controls['expYearCreditCard'].hasError('minlength') && itemForm.controls['expYearCreditCard'].touched" 
        class="form-error-msg"> El formato es : XX
      </small>
      
    </div>
    



    <div fxFlex="100" class="mt-16">
      <button mat-raised-button color="warn" type="button" (click)="dialogRef.close(false)" class="back"><mat-icon>arrow_back</mat-icon></button>
      <span fxFlex></span>
      <button mat-raised-button color="primary" [disabled]="itemForm.invalid" class="save"><mat-icon>save</mat-icon></button>
    </div>

  </div>
  </form>