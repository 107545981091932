<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button class="create-report-btn"><h1>+</h1></button>
                <img src="../../assets/icons/reportes_aqua.png" alt="icono reportes">
                <app-menu></app-menu>
            </div>
        </div>

        <div class="general-data">
            <h1>Datos generales</h1>
            <!-- <form [formGroup]="form" > -->
            <div class="grid">
                <div class="left">

                    <div class="items">
                        <label for="name">Cliente</label>
                        <input type="text" readonly   value="{{ invoice?.customer?.name_customer  + ' ' + invoice?.customer?.lastName_customer || '-' }}">
                    </div>

                   <div class="items">
                        <label for="name">Código</label>
                        <input type="text" readonly   value="{{ invoice?.customer?.code_customer || '-'}}">
                    </div>

                    <div class="items">
                        <label for="name">Contrato</label>
                        <input type="text" readonly   value="{{ invoice?.customer_has_plan?.contractNumber_Plan || '-'}}">
                    </div>


                    <div class="items">
                        <label for="name">Total ({{ currency }})</label>
                        <input type="text" readonly   value="{{ invoice?.total_invoice || '-'}}">
                    </div>
                    
                    <div class="items">
                        <label for="name">Creada</label>
                        <input type="text" readonly   value="{{ invoice?.dateCreated_invoice || '-'}}">
                    </div>
                  

                </div>


                <div class="right">

                    <div class="items">
                        <label for="name">DNI</label>
                        <input type="text" readonly   value="{{ invoice?.customer?.identifier_customer || '-'}}">
                    </div>

                    <div class="items">
                        <label for="name">Plan</label>
                        <input type="text" readonly   value="{{ invoice?.customer_has_plan?.plan?.name_plan || '-'}}">
                    </div>

                    <div class="items">
                        <label for="name">Estatus</label>
                        <input type="text" readonly   value="{{ invoice?.status_invoice || '-'}}">
                    </div>

                    <div class="items">
                        <label for="name">Balance</label>
                        <input type="text" readonly   value="{{ invoice?.balance_invoice || '-'}}">
                    </div>

                    <div class="items">
                        <label for="name">Actualizada</label>
                        <input type="text" readonly   value="{{ invoice?.updatedAt || '-'}}">
                    </div>
                   
                </div>
            </div>
        </div>


        <div class="reports-data">
            
            <h1>Payments</h1>
            <div style="padding-top: 2em;">
                <mat-table [dataSource]="dataSource" matSort  >
                                 
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="id"> {{row.id}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="description_payment">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="description_payment"> {{row.description_payment}} </mat-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="resultStatus_payment">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="resultStatus_payment"> {{row.resultStatus_payment}} </mat-cell>
                        </ng-container>
  
                        <ng-container matColumnDef="dateCreated_payment">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Creada </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="dateCreated_payment"> {{row.dateCreated_payment}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="transactionId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Id Transacción </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="transactionId"> {{row.transactionId}} </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="authorizationNumber">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Nro de Auth </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="authorizationNumber"> {{row.authorizationNumber}} </mat-cell>
                          </ng-container>


                          <ng-container matColumnDef="transactionRefundId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Id Reembolso</mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="transactionRefundId"> {{row.transactionRefundId}} </mat-cell>
                          </ng-container>
  
                  
                        <ng-container matColumnDef="amount_payment">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Total ({{ currency }}) </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="amount_payment"> {{row.amount_payment}} </mat-cell>
                        </ng-container>
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </mat-row>
                </mat-table>
                <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="list($event)">
                </mat-paginator>  
            </div>
            
        </div>

        <div class="reports-data">
            
            <h1>Cupones</h1>
            <div style="padding-top: 2em;">
                <mat-table [dataSource]="dataCuponSource" matSort  >
                                 
                    <ng-container matColumnDef="cupon.id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="cupon.id"> {{row.cupon.id}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="cupon.name_cupon">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="cupon.name_cupon"> {{row.cupon.name_cupon}} </mat-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="cupon.value">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Valor </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="cupon.value"> {{row.cupon.value}} </mat-cell>
                        </ng-container>
  
                        <ng-container matColumnDef="cupon.type">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="cupon.type"> {{row.cupon.type}} </mat-cell>
                        </ng-container>
  
                        <ng-container matColumnDef="cupon.status">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="cupon.status"> {{row.cupon.status}} </mat-cell>
                        </ng-container>
  
                  
                        <ng-container matColumnDef="cupon.createdAt">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Creado </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="cupon.createdAt"> {{row.cupon.createdAt}} </mat-cell>
                        </ng-container>
                    
                
                        <mat-header-row *matHeaderRowDef="displayedCuponColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedCuponColumns;"  >
                        </mat-row>
                </mat-table>
                <mat-paginator showFirstLastButtons
                                [length]="tableCuponService.pager.totalEntities"
                                [pageIndex]="tableCuponService.pager.pageIndex"
                                [pageSize]="tableCuponService.pager.pageSize"
                                [pageSizeOptions]="[20, 50, 100]"
                                (page)="listCupon($event)">
                        </mat-paginator>  
            </div>
            
        </div>
        
        <div class="options-buttons">
          <button class="back-btn" (click)="goBack()" >Atrás</button>
      </div>

    </div>
</body>