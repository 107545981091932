<body>
    <div class="grid-overlay">
      <div class="header">
        <div class="logo">
          <a href="/my-portals">
            <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
          </a>
        </div>
        <div class="buttons">
          <img src="../../assets/icons/user.png" alt="icono usuario" style="width:50px; height:50px;">
          <h4>{{ username }}</h4>
          <app-menu></app-menu>
        </div>
      </div>
      <div class="editing-preferences">
        <form [formGroup]="form">
          <h1>Provincias y Corregimientos</h1>
          <div class="grid">
            <div class="left">
              <div class="items">
                <mat-form-field style="width: 85%;">
                  <mat-label>Provincias</mat-label>
                  <mat-select (selectionChange)="onProvinceChange(false)" [formControl]="form.controls['provinces']" multiple>
                    <mat-option *ngFor="let province of provincesList" [value]="province">
                      {{province.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="right">
              <div class="items">
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let province of form.controls['provinces'].value">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{province.name}}
                      </mat-panel-title>
                      <button mat-icon-button (click)="removeProvince(province)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-expansion-panel-header>
                    <mat-form-field style="width: 85%;">
                        <mat-label>Corregimientos</mat-label>
                        <mat-select (selectionChange)="onCorregimientosChange($event.value, province.id)" [formControl]="form.controls['corregimientos']" multiple>
                          <mat-option *ngFor="let corre of corregimientos[province.id]" [value]="corre">
                            {{corre.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="editing-preferences">
        <h1>Otras Configuraciones</h1>

        <div class="partner-settings-container">
          <mat-slide-toggle
            [(ngModel)]="emailPaymentSucceeded"
            (change)="onToggleChange()"
            >Notificar al cliente el cobro exitoso</mat-slide-toggle
          >
        </div>

        <br>
        <br>

        <div>
          <mat-slide-toggle
          [(ngModel)]="emailPaymentFailed"
          (change)="onToggleChange()"
          >Notificar al cliente el cobro fallido</mat-slide-toggle
        >
        </div>
          <br>

          <p style="color: red"> <strong> Aviso:  </strong>  Al activar estas opciones, podrías incurrir en costos adicionales por el envío de notificaciones por correo. Para más información, contacta a nuestra empresa.</p>


      </div>

        <div class="editing-preferences">
            <h1>Notificaciones para Actualización de Método de Pago</h1>

            <div class="partner-settings-container">
                <mat-slide-toggle
                        [(ngModel)]="emailChangeMethodPayment"
                        (change)="onToggleChange()"
                >Contacto por Email</mat-slide-toggle
                >
            </div>
            <br>
            <p>Activar esta opción para enviar notificaciones de actualización de método de pago a sus clientes mediante correo electrónico.</p>

            <br>
            <br>

            <div>
                <mat-slide-toggle
                        [(ngModel)]="whatsappChangeMethodPayment"
                        (change)="onToggleChange()"
                >Contacto por WhatsApp</mat-slide-toggle
                >
            </div>
            <br>
            <p>Activar esta opción para enviar notificaciones de actualización de método de pago a sus clientes a través de WhatsApp.</p>
            <br>

            <p style="color: red"> <strong> Aviso:  </strong>  Al activar estas opciones, podrías incurrir en costos adicionales por el envío de notificaciones por correo y whatsapp. Para más información, contacta a nuestra empresa.</p>


        </div>

      <div class="buttons">
        <button (click)="goBack()">Atrás</button>
        <div class="action-buttons">
          <button (click)="send()">Guardar</button>
        </div>
      </div>
    </div>
  </body>
  
