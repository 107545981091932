import { HttpParams } from '@angular/common/http';

export class HasplanFilter {

	name_plan: string | undefined;
	name_service: string | undefined;
	id: number | undefined;
	status_plan: number | undefined;
    
	constructor(filter?: HasplanFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_plan = filter.name_plan;
            this.status_plan = filter.status_plan;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_plan) {
			httpParams = httpParams.set('name_plan', this.name_plan);
		}
		if (this.name_service) {
			httpParams = httpParams.set('name_service', this.name_service);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_plan) {
			httpParams = httpParams.set('status_plan', this.status_plan.toString());
        }
		return httpParams;
	}
}