import { Component } from '@angular/core';

import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Router,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

  
  planImage : any;
  plan : any;
  showMenu : Boolean = true;
  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;
  addressID: any;
  tdcID: any;
  tdcStatus: any;
  tdcCorerID: any;
  hasPlanID: any;
  emailCustomer: any;
  confirmation : Boolean = false;
  isActive = true;
  isDialogOpen = false;
  isPayedPortal: Boolean = true;
  onboardingCongigID : any;


  constructor(private activateRoute : ActivatedRoute,private router: Router,private sanitizer: DomSanitizer,private dialog: MatDialog) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      name: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      name_tdc : new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });
  }

  

  ngOnInit(): void {

      };

    // }else{
    //   this.loader.close();
    //   this.isActive = false;
    // 
  }
