import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'


@Component({
  selector: 'app-unauthorized-step',
  templateUrl: './unauthorized-step.component.html',
  styleUrls: ['./unauthorized-step.component.scss']
})
export class UnauthorizedStepComponent {

  constructor(private location : Location) { }
  

  ngOnInit(): void {
  }

   
  goBack(){
    this.location.back();
  }
}
