import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { UpdateSuscriptionPopupComponent } from '../update-suscription-popup/update-suscription-popup.component';

@Component({
  selector: 'app-suscription',
  templateUrl: './suscription.component.html',
  styleUrls: ['../mis-suscripciones.component.scss']
})
export class SuscriptionComponent implements OnInit {

  @Input() plan : { name: String; price: Number; description: String; next_bill_date : string; } | any ;

  suscriptions = []

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  onChangeStatus(){

  }


}