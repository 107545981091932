<div class="plan-contempo">
    <div [ngClass]="{
        'plan-contempo-content': selectedPalette === 'Billcentrix',
        'plan-contempo-content-modern': selectedPalette === 'Moderna',
        'plan-contempo-content-violet': selectedPalette === 'Violeta'
      }">
        <div class="carousel-nav-left" (click)="previous()" [ngClass]="{ 'disabled': currentIndex === 0 }">
            <div class="arrow-left"></div>
        </div>
        <div class="carousel-container">
            <div class="preview" *ngFor="let plan of visiblePlans; let i = index">
                <div class="header" >
                    <div class="circle" [ngClass]="{
                        'plan-1': plan.title === 'PLAN 1',
                        'plan-2': plan.title === 'PLAN 2',
                        'plan-3': plan.title === 'PLAN 3'
                      }">
                        <img src="{{ plan.image }}" alt="imagen planes">
                    </div>
                </div>
                <p>{{ plan.title }}</p>
                <h1 class="big-number">{{ plan.price }}</h1>
                
                <div class="select">
                    <button [routerLink]="'/checkout'" >Seleccionar</button>
                </div>
            </div>
          </div>
        <div class="carousel-nav-right" [ngClass]="{ 'disabled': currentIndex === plans.length - 1 }"
            (click)="next()">
            <div class="arrow-right"></div>
        </div>
    </div>
</div>