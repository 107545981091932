import { Component, ViewChild,AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-navbardash',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarcheckoutComponent  implements AfterViewInit  {
  nombreUsuario: string = 'Nicolas'; // Nombre de usuario
  imagenAleatoria: string; //Imagen Aleatoria
  sidenavOpened = true; // Inicialmente cerrado
  isDropdownOpen: boolean = false;
  tarjetas: boolean = true;
  isPreview : Boolean = false;
  param : any;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  ngAfterViewInit() {
    // Abre el sidenav después de que la vista se inicialice
    setTimeout(() => {
      this.sidenav.open();
    });
  }
  isMobile: boolean = false;
  constructor(private activateRoute : ActivatedRoute,private mediaMatcher: MediaMatcher, private router : Router) {
    this.activateRoute.params.subscribe( params =>{
      // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
   });
    // Selecciona una imagen de perfil aleatoria de una lista de imágenes
    const perfilImagenes: string[] = [
      '../assets/images/profile/men-01.png',
      '../assets/images/profile/men-02.png',
      '../assets/images/profile/men-03.png',
      '../assets/images/profile/men-04.png',
      '../assets/images/profile/girl-02.png',
      '../assets/images/profile/girl-03.png',
      '../assets/images/profile/girl-04.png',
      '../assets/images/profile/girl-05.png',
      // Agrega más rutas de imágenes según sea necesario
    ];
    this.imagenAleatoria = perfilImagenes[Math.floor(Math.random() * perfilImagenes.length)];
  // Verifica si es móvil o no
  const mobileQuery = this.mediaMatcher.matchMedia('(max-width: 720px)');
  this.isMobile = mobileQuery.matches;
// Verifica si es móvil o no y ajusta sidenavOpened en consecuencia
if (this.isMobile) {
  this.sidenavOpened = true; // Cierra el mat-sidenav en dispositivos móviles
} else {
  this.sidenavOpened = true; // Abre el mat-sidenav en pantallas más grandes
}mobileQuery.addEventListener('change', () => {
  this.isMobile = mobileQuery.matches;
  if (this.isMobile) {
    this.sidenavOpened = true; // Cierra el mat-sidenav en dispositivos móviles
  } else {
    this.sidenavOpened = true; // Abre el mat-sidenav en pantallas más grandes
  }
});
  }

  

  toggleSidenav() {
    this.sidenavOpened = !this.sidenavOpened;
    this.sidenav.toggle();
  }
 

 

  onAcccount(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  onPassword(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }
}
