import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent {

  form: any ;

  constructor() {
  
    this.form = new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required,Validators.minLength(6)]),
      newPasswordConfirm:  new FormControl('', [Validators.required,Validators.minLength(6)])
    });

   }

 

}