<body><div class="main">
    <div class="grid-overlay">
        <div class="nav">
            <a href="/">regresar</a> 
        </div>
        <div class="logoblock"><img (click)="goHome()" src="../../assets/images/BRAND.png" alt="Logo Billcentrix">
            <h2>Entrar</h2>
        </div>
        
    <div class="login-modal-content">
        <div class="modal-login">
            <div class="background-white">
             <h1>Entrar</h1>
              <form class="user" [formGroup]="signinForm" (ngSubmit)="signin()" >
                  <div>
                    <input type="email" placeholder="Correo" [formControl]="signinForm.controls['username']" id="exampleInputEmail">
                        <small 
                        *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched" 
                        class="form-error-msg"> Correo es requerido </small>
                        <small 
                        *ngIf="signinForm.controls['username'].hasError('email') && signinForm.controls['username'].touched" 
                        class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>
                  </div>
                  <div class="password-container">
                    <input 
                      [type]="passwordFieldType" 
                      placeholder="Contraseña" 
                      [formControl]="signinForm.controls['password']" 
                      id="exampleInputPassword"
                    >
                    <button type="button" (click)="togglePasswordVisibility()" class="hideview">
                        <mat-icon style="font-size: 20px;">{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                      </button>
                    <small 
                      *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched" 
                      class="form-error-msg"> Contraseña es requerido 
                    </small>
                  </div>

                <p>¿Olvidaste tu Contraseña?</p>
                <a href="/reset">Haz click aquí</a>
                <div class="options">
                    <div class="checkbox">
                        <input type="checkbox" id="checkbox-remember" class="cookies">
                        <label for="checkbox-remember">Recuerdame<br>en este sitio</label>
                    </div>
                    <button  [disabled]="signinForm.invalid" type="btn" >Entrar</button>
                </div>
              </form>
            </div>
           
            <div class="transparent-background">
                <p>¿Nueva cuenta?</p>
                <button (click)="goRegistry()">Regístrate</button>
            </div>
        </div>
    </div>
    <img src="../../assets/images/grafica-2.svg" alt="graff">
</div>
</div>
</body>