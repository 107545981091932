<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button class="create-report-btn"><h1>+</h1></button>
                <img src="../../assets/icons/reportes_aqua.png" alt="icono reportes">
                <app-menu></app-menu>
            </div>
        </div>
        <div class="reports-data">

            <mat-accordion>
                <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                    <mat-expansion-panel-header>

                        <button class="btn-icon btn"  title="Buscar" type="button">
                            <i class="material-icons">search</i>
                        </button>
                    </mat-expansion-panel-header>

                    <form>
                        <fieldset class="fieldset">

                            <div fxLayout="row wrap" class="fild_search">
                                <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">
                                    <div class="pb-16">

                                        <mat-form-field class="full-width">
<!--                                            <mat-label>Desde</mat-label>-->
                                            <input
                                                    matInput
                                                    [matDatepicker]="pickerDesde"
                                                    placeholder="Seleccione una fecha"
                                                    [(ngModel)]="filter.since"
                                                    name="since">
                                            <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerDesde></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">
                                    <div class="pb-16">

                                        <mat-form-field class="full-width">
<!--                                            <mat-label>Hasta</mat-label>-->
                                            <input
                                                    matInput
                                                    [matDatepicker]="pickerHasta"
                                                    placeholder="Seleccione una fecha"
                                                    [(ngModel)]="filter.until"
                                                    name="until">
                                            <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerHasta></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>

                        </fieldset>
                        <div class="button_report_align"fxLayout="row" fxLayoutAlign="end center" >

                            <button
                                    mat-raised-button
                                    type="button"
                                    color="warn"
                                    class="ml-16 mr-16 button_report light"
                                    (click)="reset()"
                            >
                                <mat-icon>refresh</mat-icon>
                            </button>

                            <button
                                    mat-raised-button
                                    type="button"
                                    color="primary"
                                    class="ml-16 button_report blue"
                                    (click)="search()">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>

            <div style="padding-top: 2em;">
                <mat-table [dataSource]="dataSource" >
                                 
                    <ng-container matColumnDef="invoice">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="invoice"> {{row.id}} </mat-cell>
                      </ng-container>
          
                      <ng-container matColumnDef="estatus">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="total"> {{row.status_invoice}} </mat-cell>
                        </ng-container>
          
                        <ng-container matColumnDef="creada">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="total"> {{row.createdAt}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="plan">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Plan </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="plan"> {{row.customer_has_plan.plan.name_plan}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="portal">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Portal </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="portal"> {{row.customer_has_plan.plan.service.name_service}} </mat-cell>
                        </ng-container>
          
                        <ng-container matColumnDef="moneda_local">
                          <mat-header-cell *matHeaderCellDef mat-sort-header >Moneda Local</mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="moneda_local">{{row.currencyLocal.codeCurrency}}</mat-cell>
                        </ng-container>
          
                        <ng-container matColumnDef="total_dolares">
                          <mat-header-cell *matHeaderCellDef mat-sort-header >Total dolares</mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="total_dolares" >{{row.total_invoice}}</mat-cell>
                        </ng-container>

                      
                      <ng-container matColumnDef="actions">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="estatus"> 
                            <button *ngIf="row.status_invoice == 'Procesada y cobrada'"  (click)="openPopUp(row)" mat-button color="primary">Reembolsar</button>
                          </mat-cell>
                        </ng-container>
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="read(row)"  >
                    </mat-row>
                </mat-table>
         <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="list($event)">
                </mat-paginator>
            </div>
            
        </div>
        
        <div class="options-buttons">
          <button class="back-btn" (click)="goBack()" >Atrás</button>
      </div>

    </div>
</body>