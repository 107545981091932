
<body>
    
    <div [ngClass]="{
        'main-content': selectedPalette === 'Billcentrix',
        'main-content-modern': selectedPalette === 'Moderna',
        'main-content-violet': selectedPalette === 'Violeta'
      }">
        <app-header></app-header>
        <div class="main-image">
            <div class="text">
                <h1>Revive Tus Mañanas con nuestro Café Premium</h1>
                <p>Calidad inigualable en cada taza. Suscribete ahora.</p>
                
            </div>
        </div>
        <div class="plans">
            <p>Tenemos un plan especialmente para ti</p>
            <app-plan-basic [selectedPalette]="selectedPalette"></app-plan-basic>
        </div>
        <div class="banner-content">
            <div class="text">
                <h1>Aroma en cada taza</h1>
                <p>Nuestro café, vestido de colores vivos, invita a una experiencia única. Ven, siente el calor de la tradición en cada taza.</p>
            </div>
            <div class="white-image"></div>
        </div>
        <div class="footer">
            <hr>
            <div class="mail">
                <img *ngIf="selectedPalette === 'Billcentrix'" src="../../../assets/icons/mail.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Moderna'" src="../../../assets/icons/mail-green.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Violeta'" src="../../../assets/icons/mail-violet.png" alt="icono de mail">
                <h2>Forma@<strong>decontacto.com</strong></h2>
            </div>
        </div>
    </div>
    <!-- <div class="options-buttons">
        <button class="back-btn">Atrás</button>
        <button class="continue-btn">Seleccionar</button>
    </div> -->
</body>