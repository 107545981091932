<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button> <a href="/my-portals">Ver Portales</a></button>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="setting-data">
            <div class="content-thanks">
                
            <div class="second-headboard">
                <h1>¡Pago Procesado! Tu Portal Personalizado Está en Camino</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
              
            </div>
            <img style="width: 350px;height: 260px;object-fit: cover;" src="../../assets/images/process.gif" alt="">
            <p>Redireccionando en {{ countdown }} segundos...</p>
            <div class="gracias_text">
            <p>¡Gracias por elegirnos! Estamos configurando tu portal. Este proceso es rápido y automatizado te brindará una herramienta potente y eficaz para tus necesidades.</p>
            <p>
                Prepárate para explorar las amplias posibilidades que tu nuevo portal te ofrecerá. Recibirás una notificación en cuanto esté listo y podrás comenzar a maximizar tu presencia y eficiencia en línea.
            </p>
            <p>  
                ¿Listo para dar el siguiente paso en tu viaje digital con nosotros? </p>
           </div>
           
        </div>
           </div>
            </div>
        
</body>
