import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  public plans = new EventEmitter<any[]>();

  constructor() { }
}
