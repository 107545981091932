<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <img (click)="navigateToHome()" src="../../assets/images/BRAND2.png" alt="logo de Billcentrix">
            </div>
            <div class="buttons">
                <button> <a href="/my-portals">Ver Portales</a></button>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="setting-data">
            <div class="content-thanks">
                
            <div class="second-headboard">
                <h1>Tu Portal Personalizado Está en Camino</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
              
            </div>
            <img style="width: 350px;height: 260px;object-fit: cover;" src="../../assets/images/process.gif" alt="">
            <p>Redireccionando en {{ countdown }} segundos...</p>
            <div class="gracias_text">
            <p>¡Gracias por elegirnos! Estamos configurando tu portal, que por lo momentos estara abajo. Pero tan pronto este habilitado el cambio de template te enviaremos una notificación.</p>
           </div>
           
        </div>
           </div>
            </div>
        
</body>
