import { HttpParams } from '@angular/common/http';

export class CustomerFilter {

	name_customer: string | undefined;
    adress_customer: string  | undefined;
	id: number | undefined;
	status_customer: number | undefined;
	identifier_customer: string | undefined;
	code_customer: string | undefined;
	services : string | undefined;
	plans : string | undefined;

    
	constructor(filter?: CustomerFilter) {
		if (filter) {
            this.id = filter.id;
            this.name_customer = filter.name_customer;
			this.adress_customer = filter.adress_customer;
			this.status_customer = filter.status_customer;
			this.identifier_customer = filter.identifier_customer;
			this.code_customer = filter.code_customer;
			this.services = filter.services;
			this.plans = filter.plans
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name_customer) {
			httpParams = httpParams.set('name_customer', this.name_customer);
        }
        if (this.adress_customer) {
			httpParams = httpParams.set('adress_customer', this.adress_customer);
		}
		if (this.identifier_customer) {
			httpParams = httpParams.set('identifier_customer', this.identifier_customer);
        }
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.status_customer) {
			httpParams = httpParams.set('status_customer', this.status_customer.toString());
		}
		if (this.code_customer) {
			httpParams = httpParams.set('code_customer', this.code_customer);
        }
		if(this.services){
			httpParams = httpParams.set('services', this.services);
		}
		if(this.plans){
			httpParams = httpParams.set('plans', this.plans);
		}
		return httpParams;
	}
}